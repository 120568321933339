import { createApi } from '@reduxjs/toolkit/query/react'
import { endpoints } from '@/api/constants'
import {
  IPaginatedRes,
  IServiceAppointment,
  IServiceAppointmentFilter,
} from '@/types'
import { createBaseQuery, generateQueryParams } from '@/utils'

export const serviceAppointmentApi = createApi({
  reducerPath: 'serviceAppointmentApi',
  tagTypes: ['ServiceAppointment'],
  baseQuery: createBaseQuery(),
  endpoints: builder => ({
    getServiceAppointments: builder.query<
      IPaginatedRes<IServiceAppointment>,
      Partial<IServiceAppointmentFilter>
    >({
      query: query => {
        const queryObject = { ...query }
        if (!queryObject.externalId) {
          delete queryObject.externalId
        }
        const filter = {
          ...query,
          workflowIds: query.workflowIds?.join(','),
        }
        const params = generateQueryParams(filter)
        return {
          url: endpoints.serviceAppointments + params,
        }
      },
    }),
  }),
})

export const { useGetServiceAppointmentsQuery } = serviceAppointmentApi
