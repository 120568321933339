import React, { useState } from 'react'
import ConfirmModal from '@/sections/ConfirmModal'
import { Button, TextArea } from '@/components'
import {
  useGetLockedLocationsQuery,
  useLockLocationMutation,
} from '@/api/services/location'

const LocationLockModal = ({ locationId }: { locationId: string }) => {
  const [lockLocation, { isLoading }] = useLockLocationMutation()
  const { refetch } = useGetLockedLocationsQuery(undefined)
  const [isOpened, setIsOpened] = useState(false)
  const [error, setError] = useState('')
  const [reason, setReason] = useState('')
  const onLockLocation = async () => {
    if (!reason) {
      setError('Reason is required')
      return
    }
    try {
      await lockLocation({ locationId, reason }).unwrap()
      refetch()
      setIsOpened(false)
    } catch (err) {
      // @ts-ignore
      setError(err.data?.message)
    }
  }
  return (
    <>
      <Button size="small" status="error" onClick={() => setIsOpened(true)}>
        Lock Location
      </Button>{' '}
      <ConfirmModal
        title="Lock Location"
        onClose={() => setIsOpened(false)}
        open={isOpened}
        isLoading={isLoading}
        content={
          <TextArea
            error={error}
            className={'w-full'}
            value={reason}
            rows={2}
            label="Reason"
            onChange={e => {
              setError('')
              setReason(e.target.value)
            }}
          />
        }
        onConfirm={onLockLocation}
      />
    </>
  )
}

export default LocationLockModal
