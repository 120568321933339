import { createApi } from '@reduxjs/toolkit/query/react'
import { endpoints } from '@/api/constants'
import {
  IPaginatedRes,
  IRepairOrder,
  IRepairOrderFilter,
  IRepairOrderStatsFilter,
} from '@/types'
import { createBaseQuery, generateQueryParams } from '@/utils'

export const repairOrderApi = createApi({
  reducerPath: 'repairOrderApi',
  tagTypes: ['RepairOrders'],
  baseQuery: createBaseQuery(),
  endpoints: builder => ({
    getRepairOrders: builder.query<
      IPaginatedRes<IRepairOrder>,
      Partial<IRepairOrderFilter>
    >({
      query: query => {
        const queryObject = { ...query }
        if (!queryObject.externalId) {
          delete queryObject.externalId
        }
        const filter = {
          ...query,
          workflowIds: query.workflowIds?.join(','),
        }
        const params = generateQueryParams(filter)
        return {
          url: endpoints.repairOrders + params,
        }
      },
    }),
    getRepairOrdersUsedWorkflowsCount: builder.query<
      number,
      Partial<IRepairOrderStatsFilter>
    >({
      query: query => {
        const params = generateQueryParams(query)
        return {
          url: endpoints.repairOrdersUsedWorkflowsCount + params,
        }
      },
    }),
  }),
})

export const {
  useGetRepairOrdersQuery,
  useGetRepairOrdersUsedWorkflowsCountQuery,
} = repairOrderApi
