import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { API_ENDPOINT, AUTH_TOKEN_KEY } from '@/constants'
import { getStorageValue } from '@/utils/storage'

export const createBaseQuery = () => {
  return fetchBaseQuery({
    baseUrl: API_ENDPOINT,
    prepareHeaders: headers => {
      const token = getStorageValue(AUTH_TOKEN_KEY)
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
  })
}
