import React from 'react'
import { useParams } from 'react-router-dom'
import {
  useGetOrganizationWorkflowListQuery,
  useGetOrganizationWorkflowLocationsQuery,
} from '@/api/services/workflow'
import { useGetSingleOrganizationsQuery } from '@/api/services/organization'
import { Button, Tag, Typography } from '@/components'
import { useAppSelector } from '@/store/hooks'
import { accessList } from '@/store/slices/user.slice'

import WorkFLowItem from '@/pages/V2Dashboard/WorkflowItem'
import { capitalize, generatePath } from '@/utils'
import { WORKFLOW } from '@/constants'

import { ReactComponent as PlusIcon } from '@/assets/icons/Plus.svg'

const V2Dashboard = () => {
  const { id = '' } = useParams()
  const accesses = useAppSelector(accessList)
  const { data: organization } = useGetSingleOrganizationsQuery({ id })
  const { data: { locations = [] } = {} } =
    useGetOrganizationWorkflowLocationsQuery({
      id,
    })
  const { data: { workflows = [] } = {}, refetch } =
    useGetOrganizationWorkflowListQuery({ id })

  const afterAction = () => {
    refetch()
  }

  return (
    <div className="flex flex-col gap-10 w-full">
      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-2">
          <Typography variant="H2" weight="semibold">
            {organization?.name}
          </Typography>
          <div className="flex flex-col gap-1">
            <Typography
              variant="regular"
              weight="normal"
              className="text-black-80"
            >
              Workflows Available:
            </Typography>
            <div className="flex gap-2">
              {locations?.map(location => (
                <Tag variant="light" status="warning" key={location.name}>
                  {capitalize(location.crm)}
                </Tag>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="flex justify-between gap-2 items-center">
          <Typography variant="H3" weight="semibold">
            Workflows List
          </Typography>
          {accesses.WORKFLOWS_WRITE && (
            <div className="shrink-0">
              <Button
                status="secondary"
                size="small"
                as="Link"
                to={generatePath(WORKFLOW, {
                  id: organization?._id,
                  workFlowId: 'new',
                })}
                IconLeft={PlusIcon}
              >
                Add Workflow
              </Button>
            </div>
          )}
        </div>
        <div className="grid grid-cols-3 gap-6 mt-4">
          {workflows.map(workflow => (
            <WorkFLowItem
              workflow={workflow}
              locations={locations}
              hasWritePermissions={accesses.WORKFLOWS_WRITE}
              organization={organization}
              afterAction={afterAction}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default V2Dashboard
