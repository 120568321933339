import React from 'react'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'
import { TIcon } from '@/types'

export type ActionButtonProps = React.HTMLAttributes<HTMLButtonElement> & {
  as?: React.ElementType | 'NavLink'
  to?: string
  disabled?: boolean
  theme?: 'light' | 'dark'
  type?: 'button' | 'submit' | 'reset'
  Icon: TIcon
  active?: boolean
}

const buttonStyles = {
  base: 'w-10 h-10 rounded-md flex justify-center items-center transition-all duration-300 shrink-0 cursor-pointer',
  light:
    'text-black-50 hover:bg-blue-10 active:bg-blue-100 active:text-white-100 disabled:opacity-50',
  dark: 'text-white-100 hover:bg-white-100 hover:bg-opacity-15 active:bg-blue-100 disabled:opacity-50',
  active: '!bg-blue-100 !text-white-100',
}

const ActionButton = ({
  className,
  as = 'button',
  to,
  disabled,
  type = 'button',
  theme = 'light',
  Icon,
  active,
  ...rest
}: ActionButtonProps) => {
  const Component = as === 'NavLink' ? NavLink : as
  if (Component === NavLink) {
    return (
      <Component
        to={to || '/'}
        className={({ isActive }) =>
          clsx(className, buttonStyles.base, buttonStyles[theme], {
            [buttonStyles.active]: isActive,
          })
        }
        disabled={disabled}
        end
        {...(rest as any)}
      >
        <Icon className="w-6 h-auto" />
      </Component>
    )
  }

  return (
    <Component
      className={clsx(className, buttonStyles.base, buttonStyles[theme], {
        [buttonStyles.active]: active,
      })}
      disabled={disabled}
      type={type}
      {...rest}
    >
      <Icon className="w-6 h-auto" />
    </Component>
  )
}

export default ActionButton
