import React from 'react'
import ComingSoon from '@/sections/ComingSoon'

import { ReactComponent as OnboardingIcon } from '@/assets/icons/Settings Colored.svg'

const Onboarding = () => {
  return (
    <div>
      <ComingSoon
        Icon={OnboardingIcon}
        description="Effortlessly onboard dealerships with an intuitive, automated process to streamline account setup."
      />
    </div>
  )
}

export default Onboarding
