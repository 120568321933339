import React from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'
import { Typography } from '@/components'

interface TextAreaProps
  extends Omit<React.HTMLAttributes<HTMLTextAreaElement>, 'onChange'> {
  wrapperClass?: string
  error?: string
  onChange?: (_e: React.ChangeEvent<HTMLTextAreaElement>) => void
  disabled?: boolean
  placeholder?: string
  value?: string
  label?: string
  rows?: number
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    { className, label, wrapperClass, disabled, error, rows = 6, ...rest },
    ref,
  ) => {
    return (
      <label>
        {label && (
          <Typography variant="small" weight="medium" className="mb-3">
            {label}
          </Typography>
        )}
        <div
          className={clsx(wrapperClass, styles.inputContainer, {
            [styles.disabled]: disabled,
            [styles.error]: error,
          })}
        >
          <textarea
            rows={rows}
            ref={ref}
            className={clsx(className, styles.input, {
              [styles.error]: error,
            })}
            disabled={disabled}
            {...rest}
          />
        </div>
      </label>
    )
  },
)

export default TextArea
