import React from 'react'
import Modal from '@/components/Modal'
import { TPingTestType } from '@/types'
import FormActionState from '@/sections/FormActionState'
import { Button, Typography } from '@/components'
import { AUTOMATIONS, ORGANIZATION } from '@/constants'
import { generatePath } from '@/utils'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '@/store/hooks'
import { onFormTypeChange } from '@/store/slices/pages/organization/organization-automations.slice'

const statusText = {
  completed: {
    title: 'The automation has been setup successfully.',
    desc: 'You can now enjoy your extra time!',
  },
  failed: {
    title: 'The automation setup has failed for some reason.',
    desc: 'Make sure you have submitted the correct settings, then try again.',
  },
  pending: {
    title: 'The automation is currently being tested.',
    desc: 'The process should not take more than 2 minutes.',
  },
}

const AutomationStatusModal = ({ status }: { status: TPingTestType }) => {
  const dispatch = useAppDispatch()
  const { id } = useParams()
  return (
    <Modal open={!!status} showClose={false}>
      <FormActionState status={status} />
      <div className="flex flex-col gap-1 text-center">
        <Typography variant="H3" weight="semibold">
          {statusText[status].title}
        </Typography>
        <Typography variant="regular">{statusText[status].desc}</Typography>
      </div>
      <div className="flex justify-center pt-3">
        <Button
          status="primary"
          size="large"
          as="Link"
          to={generatePath(ORGANIZATION + AUTOMATIONS, { id })}
          onClick={() => {
            dispatch(onFormTypeChange(undefined))
          }}
        >
          Go back to automations
        </Button>
      </div>
    </Modal>
  )
}

export default AutomationStatusModal
