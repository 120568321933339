import { IBaseListItem, IGlobalState, IPageMeta } from '@/types/global'

export enum AutomationStatus {
  FAILED = 'failed',
  PENDING = 'pending',
  COMPLETED = 'completed',
  ACKNOWLEDGED = 'acknowledged',
}

export interface IPingTestResponse {
  type?: string
  number?: number | string
  status: TPingTestType
}

export type TPingTestType = 'completed' | 'failed' | 'pending'

export type TAutomationType =
  | 'automation'
  | 'appointment'
  | 'lead'
  | 'aibot'
  | 'workflow'
  | 'resurrection'
  | 'weeklyreport'

export interface ITaskParsedPayload {
  [key: string]: string
}

export type TagStatus =
  | 'error'
  | 'success'
  | 'warning'
  | 'default'
  | 'alert'
  | 'info'
  | 'primary'
  | 'secondary'
  | 'transparent'

export type PayloadTagStatus = 'ERROR' | 'SUCCESS' | 'WARNING' | 'DEFAULT'

interface IPayload {
  tag: PayloadTagStatus
  message: string
  content?: any
  lambdaLogs?: string
  parsedRowsCount?: number
  fetchedRowsCount?: number
  screenshotLocation?: string
}

export interface IEvent {
  _id: string
  type: string
  payload: IPayload
  createdAt: string
}

export interface IAutomation extends IBaseListItem {
  type: TAutomationType
}

export interface ITask {
  _id: string
  step: number
  crm: string
  createdAt: Date
  events: IEvent[]
  location: IBaseListItem
  crmPayload: boolean
  status: AutomationStatus
  parsedPayload?: ITaskParsedPayload[]
  automation: IAutomation
  organization: IBaseListItem
}

export interface IAutomationFilter extends IPageMeta {
  startDate: string
  endDate: string
  automationType: string
  organizationIds: string[]
  locationId: string
  crm: string
  status: string
}

export interface IAutomationState extends IGlobalState {
  details?: ITask
  filter: IAutomationFilter
}

interface ITime {
  hour: number | string
  minute: number | string
  isoTimezone?: string
}

export interface IFrequency {
  readableText?: string
  increment: string
  days: number[]
  time: ITime
  weekdays: string[]
}

interface ITimeOffest {
  unit: string
  value: number
  _id: string
}
export interface IMedia {
  _id: string
  url: string
  createdBy: string
  createdAt: string
  deletedAt: string
}

interface IAutomationEvent {
  type: string
  payload: {
    body: any // This can be of any type depending on the payload
  }
  _id: string
  createdAt: string
}

export interface IAutomationData {
  _id: string
  crm: string
  user: string
  name: string
  rule: string
  active: boolean
  location: {
    _id: string
    name: string
  }
  organization: string
  meta: {
    end_2: string
    start_2: string
    endUnit_2: string
    startUnit_2: string
    deal_status: string[]
    is_used: string
  }
  __v: number
  type: TAutomationType
  rpaSteps: any[]
  message: string
  public: boolean
  timezone: string
  createdAt: string
  updatedAt: string
  failureCount: number
  initialTest: boolean
  frequencies: IFrequency[]
  endOffset: ITimeOffest
  media: IMedia | null
  startOffset: ITimeOffest
  events: IAutomationEvent[]
  nextScheduledAction: string
  assignedUsers?: string[]
}

export interface IAutomationForm {
  edit?: boolean
  name?: string
  ruleId?: string
  locationId?: string
  automationId?: string
  meta: any | undefined
  message?: string
  start: string
  startUnit: string
  end: string
  endUnit: string

  image: File | null
  media: IMedia | null
  imageError: string | null
  frequencies: IFrequency[]
  isReviewInvite: boolean
  assignedUsers?: string[]
  timezone?: string
}

export interface IPBSDealsType {
  _id: string
  crm: string
  location: string
  username: string
  password: string
  createdAt: string
  organization: string
  pbsDealStatusCache: string[]
}

export interface IAutomationMutationPayload {
  crm: any | undefined
  meta: any | undefined
  id?: string | undefined
  name: string | undefined
  rule: string | undefined
  message: string | undefined
  location: string | undefined
  media: IMedia | null
  frequencies: IFrequency[] | undefined
  endOffset: [string | undefined, string | undefined]
  startOffset: [string | undefined, string | undefined]
  assignedUsers?: string[]
}
