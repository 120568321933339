import { createSlice } from '@reduxjs/toolkit'
import { defaultPageMeta, errorCodes } from '@/constants'
import moment from 'moment-timezone'

import { IErrorTrackerState } from '@/types'

const initialState: IErrorTrackerState = {
  loading: false,
  error: undefined,
  filter: {
    ...defaultPageMeta,
    startDate: moment().subtract(7, 'd').toString(),
    endDate: moment().endOf('day').toString(),
    organization: undefined,
    crm: undefined,
    includedErrorCodes: Object.keys(errorCodes),
  },
}

const errorTrackerSlice = createSlice({
  name: 'errorTracker',
  initialState,
  reducers: {
    clearFilter: state => {
      state.filter = initialState.filter
    },
    onFilterChange: (state, action) => {
      state.filter = { ...state.filter, ...action.payload }
    },
  },
})

export const { clearFilter, onFilterChange } = errorTrackerSlice.actions
export default errorTrackerSlice.reducer
