import React, { ReactNode } from 'react'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/react'

import { ReactComponent as ArrowIcon } from '@/assets/icons/Arrow.svg'
import { TIcon } from '@/types'
import clsx from 'clsx'
import { Typography } from '@/components'

const DropdownMenu = ({
  trigger,
  showArrow = false,
  items,
  className,
}: {
  trigger: ReactNode
  showArrow?: boolean
  items: { Icon?: TIcon; label: string | ReactNode; onClick?: () => void }[]
  className?: string
}) => {
  return (
    <Menu>
      <MenuButton className={className}>
        {trigger}
        {showArrow && <ArrowIcon className="size-4" />}
      </MenuButton>

      <MenuItems
        transition
        anchor="bottom end"
        className="!max-w-52 w-full p-1 border border-bg-stroke rounded-xl max-h-[240px] overflow-auto bg-white-100 mt-2 absolute z-30 shadow-dropdown transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        {items.map(({ Icon, label, onClick }, index) => (
          <MenuItem key={index}>
            <div
              onClick={onClick}
              className={clsx(
                'px-3 py-2 rounded-lg transition-all hover:bg-blue-10 cursor-pointer active:bg-blue-20 group flex items-center gap-2',
              )}
            >
              {Icon && (
                <Icon className="mr-3 h-5 w-5 text-black-50 group-hover:text-black-100 transition-all duration-300" />
              )}
              <Typography variant="small" weight="medium">
                {label}
              </Typography>
            </div>
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  )
}

export default DropdownMenu
