import React from 'react'
import moment from 'moment-timezone'
import { DATE_FORMAT } from '@/constants'
import { capitalize, renderUserFullName } from '@/utils'
import AutomationTaskStatus from '@/sections/AutomationTaskStatus'
import { ColumnDef } from '@tanstack/react-table'
import { AutomationStatus, IAppointment } from '@/types'
import OrganizationLink from '@/sections/OrganizationLink'

export const appointmentTableColumns: ColumnDef<IAppointment, any>[] = [
  {
    accessorKey: 'organization.name',
    header: () => <span>Organization</span>,
    cell: info => {
      const data = info.row.original.organization
      return <OrganizationLink id={data._id} name={data.name} />
    },
  },
  {
    accessorKey: 'location.name',
    header: () => <span>Location</span>,
    cell: info => info.getValue(),
  },
  {
    accessorKey: 'name',
    header: () => <span>Name</span>,
    cell: info =>
      renderUserFullName(
        info.row.original.firstName,
        info.row.original.lastName,
      ),
  },
  {
    accessorKey: 'phoneNumber',
    header: () => <span>Phone Number</span>,
    cell: info => info.getValue(),
  },
  {
    accessorKey: 'appointmentTimestamp',
    header: () => <span>Appointment time</span>,
    // TODO: Show date in a readable format
    cell: info => moment(info.getValue()).format(DATE_FORMAT),
  },
  {
    accessorKey: 'crm',
    header: () => <span>CRM</span>,
    cell: info => capitalize(info.getValue()),
  },
  {
    accessorKey: 'status',
    header: () => <span>Status</span>,
    cell: info => (
      // TODO: Show status when have data from backend
      <AutomationTaskStatus status={AutomationStatus.COMPLETED} />
    ),
  },
  {
    accessorKey: 'createdAt',
    header: () => <span>Created at</span>,
    cell: info => moment(info.getValue()).format(DATE_FORMAT),
  },
]
