export enum TA2pStatus {
  FAILED = 'failed',
  PAUSED = 'paused',
  COMPLETED = 'completed',
}

export interface IA2pWorkflow {
  _id: string
  message: string
  name: string
  status: TA2pStatus
  createdAt: string
}
