import React from 'react'
import PageHeading from '@/sections/PageHeading'
import { DataTable } from '@/components'
import Pagination from '@/components/Pagination'
import RepairOrderFilter from '@/pages/Organization/RepairOrders/Filter'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { useParams } from 'react-router-dom'
import { useGetRepairOrdersQuery } from '@/api/services/repair-order'
import { onFilterChange } from '@/store/slices/repait-order.slice'
import { repairOrderTableColumns } from '@/pages/Organization/RepairOrders/config'

const RepairOrders = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const { filter } = useAppSelector(state => state.repairOrders)
  const {
    data: { data = [], pageMeta: { totalItems = 0 } = {} } = {},
    isFetching,
  } = useGetRepairOrdersQuery({
    ...filter,
    organization: id,
  })

  const onPageChange = (page: number) => {
    dispatch(
      onFilterChange({
        page,
      }),
    )
  }

  return (
    <div className="w-full flex flex-col gap-6">
      <RepairOrderFilter />
      <DataTable
        loading={isFetching}
        columns={repairOrderTableColumns}
        data={data}
      />
      <Pagination
        currentPage={filter.page}
        totalCount={totalItems}
        onPageChange={onPageChange}
      />
    </div>
  )
}

export default RepairOrders
