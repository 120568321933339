import { endpoints } from '@/api/constants'
import { createApi } from '@reduxjs/toolkit/query/react'
import { createBaseQuery, generateQueryParams } from '@/utils'
import {
  IAutomationData,
  IErrorTracker,
  IErrorTrackerCount,
  IErrorTrackerFilter,
  IErrorTrackerWithStats,
  IPaginatedRes,
  TErrorCodes,
} from '@/types'
import { errorCodes } from '@/constants'

export const errorCodesApi = createApi({
  reducerPath: 'errorCodesApi',
  tagTypes: ['ErrorCodes'],
  baseQuery: createBaseQuery(),
  endpoints: builder => ({
    getErrorCodeStats: builder.query<
      IErrorTrackerWithStats[],
      Partial<IErrorTrackerFilter>
    >({
      query: query => {
        const params = generateQueryParams(query)
        return {
          url: endpoints.errorCodesStats + params,
        }
      },
      transformResponse: (response: IErrorTrackerWithStats[]) => {
        const errorCodeList = Object.keys(errorCodes)
        return response.map(record => {
          const data = { ...record }
          errorCodeList.forEach(code => {
            if (!data[code as TErrorCodes]) {
              data[code as TErrorCodes] = 0
            }
          })
          return data
        })
      },
    }),
    getErrorCodeList: builder.query<
      IPaginatedRes<IErrorTracker>,
      Partial<IErrorTrackerFilter>
    >({
      query: query => {
        const params = generateQueryParams(query)
        return {
          url: endpoints.errorCodesList + params,
        }
      },
    }),
    getErrorCodeCount: builder.query<
      IErrorTrackerCount[],
      Partial<IErrorTrackerFilter>
    >({
      query: query => {
        const params = generateQueryParams(query)
        return {
          url: endpoints.errorCodesCount + params,
        }
      },
      transformResponse: (response: IErrorTrackerCount[]) => {
        const errorCodeList = Object.keys(errorCodes)
        return errorCodeList.map(code => {
          const data = { error_code: code as TErrorCodes, count: 0 }
          response.forEach(record => {
            if (record.error_code === (code as TErrorCodes)) {
              data.count = record.count
            }
          })
          return data
        })
      },
    }),
  }),
})

export const {
  useGetErrorCodeStatsQuery,
  useGetErrorCodeListQuery,
  useGetErrorCodeCountQuery,
} = errorCodesApi
