import React, { useEffect, useState } from 'react'
import { Button, Drawer, DrawerSize, Typography } from '@/components'
import Accordion from '@/components/Accordion'
import {
  InventoryType,
  IUnusedFeed,
  IUnusedFeedResponseType,
} from '@/types/inventory'
import moment from 'moment-timezone'
import { DATE_FORMAT, DATE_FORMAT_FULL } from '@/constants'
import FileViewer from 'src/pages/Inventory/FileViewer'
import { useGetDealerFileQuery } from '@/api/services/inventory'
import ListLoadingSkeleton from '@/sections/ListLoadingSkeleton'
import clsx from 'clsx'

const UnusedFeedModal = ({
  open,
  onClose,
  unusedFeeds,
  loading,
}: {
  open: boolean
  loading: boolean
  onClose: () => void
  unusedFeeds: IUnusedFeedResponseType
}) => {
  const [feed, setFeed] = useState<{
    vendor: InventoryType
    dealer: string
  } | null>(null)

  const { data: { data: { url: fileUrl = '' } = {} } = {}, isFetching } =
    useGetDealerFileQuery(feed, {
      skip: !feed,
    })

  useEffect(() => {
    setFeed(null)
  }, [])

  return (
    <Drawer
      title="Unused feeds"
      open={open}
      onClose={() => {
        onClose()
        setFeed(null)
      }}
      size={DrawerSize.XL}
    >
      <div className="flex gap-6">
        <div className="max-w-[420px] w-full shrink-0">
          {loading ? (
            <ListLoadingSkeleton rows={6} />
          ) : (
            <Accordion
              items={Object.keys(unusedFeeds).map(el => ({
                title: el,
                content: (
                  <div className="flex flex-col gap-3 w-full">
                    {unusedFeeds[el as InventoryType].map(
                      (item: IUnusedFeed) => (
                        <div
                          key={item.dealer}
                          className={clsx(
                            'p-4 flex justify-between gap-3 w-full border border-bg-stroke rounded-2xl hover:bg-blue-10 transition-all duration-300 items-center',
                            feed?.dealer === item.dealer ? 'bg-blue-20' : '',
                          )}
                        >
                          <div className="flex flex-col gap-1">
                            <Typography
                              variant="regular"
                              weight="medium"
                              className="text-black-100"
                            >
                              {item.dealer}
                            </Typography>
                            <Typography
                              className="text-black-50"
                              variant="small"
                              weight="normal"
                            >
                              Modified at:{' '}
                              {moment(item.modifiedAt).format(DATE_FORMAT_FULL)}
                            </Typography>
                          </div>
                          <Button
                            status="secondary"
                            size="small"
                            onClick={() => {
                              setFeed({
                                vendor: el as InventoryType,
                                dealer: item.dealer,
                              })
                            }}
                          >
                            View
                          </Button>
                        </div>
                      ),
                    )}
                  </div>
                ),
              }))}
            />
          )}
        </div>
        <div className="w-full overflow-auto">
          {feed && <FileViewer url={fileUrl} defaultLoading={isFetching} />}
        </div>
      </div>
    </Drawer>
  )
}

export default UnusedFeedModal
