import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Layout from '@/layout'
import {
  A2P,
  ACCESS_CONTROL,
  AI_BOT,
  APPOINTMENTS,
  AUTOMATIONS,
  BROADCASTING,
  DASHBOARD,
  DOCUMENTATION,
  ERROR_TRACKER,
  EXTERNAL_SALES,
  GOOGLE_AUTH_CLIENT_ID,
  INVENTORY,
  LEADS,
  LOCKED_LOCATIONS,
  ONBOARDING,
  ORGANIZATION,
  REPAIR_ORDERS,
  SALES,
  SERVICE_APPOINTMENTS,
  SETTINGS,
  SIGN_IN,
  SUCCESS_PORTAL_ORGANIZATION_SUMMARY,
  SUPERHUMAN,
  TASKS,
  V2_DASHBOARD,
  WORKFLOW,
  METRICS_INTELLIGENCE,
} from '@/constants'
import NotFound from '@/pages/404'
import { SignInPage } from '@/pages/SignIn'
import { GoogleOAuthProvider } from '@react-oauth/google'
import AuthWrapper from '@/layout/AuthWrapper'
import Automations from '@/pages/Automations'
import Leads from '@/pages/Leads'
import Appointments from '@/pages/Appointments'
import AiBotCustomers from '@/pages/AiBotCustomers'
import Inventory from '@/pages/Inventory'
import Analytics from '@/pages/Analytics'
import LockedLocations from '@/pages/LockedLocations'
import Superhuman from '@/pages/Superhuman'
import Organization from '@/pages/Organization'
import RepairOrders from '@/pages/Organization/RepairOrders'
import Settings from '@/pages/Organization/Settings'
import OrganizationAutomations from '@/pages/Organization/Automations'
import OrganizationTasks from '@/pages/Organization/Tasks'
import OrganizationAppointments from '@/pages/Organization/Appointments'
import OrganizationLeads from '@/pages/Organization/Leads'
import OrganizationAiBotCustomers from '@/pages/Organization/AiBotCustomers'
import V2Dashboard from '@/pages/V2Dashboard'
import V2Layout from '@/layout/V2Layout'
import WorkflowForm from '@/pages/V2Dashboard/WorkflowForm'
import UserSettings from '@/pages/Settings'
import AccessControl from '@/pages/AccessControl'
import ServiceAppointments from '@/pages/Organization/ServiceAppointments'
import AutomationsPageWrapper from '@/sections/AutomationsPageWrapper'
import ErrorTracker from '@/pages/ErrorTracker'
import SuccessPortal from '@/pages/SuccessPortal'
import Onboarding from '@/pages/SuccessPortal/Onboarding'
import Broadcasting from '@/pages/SuccessPortal/Broadcasting'
import SuccessPortalOrganizationSummary from '@/pages/SuccessPortal/OrganizationSummary'
import Documentation from '@/pages/SuccessPortal/Documentation'
import A2p from '@/pages/SuccessPortal/A2P'
import MetricsIntelligence from '@/pages/SuccessPortal/MetricsIntelligence'
import SalesDashboard from '@/pages/SuccessPortal/SalesDashboard'
import ExternalLayout from '@/layout/ExternalLayout'
import ExternalSalesDashboard from '@/pages/External/Sales'
import Redirect from '@/pages/Organization/Redirect'

const Navigation = () => {
  return (
    <Routes>
      <Route
        path={SIGN_IN}
        element={
          <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>
            <SignInPage />
          </GoogleOAuthProvider>
        }
      />
      <Route element={<AuthWrapper />}>
        <Route element={<Layout />}>
          <Route element={<AutomationsPageWrapper />}>
            {/*<Route path={DASHBOARD} element={<Analytics />} />*/}
            <Route path={DASHBOARD} element={<Automations />} />
            {/*<Route path={AUTOMATIONS} element={<Automations />} />*/}
            <Route path={LEADS} element={<Leads />} />
            <Route path={ERROR_TRACKER} element={<ErrorTracker />} />
            <Route path={APPOINTMENTS} element={<Appointments />} />
            <Route path={AI_BOT} element={<AiBotCustomers />} />
          </Route>
          <Route path={INVENTORY} element={<Inventory />} />
          <Route path={LOCKED_LOCATIONS} element={<LockedLocations />} />

          <Route path={SUPERHUMAN} element={<Superhuman />} />
          <Route path={SETTINGS} element={<UserSettings />} />
          <Route path={ACCESS_CONTROL} element={<AccessControl />} />
          <Route path={ORGANIZATION} element={<Organization />}>
            <Route index element={<Redirect />} />
            <Route
              path={ORGANIZATION + AUTOMATIONS}
              element={<OrganizationAutomations />}
            />
            <Route
              path={ORGANIZATION + TASKS}
              element={<OrganizationTasks />}
            />
            <Route
              path={ORGANIZATION + APPOINTMENTS}
              element={<OrganizationAppointments />}
            />
            <Route
              path={ORGANIZATION + LEADS}
              element={<OrganizationLeads />}
            />
            <Route
              path={ORGANIZATION + REPAIR_ORDERS}
              element={<RepairOrders />}
            />
            <Route
              path={ORGANIZATION + SERVICE_APPOINTMENTS}
              element={<ServiceAppointments />}
            />
            <Route path={ORGANIZATION + SETTINGS} element={<Settings />} />
            <Route
              path={ORGANIZATION + AI_BOT}
              element={<OrganizationAiBotCustomers />}
            />
          </Route>
          <Route element={<SuccessPortal />}>
            <Route path={A2P} element={<A2p />} />
            <Route
              path={METRICS_INTELLIGENCE}
              element={<MetricsIntelligence />}
            />
            <Route path={ONBOARDING} element={<Onboarding />} />
            <Route path={BROADCASTING} element={<Broadcasting />} />
            <Route
              path={SUCCESS_PORTAL_ORGANIZATION_SUMMARY}
              element={<SuccessPortalOrganizationSummary />}
            />
            <Route path={DOCUMENTATION} element={<Documentation />} />
            <Route path={SALES} element={<SalesDashboard />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route element={<V2Layout />}>
          <Route path={V2_DASHBOARD} element={<V2Dashboard />} />
          <Route path={WORKFLOW} element={<WorkflowForm />} />
        </Route>
      </Route>
      <Route path="/external" element={<ExternalLayout />}>
        <Route path={EXTERNAL_SALES} element={<ExternalSalesDashboard />} />
      </Route>
    </Routes>
  )
}

export default Navigation
