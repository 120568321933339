import React from 'react'
import { IEvent, ITaskParsedPayload } from '@/types'
import moment from 'moment-timezone'
import { DATE_FORMAT_FULL } from '@/constants'
import ImageView from '@/pages/Automations/Details/ImageView'
import { DataTable, Typography } from '@/components'

const getParsedPayloadFormatted = (parsedPayload?: ITaskParsedPayload[]) => {
  const keys = parsedPayload?.reduce<string[]>((acc, curr) => {
    return [...acc, ...Object.keys(curr)]
  }, [])

  if (keys) {
    return Array.from(new Set(keys)).map(header => ({
      accessorKey: header,
      header,
    }))
  }
}

const Content = ({
  event: { payload, createdAt },
  parsedPayload,
}: {
  event: IEvent
  parsedPayload?: ITaskParsedPayload[]
}) => {
  const headers = getParsedPayloadFormatted(parsedPayload)
  return (
    <div className="py-3">
      <div className="flex flex-col gap-1 break-all">
        <Typography variant="regular">
          Event completed at {moment(createdAt).format(DATE_FORMAT_FULL)}
        </Typography>

        {!!payload?.fetchedRowsCount && (
          <Typography variant="regular">
            {payload?.fetchedRowsCount} rows fetched
          </Typography>
        )}
        {!!payload?.parsedRowsCount && (
          <Typography variant="regular">
            {payload?.parsedRowsCount} rows parsed
          </Typography>
        )}
        {!!payload?.lambdaLogs && (
          <Typography variant="regular">
            Error logs: {Buffer.from(payload.lambdaLogs, 'base64').toString()}
          </Typography>
        )}
        {!!payload?.content && (
          <Typography variant="regular">
            {payload?.content?.toString()}
          </Typography>
        )}
        {!!payload?.screenshotLocation && (
          <ImageView s3Path={payload?.screenshotLocation} />
        )}
      </div>
      {parsedPayload && headers && (
        <div className="mt-3">
          <DataTable columns={headers} data={parsedPayload} />
        </div>
      )}
    </div>
  )
}

export default Content
