import { createSlice } from '@reduxjs/toolkit'

import { ISalesDashboardState } from '@/types'
import moment from 'moment-timezone'

const initialState: ISalesDashboardState = {
  filter: {
    organizationId: null,
    startDate: moment().subtract(7, 'd').toString(),
    endDate: moment().endOf('day').toString(),
  },
}

const salesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    onFilterChange: (state, action) => {
      state.filter = { ...state.filter, ...action.payload }
    },
  },
})

export const { onFilterChange } = salesSlice.actions
export default salesSlice.reducer
