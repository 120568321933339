import React from 'react'
import PageHeading from '@/sections/PageHeading'
import { DataTable, Tag, Typography } from '@/components'
import Pagination from '@/components/Pagination'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { onFilterChange } from '@/store/slices/pages/appointment.slice'
import AppointmentFilter from '@/pages/Appointments/Filter'
import { appointmentTableColumns } from '@/pages/Appointments/config'
import { useGetAppointmentsQuery } from '@/api/services/organization/appointment'
import Card from '@/components/Card'
import { ReactComponent as ArrowUpIcon } from '@/assets/icons/ArrowUp.svg'
import { useGetStatsQuery } from '@/api/services/statistic'

const Appointments = () => {
  const dispatch = useAppDispatch()
  const { filter } = useAppSelector(state => state.appointments)

  const {
    data: {
      appointment: {
        successRate: appointmentSuccessRate = 0,
        completed = 0,
      } = {},
    } = {},
  } = useGetStatsQuery(undefined)

  const {
    data: { data: appointments = [], pageMeta: { totalItems = 0 } = {} } = {},
    isFetching: loading,
  } = useGetAppointmentsQuery(filter)
  const onPageChange = (page: number) => {
    dispatch(
      onFilterChange({
        page,
      }),
    )
  }

  return (
    <div className="w-full flex flex-col gap-6">
      <PageHeading title="Appointments" />
      <div>
        <Card className="!p-5 flex flex-col justify-between gap-2 max-w-64">
          <Typography variant="regular" weight="medium">
            Appointment Confirmation
          </Typography>
          <div className="flex gap-2 items-center">
            <Typography variant="H2" weight="semibold">
              {completed}
            </Typography>
            <Tag status="success" IconLeft={ArrowUpIcon} variant="light">
              {appointmentSuccessRate}%
            </Tag>
          </div>
        </Card>
      </div>
      <AppointmentFilter />
      <DataTable
        loading={loading}
        columns={appointmentTableColumns}
        data={appointments}
      />
      <Pagination
        currentPage={filter.page}
        totalCount={totalItems}
        onPageChange={onPageChange}
      />
    </div>
  )
}

export default Appointments
