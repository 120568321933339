import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import {
  ICheckAuthResponse,
  IUserSettingsResponse,
  IUserState,
  permissionType,
  permissionValueType,
} from '@/types/user'
import { getUserWithSettings } from '@/api/services/user'
import {
  checkPermissions,
  checkSuperhuman,
  getResourceAndLevel,
} from '@/utils/permissions'
import { generateNavigationPathsByPermissions } from '@/utils'
import { TMenu } from '@/types'
import { RootState } from '@/store'

const initialState: IUserState = {
  isLoading: true,
  isAdmin: false,
  isSuperAdmin: false,
  _id: '',
  _organization_id: '',
  permissions: [],
  organizations: [],
  error: undefined,
  isLoggedIn: false,
}

export const getUser = createAsyncThunk<
  ICheckAuthResponse & IUserSettingsResponse,
  undefined,
  { rejectValue: { message: string } }
>('user/getUser', async (_, thunkApi) => {
  try {
    const res = await getUserWithSettings()
    return { ...res.user.data, ...res.settings.data }
  } catch (error) {
    return thunkApi.rejectWithValue({
      message: 'Something went wrong',
    })
  }
})

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getUser.fulfilled, (state: IUserState, action) => {
      if (!action.payload) {
        state.isLoggedIn = true
        state.isLoading = false
      }
      const {
        _id,
        _organization_id,
        isAdmin,
        isSuperAdmin,
        permissions,
        organizations,
      } = action.payload
      state._id = _id
      state._organization_id = _organization_id
      state.isAdmin = isAdmin
      state.isSuperAdmin = isSuperAdmin
      state.permissions = permissions
      state.organizations = organizations
      state.isLoggedIn = true
      state.isLoading = false
    })
    builder.addCase(getUser.rejected, (state: IUserState, action) => {
      state.error = action.payload?.message
      state.isLoading = false
    })
  },
  selectors: {
    userPermissions: (state: IUserState) => {
      return state.permissions
    },
  },
})

export const userPermissions = createSelector(
  (state: RootState) => state.user,
  (user: IUserState) => user.permissions,
)

export const accessList = createSelector(userPermissions, permissions => {
  return Object.keys(permissionType).reduce(
    (acc, key) => {
      acc[key as keyof typeof permissionType] = checkPermissions(
        getResourceAndLevel(permissions) as permissionValueType[],
        [permissionType[key as keyof typeof permissionType]],
      )
      return acc
    },
    {} as Record<keyof typeof permissionType, boolean>,
  )
})

export const isSuperhuman = createSelector(userPermissions, permissions =>
  checkSuperhuman(getResourceAndLevel(permissions) as permissionValueType[]),
)

export const availablePaths = createSelector(userPermissions, permissions =>
  generateNavigationPathsByPermissions(
    getResourceAndLevel(permissions) as permissionValueType[],
  ),
)

export const hasAccess = createSelector(
  [availablePaths, (state, pathname) => pathname],
  (paths, pathname) =>
    paths.some(
      ({ path, subMenus }) =>
        pathname.replace('/', '').includes(path.replace('/', '')) ||
        subMenus?.some(({ path }: TMenu) => pathname.includes(path)),
    ),
)

export const notPermitted = createSelector([hasAccess], access => !access)

export const { setLoading } = userSlice.actions
export default userSlice.reducer
