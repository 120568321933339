import React from 'react'
import moment from 'moment-timezone'
import { DATE_FORMAT } from '@/constants'
import { capitalize, renderUserFullName } from '@/utils'
import { ColumnDef } from '@tanstack/react-table'
import { ILead } from '@/types'
import OrganizationLink from '@/sections/OrganizationLink'
import { Tag } from '@/components'

export const leadTableColumns: ColumnDef<ILead, any>[] = [
  {
    accessorKey: 'firstName',
    header: () => <span>Name</span>,
    cell: info =>
      renderUserFullName(
        info.row.original.firstName,
        info.row.original.lastName,
      ),
  },
  {
    accessorKey: 'phoneNumber',
    header: () => <span>Phone Number</span>,
    cell: info => info.getValue(),
  },
  {
    accessorKey: 'crm',
    header: () => <span>CRM</span>,
    cell: info => capitalize(info.getValue()),
  },
  {
    accessorKey: 'organization',
    header: () => <span>Organization</span>,
    cell: info => {
      const data = info.row.original.organization
      return <OrganizationLink id={data._id} name={data.name} />
    },
  },
  {
    accessorKey: 'location',
    header: () => <span>Location</span>,
    cell: info => (
      <span className="text-blue-100">{info.row.original.location.name}</span>
    ),
  },
  {
    accessorKey: 'createdAt',
    header: () => <span>Created at</span>,
    cell: info => moment(info.getValue()).format(DATE_FORMAT),
  },
  {
    accessorKey: 'isImported',
    header: () => <span>Imported to Matador</span>,
    cell: ({ getValue }) =>
      getValue() ? (
        <Tag variant="light" status="success">
          Imported
        </Tag>
      ) : null,
  },
]
