import React from 'react'
import { Outlet } from 'react-router-dom'
import { Logo } from '@/components'

const ExternalLayout = () => {
  return (
    <div className="flex flex-col gap-6  min-h-screen w-full bg-[#080224]">
      <div className="flex justify-center items-center px-8 py-4 w-full sticky top-0  z-10">
        <Logo type="large" />
      </div>
      <div className="w-full px-8 grow flex">
        <Outlet />
      </div>
    </div>
  )
}

export default ExternalLayout
