import { createSlice } from '@reduxjs/toolkit'
import { defaultPageMeta } from '@/constants'

import { IOrganizationSummaryState } from '@/types'

const initialState: IOrganizationSummaryState = {
  loading: false,
  error: undefined,
  filter: {
    ...defaultPageMeta,
    search: '',
    startDate: '',
  },
}

const organizationSummarySlice = createSlice({
  name: 'organizationSummary',
  initialState,
  reducers: {
    clearFilter: state => {
      state.filter = initialState.filter
    },
    onFilterChange: (state, action) => {
      state.filter = { ...state.filter, ...action.payload }
    },
  },
})

export const { clearFilter, onFilterChange } = organizationSummarySlice.actions
export default organizationSummarySlice.reducer
