import { createSlice } from '@reduxjs/toolkit'

import { ICDKDriveState } from '@/types'

const initialState: ICDKDriveState = {
  isWorkflowRequest: false,
  selectedFeatures: [],
  serviceAdvisorId: true,
  notes: true,
  opCodes: true,
  historicalData: true,
}

const cdkDriveSlice = createSlice({
  name: 'cdkDriveSlice',
  initialState,
  reducers: {
    clearAll: state => {
      state.selectedFeatures = []
    },
    onFeatureChange: (state, action) => {
      state.selectedFeatures = action.payload
    },
    toggleWorkflowRequest: (state, action) => {
      state.isWorkflowRequest = action.payload
    },
    toggleIncludedInfo: (state, action) => {
      switch (action.payload) {
        case 'serviceAdvisorId':
          state.serviceAdvisorId = !state.serviceAdvisorId
          break
        case 'notes':
          state.notes = !state.notes
          break
        case 'opCodes':
          state.opCodes = !state.opCodes
          break
        case 'historicalData':
          state.historicalData = !state.historicalData
          break
        default:
          break
      }
    },
  },
})

export const {
  clearAll,
  onFeatureChange,
  toggleWorkflowRequest,
  toggleIncludedInfo,
} = cdkDriveSlice.actions
export default cdkDriveSlice.reducer
