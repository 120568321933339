import React from 'react'
import { Link, Outlet, useParams } from 'react-router-dom'
import { Button, Logo } from '@/components'
import { AUTOMATIONS, DASHBOARD, ORGANIZATION } from '@/constants'
import { ReactComponent as ArrowLeft } from '@/assets/icons/Arrow Left.svg'
import { generatePath } from '@/utils'

const V2Layout = () => {
  const { id } = useParams()

  const backToV1Link = generatePath(ORGANIZATION + AUTOMATIONS, { id })

  return (
    <div className="flex flex-col gap-6 bg-white-100 min-h-screen">
      <div className="flex justify-between items-center px-8 py-4 w-full sticky top-0 bg-[#080224] z-10">
        <Link to={DASHBOARD}>
          <Logo type="large" />
        </Link>
        <Button status="primary" size="small" as="Link" to={backToV1Link}>
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to V1
        </Button>
      </div>
      <div className="w-full px-8 grow flex">
        <Outlet />
      </div>
    </div>
  )
}

export default V2Layout
