import React from 'react'
import { useGetEventImageQuery } from '@/api/services/organization/organization-automation'
import { Typography } from '@/components'

const ImageView = ({ s3Path }: { s3Path?: string }) => {
  const { data } = useGetEventImageQuery({ s3Path }, { skip: !s3Path })
  return (
    <div className="py-4 flex flex-col gap-3">
      <Typography variant="regular" weight="medium">
        Screenshot of the Browser instance at the time of failure
      </Typography>
      <img alt="" className="w-3/4" src={`data:image/png;base64, ${data}`} />
    </div>
  )
}

export default ImageView
