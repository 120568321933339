import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { googleLogin, login } from '@/api/services/auth'
import {
  IAuthState,
  IGoogleLoginCredentials,
  ILoginCredentials,
} from '@/types/auth'

const initialState: IAuthState = {
  loading: false,
  token: null,
  error: undefined,
}

export const loginUser = createAsyncThunk<
  string,
  ILoginCredentials,
  { rejectValue: { message: string } }
>('auth/login', async (data, thunkApi) => {
  try {
    const res = await login(data)
    return res.data
  } catch (error) {
    return thunkApi.rejectWithValue({
      message: 'Login failed',
    })
  }
})

export const loginUserByGoogle = createAsyncThunk<
  string,
  IGoogleLoginCredentials,
  { rejectValue: { message: string } }
>('auth/google', async (data, thunkApi) => {
  try {
    const res = await googleLogin(data)
    return res.data
  } catch (error) {
    return thunkApi.rejectWithValue({
      message: 'Login failed',
    })
  }
})

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clear: state => {
      state.token = null
    },
  },
  extraReducers: builder => {
    builder.addCase(loginUser.pending, (state: IAuthState) => {
      state.loading = true
    })
    builder.addCase(loginUser.fulfilled, (state: IAuthState, action) => {
      state.token = action.payload
      state.loading = false
    })
    builder.addCase(loginUser.rejected, (state: IAuthState, action) => {
      state.error = action.payload?.message
      state.loading = false
    })
    builder.addCase(loginUserByGoogle.pending, (state: IAuthState) => {
      state.loading = true
    })
    builder.addCase(
      loginUserByGoogle.fulfilled,
      (state: IAuthState, action) => {
        state.token = action.payload
        state.loading = false
      },
    )
    builder.addCase(loginUserByGoogle.rejected, (state: IAuthState, action) => {
      state.error = action.payload?.message
      state.loading = false
    })
  },
})

export const { clear } = authSlice.actions
export default authSlice.reducer
