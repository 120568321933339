import React from 'react'
import { TagStatus, TAutomationType } from '@/types'

import { Tag } from '@/components'
import { capitalize } from '@/utils'

const automationTypeStatuses: Record<TAutomationType, TagStatus> = {
  automation: 'success',
  appointment: 'secondary',
  lead: 'warning',
  aibot: 'primary',
  workflow: 'info',
  resurrection: 'error',
  weeklyreport: 'default',
}

const AutomationType = ({ type }: { type: TAutomationType }) => {
  return (
    <Tag variant="light" status={automationTypeStatuses[type]}>
      {capitalize(type)}
    </Tag>
  )
}

export default AutomationType
