import { Switch } from '@headlessui/react'
import clsx from 'clsx'
import { Typography } from '@/components'

interface ToggleProps {
  checked: boolean
  className?: string
  onChange: (val: boolean) => void
  disabled?: boolean
  label?: string
}

export const Toggle = ({
  checked,
  onChange,
  className,
  disabled,
  label,
}: ToggleProps) => {
  return (
    <div className="flex gap-2 items-center">
      <Switch
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        className={clsx(
          'group inline-flex h-6 w-10 items-center rounded-full bg-black-5 transition data-[checked]:bg-blue-100 p-0.5',
          className,
        )}
      >
        <span className="size-5 shadow-sm -translate-x-0 rounded-full bg-white-100 transition duration-300 group-data-[checked]:translate-x-4" />
      </Switch>
      {label && (
        <Typography variant="regular" weight="medium">
          {label}
        </Typography>
      )}
    </div>
  )
}

export default Toggle
