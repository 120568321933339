import React from 'react'
import { TIcon } from '@/types'
import { Typography } from '@/components'

interface ComingSoonProps {
  title?: string
  description?: string
  Icon: TIcon
}
const ComingSoon = ({
  title = 'Coming Soon!',
  Icon,
  description,
}: ComingSoonProps) => {
  return (
    <div className="flex flex-col max-w-md mx-auto pt-52 text-center gap-4 items-center px-1">
      <div className="w-24 h-24 rounded-full bg-blue-10 flex items-center justify-center">
        <Icon className="w-14 h-auto mx-auto" />
      </div>
      <Typography variant="H2" weight="semibold">
        {title}
      </Typography>
      {description && (
        <Typography variant="regular" className="text-black-80">
          {description}
        </Typography>
      )}
    </div>
  )
}

export default ComingSoon
