import React, { useEffect } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts'
import moment from 'moment'
import { IErrorTrackerWithStats, TErrorCodes } from '@/types'
import { errorCodeColors, errorCodes } from '@/constants'

const CustomLineChart = ({ data }: { data: IErrorTrackerWithStats[] }) => {
  const [info, setInfo] = React.useState(data ? data : [])
  useEffect(() => {
    if (data.length > 60) {
      const formatted = data.reduce<IErrorTrackerWithStats[]>((acc, curr) => {
        const month = moment(curr.date).format('MMM')
        const year = moment(curr.date).format('YYYY')
        const existing = acc.find(
          item =>
            moment(item.date).format('MMM') === month &&
            moment(item.date).format('YYYY') === year,
        )
        if (existing) {
          const values = { ...existing }
          Object.keys(curr).forEach(code => {
            if (existing[code as TErrorCodes]) {
              values[code as TErrorCodes] += curr[code as TErrorCodes] || 0
            }
            if (code === 'date') {
              values.date = moment(existing.date).format('MMM YYYY')
            }
          })
          acc = acc.map(item => {
            if (
              moment(item.date).format('MMM') === month &&
              moment(item.date).format('YYYY') === year
            ) {
              return values
            }
            return item
          })
        } else {
          acc.push(curr)
        }
        return acc
      }, [])
      setInfo(formatted)
    } else {
      setInfo(
        data.map(item => {
          return {
            ...item,
            date: moment(item.date).format('MMM DD'),
          }
        }),
      )
    }
  }, [data])

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={info}
        margin={{
          top: 24,
          right: 30,
          left: 20,
          bottom: 24,
        }}
      >
        <XAxis
          axisLine={false}
          dataKey="date"
          tickLine={false}
          tick={{ fill: '#0802247F' }}
          // tick={({ x, y, payload }) => {
          //   const isFirstDayOfMonth = moment(payload.value).date() === 1;
          //   if (isFirstDayOfMonth) {
          //     return (
          //       <text x={x} y={y} textAnchor="middle" fill="#0802247F" fontSize="12px">
          //         {moment(payload.value).format('MMM')} {/* Display month name */}
          //       </text>
          //     );
          //   }
          //   return null;
          // }}
          orientation="top"
          padding={{ left: 24, right: 24 }}
          tickMargin={20}
          style={{
            fontSize: '12px',
          }}
        />
        <CartesianGrid horizontal={{ fill: '#E7EAF0' }} vertical={false} />
        <YAxis
          tickLine={false}
          axisLine={false}
          tick={{ fill: '#0802247F' }}
          domain={[0, 'dataMax + 100']}
          style={{
            fontSize: '12px',
          }}
        />
        <Tooltip />
        {Object.keys(errorCodes).map(code => (
          <Line
            key={code}
            type="monotone"
            dataKey={code}
            stroke={errorCodeColors[code as TErrorCodes]}
            dot={false}
            activeDot={{ r: 8 }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  )
}

export default CustomLineChart
