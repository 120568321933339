import { createSlice } from '@reduxjs/toolkit'
import { defaultPageMeta } from '@/constants'
import moment from 'moment-timezone'

import { IAutomationState } from '@/types/automation'

const initialState: IAutomationState = {
  loading: false,
  error: undefined,
  filter: {
    ...defaultPageMeta,
    automationType: 'automation',
    startDate: moment().subtract(7, 'd').toString(),
    endDate: moment().endOf('day').toString(),
    organizationIds: [],
    locationId: '',
    crm: '',
    status: '',
  },
  details: undefined,
}

const automationSlice = createSlice({
  name: 'automation',
  initialState,
  reducers: {
    clearFilter: state => {
      state.filter = initialState.filter
    },
    setDetails: (state, action) => {
      state.details = action.payload
    },
    onFilterChange: (state, action) => {
      state.filter = { ...state.filter, ...action.payload }
    },
  },
})

export const { clearFilter, onFilterChange, setDetails } =
  automationSlice.actions
export default automationSlice.reducer
