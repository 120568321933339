import React from 'react'
import { ITask } from '@/types'
import {
  automationApi,
  useRetryMutation,
} from '@/api/services/organization/organization-automation'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { Tag } from '@/components'
import { statuses } from '@/constants/automations'

const AutomationTableActions = ({
  task,
  afterAction,
  onClick,
}: {
  task: ITask
  afterAction: () => void
  onClick?: () => void
}) => {
  const { filter } = useAppSelector(state => state.automation)
  const dispatch = useAppDispatch()
  const canRetry = task.status === 'failed'
  const [retry] = useRetryMutation()

  return (
    <div className="flex gap-1 items-center justify-end w-full">
      <Tag
        variant="light"
        status={statuses[task.status].color}
        onClick={onClick}
      >
        {statuses[task.status].text}
      </Tag>
      {canRetry && (
        <Tag
          status="info"
          variant="light"
          onClick={() => {
            if (canRetry) {
              retry({ id: task._id })
                .unwrap()
                .then(() => {
                  dispatch(
                    automationApi.util.updateQueryData(
                      'getAllTask',
                      filter,
                      draft => {
                        const updateItem = draft.data.find(
                          item => item._id === task._id,
                        )

                        if (updateItem) {
                          const updatedTask = {
                            ...updateItem,
                            status: 'pending',
                          }
                          Object.assign(updateItem, updatedTask)
                        }
                      },
                    ),
                  )

                  afterAction()
                })
            }
          }}
        >
          Retry
        </Tag>
      )}
    </div>
  )
}

export default AutomationTableActions
