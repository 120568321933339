import { ColumnDef } from '@tanstack/react-table'
import { IListUser, ParsedPermissionWidthActions } from '@/types'

export const accessControlColumns: ColumnDef<IListUser, any>[] = [
  {
    id: 'name',
    accessorKey: 'name',
    header: () => <span>Name</span>,
    cell: ({ getValue }) => (
      <span className="font-medium text-black-100">{getValue()}</span>
    ),
  },
  {
    id: 'email',
    accessorKey: 'email',
    header: () => <span>Email</span>,
  },
  {
    id: 'actions',
    accessorKey: 'email',
    header: () => '',
  },
]

export const accessControlPermissionColumns: ColumnDef<
  ParsedPermissionWidthActions,
  any
>[] = [
  {
    id: 'resource',
    accessorKey: 'resource',
    header: () => <span>Resource</span>,
    size: 300,
  },
  {
    id: 'level',
    accessorKey: 'level',
    header: () => <span>Level</span>,
    size: 300,
  },
  {
    id: 'organizations',
    accessorKey: 'organizations',
    header: () => <span>Organizations</span>,
    size: 300,
  },
  {
    id: 'actions',
    accessorKey: 'resource',
    header: () => '',
    size: 110,
  },
]
