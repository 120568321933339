import React, { useCallback, useMemo } from 'react'
import { Select, Typography } from '@/components'
import { ISales, ISalesRow } from '@/types'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { onFilterChange } from '@/store/slices/pages/successPortal/sales.slice'
import DatePicker from '../../../../components/DatePicker'
import ExpandableTable from '@/sections/ExpandableTable'
import { salesTableColumns } from '@/pages/SuccessPortal/SalesDashboard/config'
import clsx from 'clsx'
import SalesListView from '@/pages/SuccessPortal/SalesDashboard/ListView'
import { useGetOrganizationsQuery } from '@/api/services/organization-list'
import { useGetSalesQuery } from '@/api/services/sales'
import moment from 'moment-timezone'

const SalesData = ({
  isDark,
  id,
  name: orgName,
}: {
  isDark?: boolean
  id?: string | null
  name?: string | null
}) => {
  const dispatch = useAppDispatch()
  const [customers, setCustomers] = React.useState<ISalesRow[] | null>(null)
  const [sectionName, setSectionName] = React.useState<string>('')
  const {
    filter: { organizationId, startDate, endDate },
  } = useAppSelector(state => state.sales)
  const { data: organizations = [] } = useGetOrganizationsQuery(undefined, {
    skip: !!id,
  })
  const onOrganizationChange = useCallback(
    (val: string | null) => {
      dispatch(onFilterChange({ organizationId: val }))
    },
    [dispatch],
  )

  const onDateChange = useCallback(
    (type: 'startDate' | 'endDate') => (date: Date | undefined) => {
      dispatch(
        onFilterChange({
          [type]: date,
        }),
      )
    },
    [dispatch],
  )

  const organization = id || organizationId

  const { data, isFetching } = useGetSalesQuery(
    { organizationId: organization, startDate, endDate },
    { skip: !organization },
  )

  const onItemClick = useCallback((item: ISales) => {
    if (item.customers?.length) {
      setCustomers(item.customers)
      setSectionName(item.name)
    }
  }, [])

  const generatedData = useMemo(() => {
    if (!data) return []
    return data.reduce((acc: ISales[], el) => {
      if (el.sectionName === 'none') {
        acc.push({ ...el, subRows: [] })
      } else {
        const sectionName = el.sectionName
        const accIndex = acc.findIndex(el => el.name === sectionName)
        if (accIndex === -1) {
          acc.push({
            ...el,
            name: sectionName,
            subRows: [el],
            description: '',
            leads: Number(el.leads) || 0,
          })
        } else {
          acc[accIndex].subRows?.push(el)
          // sum other columns
          acc[accIndex].leads += Number(el.leads) || 0
          acc[accIndex].engagedCustomers += el.engagedCustomers
          acc[accIndex].sales += el.sales
        }
      }
      return acc
    }, [])
  }, [data])

  generatedData.sort((a, b) => {
    if (a.sectionName === 'none' && b.sectionName !== 'none') return -1
    if (a.sectionName !== 'none' && b.sectionName === 'none') return 1

    if (a.sectionName === 'none' && b.sectionName === 'none') {
      return a.name.localeCompare(b.name)
    }
    return a.name.localeCompare(b.name)
  })

  const fileName = useMemo(() => {
    const organization = organizations.find(el => el._id === organizationId)
    let name = `${moment(startDate).format('YYYY/MM/DD')}-${moment(endDate).format('YYYY/MM/DD')}`

    if (orgName) {
      name = `${orgName}-${name}`
    } else {
      if (organization) {
        name = `${organization.name}-${name}`
      }
    }
    if (sectionName) {
      name = `${name}-${sectionName}`
    }
    return `${name}-sales.csv`
  }, [endDate, orgName, organizationId, organizations, sectionName, startDate])

  return (
    <div className="flex flex-col gap-6">
      <div className="grid grid-cols-5">
        <div className="col-span-4">
          <Typography
            variant="H3"
            weight="semibold"
            className={clsx({ 'text-white-100': isDark })}
          >
            {!id
              ? 'Please select an organization to view Sales Data'
              : `${orgName} sales attribution report`}
          </Typography>
        </div>
        {!id && (
          <Select
            options={organizations}
            onChange={val => onOrganizationChange(val ? val._id : null)}
            value={organizations.find(el => el._id === organizationId)}
            variant={isDark ? 'dark' : 'light'}
          />
        )}
      </div>
      <div className="grid grid-cols-5 gap-3">
        <DatePicker
          label="Start Date"
          value={new Date(startDate)}
          onChange={onDateChange('startDate')}
          placeholder="Select a date range"
          theme={isDark ? 'dark' : 'light'}
        />
        <DatePicker
          label="End Date"
          value={new Date(endDate)}
          onChange={onDateChange('endDate')}
          placeholder="Select a date range"
          theme={isDark ? 'dark' : 'light'}
        />
      </div>
      <ExpandableTable
        theme={isDark ? 'dark' : 'light'}
        data={generatedData}
        columns={salesTableColumns(isDark ? 'dark' : 'light', onItemClick)}
        loading={isFetching}
      />
      <SalesListView
        open={!!customers}
        onClose={() => {
          setCustomers(null)
          setSectionName('')
        }}
        items={customers}
        isDark={isDark}
        section={sectionName}
        fileName={fileName}
      />
    </div>
  )
}

export default SalesData
