import React, { ReactNode } from 'react'
import Modal from '@/components/Modal'
import { Button } from '@/components'

interface ConfirmModalProps {
  title: string
  onClose: () => void
  open: boolean
  isLoading: boolean
  content: string | ReactNode
  onConfirm: () => void
}
const ConfirmModal = ({
  title,
  open,
  onClose,
  content,
  onConfirm,
  isLoading,
}: ConfirmModalProps) => {
  return (
    <Modal
      title={title}
      onClose={onClose}
      open={open}
      footer={
        <>
          <Button
            size="small"
            status="secondary"
            onClick={onClose}
            className="w-full flex-1"
          >
            Cancel
          </Button>
          <Button
            size="small"
            status="primary"
            onClick={onConfirm}
            loading={isLoading}
            className="w-full flex-1"
          >
            Confirm
          </Button>
        </>
      }
    >
      {content}
    </Modal>
  )
}

export default ConfirmModal
