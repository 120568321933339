import { combineReducers, configureStore } from '@reduxjs/toolkit'
import authReducer from './slices/auth.slice'
import userReducer from './slices/user.slice'
import notificationReducer from './slices/notification.slice'
import automationReducer from './slices/pages/automation.slice'
import workflowReducer from './slices/workflow.slice'
import accessReducer from './slices/access.slice'
import leadsReducer from './slices/pages/lead.slice'
import appointmentReducer from './slices/pages/appointment.slice'
import errorTrackerReducer from './slices/pages/error-tracker.slice'
import aiBotReducer from './slices/pages/ai-bot.slice'
import analyticsReducer from './slices/pages/analytics.slice'
import inventoryReducer from './slices/pages/inventory.slice'
import systemMonitoringReducer from './slices/pages/systemMonitoring.slice'
import lockedLocationReducer from './slices/pages/lockedLocation.slice'
import superhumanReducer from './slices/superhuman.slice'
import RepairOrderReducer from './slices/repait-order.slice'
import ServiceAppointmentReducer from './slices/service-appointment.slice'
import organizationAutomationReducer from './slices/pages/organization/organization-automations.slice'
import organizationSummaryReducer from './slices/pages/successPortal/organization-summary.slice'
import salesReducer from './slices/pages/successPortal/sales.slice'
import cdkDriveReducer from './slices/pages/successPortal/cdk-drive.slice'
import { automationApi } from '@/api/services/organization/organization-automation'
import { setupListeners } from '@reduxjs/toolkit/query'
import { taskApi } from '@/api/services/task'
import { appointmentApi } from '@/api/services/organization/appointment'
import { leadApi } from '@/api/services/organization/lead'
import { aiBotApi } from '@/api/services/organization/ai-bot'
import { repairOrderApi } from '@/api/services/repair-order'
import { workflowApi } from '@/api/services/workflow'
import { locationApi } from '@/api/services/location'
import { organizationApi } from '@/api/services/organization'
import { settingsApi } from '@/api/services/settings'
import { salesApi } from '@/api/services/sales'
import { statsApi } from '@/api/services/statistic'
import { inventoryApi } from '@/api/services/inventory'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'
import { crmApi } from '@/api/services/crm'
import { accessApi } from '@/api/services/acsess'
import { serviceAppointmentApi } from '@/api/services/service-appointment'
import { organizationListApi } from '@/api/services/organization-list'
import { errorCodesApi } from '@/api/services/error-codes'

const organizationPersistConfig = {
  key: 'getOrganizations',
  storage,
  whitelist: ['queries'],
}

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  notifications: notificationReducer,
  // for page state
  automation: automationReducer,
  leads: leadsReducer,
  access: accessReducer,
  appointments: appointmentReducer,
  aiBot: aiBotReducer,
  analytics: analyticsReducer,
  inventory: inventoryReducer,
  systemMonitoring: systemMonitoringReducer,
  lockedLocations: lockedLocationReducer,
  superhuman: superhumanReducer,
  errorTracker: errorTrackerReducer,
  repairOrders: RepairOrderReducer,
  serviceAppointments: ServiceAppointmentReducer,
  workflow: workflowReducer,
  organizationAutomations: organizationAutomationReducer,
  organizationSummary: organizationSummaryReducer,
  cdkDrive: cdkDriveReducer,
  sales: salesReducer,
  [automationApi.reducerPath]: automationApi.reducer,
  [taskApi.reducerPath]: taskApi.reducer,
  [appointmentApi.reducerPath]: appointmentApi.reducer,
  [leadApi.reducerPath]: leadApi.reducer,
  [aiBotApi.reducerPath]: aiBotApi.reducer,
  [repairOrderApi.reducerPath]: repairOrderApi.reducer,
  [workflowApi.reducerPath]: workflowApi.reducer,
  [locationApi.reducerPath]: locationApi.reducer,
  [organizationApi.reducerPath]: organizationApi.reducer,
  [organizationListApi.reducerPath]: persistReducer(
    organizationPersistConfig,
    organizationListApi.reducer,
  ),
  [settingsApi.reducerPath]: settingsApi.reducer,
  [statsApi.reducerPath]: statsApi.reducer,
  [crmApi.reducerPath]: crmApi.reducer,
  [inventoryApi.reducerPath]: inventoryApi.reducer,
  [accessApi.reducerPath]: accessApi.reducer,
  [serviceAppointmentApi.reducerPath]: serviceAppointmentApi.reducer,
  [salesApi.reducerPath]: salesApi.reducer,
  [errorCodesApi.reducerPath]: errorCodesApi.reducer,
})

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(automationApi.middleware)
      .concat(taskApi.middleware)
      .concat(appointmentApi.middleware)
      .concat(aiBotApi.middleware)
      .concat(repairOrderApi.middleware)
      .concat(workflowApi.middleware)
      .concat(locationApi.middleware)
      .concat(organizationApi.middleware)
      .concat(settingsApi.middleware)
      .concat(workflowApi.middleware)
      .concat(statsApi.middleware)
      .concat(inventoryApi.middleware)
      .concat(crmApi.middleware)
      .concat(accessApi.middleware)
      .concat(serviceAppointmentApi.middleware)
      .concat(organizationListApi.middleware)
      .concat(errorCodesApi.middleware)
      .concat(salesApi.middleware)
      .concat(leadApi.middleware),
})

setupListeners(store.dispatch)

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
