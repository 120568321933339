import React from 'react'
import clsx from 'clsx'

interface CardWrapperProps {
  children: React.ReactNode
  className?: string
}
const CardWrapper = ({ children, className }: CardWrapperProps) => {
  return (
    <div
      className={clsx(
        'rounded-2xl p-5 border border-gray-100 bg-white-100 w-full',
        className,
      )}
    >
      {children}
    </div>
  )
}

export default CardWrapper
