import React from 'react'
import { Option, Select, Tag } from '@/components'
import { capitalize } from '@/utils'
import { ParsedPermissionWidthActions } from '@/types'

const LevelSection = ({
  permission,
  levels,
  onChange,
  error,
}: {
  permission: ParsedPermissionWidthActions
  levels: Option[]
  onChange: (val: string) => void
  error: string
}) => {
  const value = permission.level
  const isEdit = permission.edit
  const isAdd = permission.add
  const isDelete = permission.delete
  return (
    <div className="w-full">
      <div className="w-full">
        {isEdit || isAdd ? (
          <Select
            error={error}
            className="w-full"
            options={levels}
            value={levels.find(res => res._id === value)}
            onChange={val => onChange(val?._id as string)}
          />
        ) : (
          <span className={isDelete ? 'line-through opacity-70' : ''}>
            <Tag
              status={
                value === 'read'
                  ? 'info'
                  : value === 'write'
                    ? 'success'
                    : 'error'
              }
              variant="light"
            >
              {capitalize(value)}
            </Tag>
          </span>
        )}
      </div>
    </div>
  )
}

export default LevelSection
