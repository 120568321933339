import { IOrganization } from '@/types'
import { endpoints } from '@/api/constants'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINT, AUTH_TOKEN_KEY } from '@/constants'
import { getStorageValue } from '@/utils'

export const organizationListApi = createApi({
  reducerPath: 'organizationListApi',
  tagTypes: ['OrganizationList'],
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT,
    prepareHeaders: headers => {
      const token = getStorageValue(AUTH_TOKEN_KEY)
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  endpoints: builder => ({
    getOrganizations: builder.query<IOrganization[], undefined>({
      query: () => {
        return {
          url: endpoints.organizations,
          method: 'POST',
        }
      },
      providesTags: ['OrganizationList'],
    }),
  }),
})

export const { useGetOrganizationsQuery } = organizationListApi
