import { endpoints } from '@/api/constants'
import { IStatistic } from '@/types'
import { createApi } from '@reduxjs/toolkit/query/react'
import { createBaseQuery } from '@/utils'
import { IAnalyticsFilter } from '@/types/analytics'

export const statsApi = createApi({
  reducerPath: 'statsApi',
  tagTypes: ['Stats'],
  baseQuery: createBaseQuery(),
  endpoints: builder => ({
    getStats: builder.query<IStatistic, Partial<IAnalyticsFilter> | undefined>({
      query: params => {
        return {
          url: endpoints.stats,
          method: 'POST',
          body: params,
        }
      },
    }),
  }),
})

export const { useGetStatsQuery } = statsApi
