import React from 'react'
import moment from 'moment-timezone'
import { DATE_FORMAT } from '@/constants'
import { ColumnDef } from '@tanstack/react-table'
import { ITask } from '@/types'
import OrganizationLink from '@/sections/OrganizationLink'

export const automationTableColumns: ColumnDef<ITask, any>[] = [
  {
    id: 'organization',
    header: () => <span>Organization</span>,
    cell: info => {
      const data = info.row.original.organization
      return <OrganizationLink id={data._id} name={data.name} />
    },
  },
  {
    id: 'location',
    header: () => <span>Location</span>,
    cell: info => info.row.original.location.name,
  },
  {
    id: 'automation',
    header: () => <span>Name</span>,
    cell: info => info.row.original.automation.name,
  },
  {
    id: 'crm',
    accessorKey: 'crm',
    header: () => <span>CRM</span>,
    cell: ({ getValue }) => getValue(),
  },
  {
    id: 'createdAt',
    accessorKey: 'createdAt',
    header: () => <span>Date</span>,
    cell: info => moment(info.getValue()).format(DATE_FORMAT),
  },
  {
    id: 'status',
    header: () => <span className="block w-full text-right">Status</span>,
  },
]
