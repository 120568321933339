import React from 'react'
import PageHeading from '@/sections/PageHeading'
import { useAppSelector } from '@/store/hooks'
import { DataTable } from '@/components'
import { lockedLocationColumns } from '@/pages/LockedLocations/config'
import { useGetLockedLocationsQuery } from '@/api/services/location'
import { ILockedLocation } from '@/types'
import { ReactComponent as BackIcon } from '@/assets/icons/Arrow Left.svg'
import { useNavigate } from 'react-router-dom'

const filteredLocations = (
  locations: ILockedLocation[],
  locationId: string,
  search: string,
) => {
  let filtered = locations
  if (locationId) {
    filtered = locations.filter(location => location._id === locationId)
  }
  if (search) {
    filtered = locations.filter(location =>
      location.location.name.toLowerCase().includes(search.toLowerCase()),
    )
  }
  return filtered
}

const LockedLocations = () => {
  const navigate = useNavigate()
  const {
    loading,
    filter: { locationId, search },
  } = useAppSelector(state => state.lockedLocations)
  const { data: { locations = [] } = {} } =
    useGetLockedLocationsQuery(undefined)
  const filtered = filteredLocations(locations, locationId, search)

  return (
    <div className="w-full flex flex-col gap-6">
      <PageHeading
        title={
          <div className="flex gap-3 items-center">
            <BackIcon
              onClick={() => navigate(-1)}
              className="w-5 h-5 text-black-80 hover:text-black-100 transition-all duration-300 cursor-pointer"
            />
            Locked locations
          </div>
        }
      />
      <DataTable
        loading={loading}
        columns={lockedLocationColumns}
        data={filtered}
      />
    </div>
  )
}

export default LockedLocations
