import React from 'react'
import PageHeading from '@/sections/PageHeading'
import SubmenuSection from '@/sections/SubmenuSection'
import { Outlet } from 'react-router-dom'

const SuccessPortal = () => {
  return (
    <div className="w-full flex flex-col gap-6">
      <PageHeading title="Success Portal" />
      <SubmenuSection />
      <Outlet />
    </div>
  )
}

export default SuccessPortal
