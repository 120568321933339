import React from 'react'
import moment from 'moment-timezone'
import { DATE_FORMAT } from '@/constants'
import { ColumnDef } from '@tanstack/react-table'
import { IRepairOrder } from '@/types'
import RawPayloadModal from '@/pages/Organization/RepairOrders/RawPayloadModal'
import WorkflowsModal from '@/pages/Organization/RepairOrders/WorkflowsModal'

const tz = moment.tz.guess()
export const serviceAppointmentsTableColumns: ColumnDef<IRepairOrder, any>[] = [
  {
    accessorKey: 'externalId',
    header: () => <span>Appointment ID</span>,
  },
  {
    accessorKey: 'crm',
    header: () => <span>CRM</span>,
  },
  {
    accessorKey: 'excludedWorkflows',
    header: () => <span>Workflows Used</span>,
    cell: info => {
      const count = info.row.original.excludedWorkflows.length
      return count > 0 ? (
        <WorkflowsModal workflows={info.row.original.excludedWorkflows} />
      ) : (
        count
      )
    },
  },
  {
    accessorKey: 'appointmentDate',
    header: () => <span>Appointment time</span>,
    cell: info => moment(info.getValue()).tz(tz).format(DATE_FORMAT),
  },
  {
    accessorKey: 'createdAt',
    header: () => <span>Created At</span>,
    cell: info => moment(info.getValue()).tz(tz).format(DATE_FORMAT),
  },
  {
    accessorKey: 'rawPayloads',
    header: () => <span>Last Raw Payload</span>,
    cell: info => <RawPayloadModal data={info.row.original.rawPayloads[0]} />,
  },
]
