import React from 'react'
import { Link } from 'react-router-dom'
import { generatePath, truncateString } from '@/utils'
import { AUTOMATIONS, ORGANIZATION } from '@/constants'

interface OrganizationLinkProps {
  id: string
  name: string
  maxChars?: number
}

const OrganizationLink = ({
  id,
  name,
  maxChars = 30,
}: OrganizationLinkProps) => {
  const path = generatePath(ORGANIZATION + AUTOMATIONS, { id })
  return (
    <Link className="text-blue-100" to={path}>
      {truncateString(name, maxChars)}
    </Link>
  )
}

export default OrganizationLink
