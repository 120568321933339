import { IBaseListItem, IGlobalState, Maybe } from '@/types/global'
import { TAutomationType } from '@/types/automation'
import { ICRMLocation } from '@/types/crm'
import { ICredential } from '@/types/auth'
import { ISuperhumanConfig } from '@/types/superhuman'

export interface IOrganization extends IBaseListItem {}

export interface IOrganizationState extends IGlobalState {
  organizations: IOrganization[]
}

export enum TTag {
  INPUT = 'input',
  SELECT = 'select',
  TEXTAREA = 'textarea',
  SELECT_V2 = 'select_v2',
  MULTIPLE_SELECT = 'multiselect',
  ELEAD_OPTIONS = 'elead-options',
  ACTIVIX_FILTER = 'activixFilter',
  WORKFLOW_SELECT = 'workflow-select',
  PBS_MULTISELECT = 'pbs_multiselect',
  DEALERSOCKET_UUID = 'dealersocket-uuid',
  INPUT_NOT_REQUIRED = 'inputNotRequired',
  START_END_SOLD_ELEAD = 'startEndSoldElead',
}
export interface ICustomField {
  tag?: TTag
  type: string
  name: string
  viewName: string
  options?: any[]
}

export interface IRule {
  _id: string
  name: string
  viewName: string
  crm: string
  steps: {
    type: string
    value: string | number
    action: {
      type: string
      value?: string
    } | null
    tolerance?: boolean
  }[]
  flags: {
    useFutureOffset: any
  }
  htmltable: boolean
  customFields: ICustomField[]
  type: TAutomationType
  dynamicFields: string[]
  createdAt: string
  updatedAt: string
  __v: number
}

export interface IMfaConfig {
  enabled: boolean
}

export interface IIpWhitelist {
  ips: string[]
  enabled: boolean
}

export interface IRestrictions {
  restrictTextingCustomersInPendingStatus: boolean
  restrictSupportAgentsAccessToUnassignedFolder: boolean
  restrictSupportAgentsAccessToArchivedFolder: boolean
  restrictSupportAgentsAccessToMainInboxFolder: boolean
  restrictSendingBroadcastsOverTheWeekend: boolean
  calendarInvitationsToUsers: boolean
  restrictSupportAgentsAccessToAllLocations: boolean
  restrictManagersAccessToAllLocations: boolean
  restrictDisplayingPushToCRMButtonToSupportAgents: boolean
  restrictAppointmentsOutsideWorkingHours: boolean
  restrictDisableAIBotManagers: boolean
  restrictDisableAIBotSupportAgents: boolean
  allowParseLeadsWithoutPhone: boolean
  restrictUserVoiceCallsFromUsingOrganizationNumbers: boolean
  restrictEditSuperHumanForManagers: boolean
  restrictEditSuperHumanForSupportAgents: boolean
}

export interface IWorkingHours {
  Sunday: IWorkingHoursDay
  Monday: IWorkingHoursDay
  Tuesday: IWorkingHoursDay
  Wednesday: IWorkingHoursDay
  Thursday: IWorkingHoursDay
  Friday: IWorkingHoursDay
  Saturday: IWorkingHoursDay
}

interface IWorkingHoursDay {
  isOpen: boolean
  from: string
  to: string
}

export interface ITwilioMessaging {
  twilioNumbers: string[]
  messagingServiceSid: string
}

export interface ISingOrganizationType {
  _id: string
  __v: number
  name: string
  tags: string[]
  rules: IRule[]
  enabled: boolean
  timezone: string
  updatedAt: string
  createdAt: string
  authToken: string
  languages: string[]
  twilio_number: string
  mfaConfig: IMfaConfig
  enable_payments: boolean
  stripeConnected: boolean
  defaultLanguage: string
  authTokenAccount: string
  appointmentEmail: string
  enable_broadcast: boolean
  enable_auto_reply: boolean
  locations: ICRMLocation[]
  ipWhitelist: IIpWhitelist
  credentials: ICredential[]
  restrictions: IRestrictions
  workingHours: IWorkingHours
  callForwardingNumber: string
  lastMessageHistoryId: string
  stripeAccountId: Maybe<string>
  disableTimestamp: Maybe<boolean>
  stripeExternalAccounts: string[]
  twilioMessaging: ITwilioMessaging
  stripeConnectedDate: Maybe<string>
  superhumanConfig: ISuperhumanConfig
  enable_email_notifications: boolean
  stripeAccountDefaultCurrency: Maybe<string>
}

export interface IOrganizationSettingsResponse {
  _id: string
  organizationId: string
  assignedUsers: string[]
  createdAt: string
  updatedAt: string
}
