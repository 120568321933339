import { ColumnDef } from '@tanstack/react-table'
import React from 'react'
import { Tag } from '@/components'
import { ReactComponent as InfoIcon } from '@/assets/icons/Info.svg'
import Tooltip from '@/components/Tooltip'
import { IErrorTrackerCount, TErrorCodes } from '@/types'
import { errorCodes } from '@/constants'

export const errorTableColumns: ColumnDef<IErrorTrackerCount, any>[] = [
  {
    accessorKey: 'error_code',
    header: () => <span>Error Code</span>,
    cell: ({ getValue }) => {
      const errorCode = getValue()
      return (
        <div className="flex items-center gap-1">
          <Tag status="error" variant="light">
            #{errorCode}
          </Tag>
          <Tooltip content={errorCodes[errorCode as TErrorCodes]}>
            <InfoIcon className="text-black-50" />
          </Tooltip>
        </div>
      )
    },
  },
  {
    accessorKey: 'count',
    header: () => <span>Error Count</span>,
  },
]
