import { TErrorCodes } from '@/types'

export const errorCodes: Record<TErrorCodes, string> = {
  '1001': '1001 - Invalid credentials',
  '1005': '1005 - RPA Driver error',
  '1030': '1030 - Empty VinSolutions report',
}

export const errorCodeColors: Record<TErrorCodes, string> = {
  '1001': '#3B48E6',
  '1005': '#E59700',
  '1030': '#D92D20',
}
