import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getNotifications } from '@/api/services/notification'
import { IGlobalState, INotification } from '@/types'

interface INotificationState extends IGlobalState {
  notifications: INotification[]
  isOpen: boolean
}

const initialState: INotificationState = {
  loading: false,
  notifications: [],
  error: undefined,
  fetched: false,
  isOpen: false,
}

export const fetchNotifications = createAsyncThunk<
  INotification[],
  undefined,
  { rejectValue: { message: string } }
>('notification/list', async (_, thunkApi) => {
  try {
    const res = await getNotifications()
    return res.data
  } catch (error) {
    return thunkApi.rejectWithValue({
      message: 'Failed to fetch notifications',
    })
  }
})

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    clear: state => {
      state.notifications = []
    },
    toggleNotifications: (state, action) => {
      state.isOpen = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchNotifications.pending, (state: INotificationState) => {
      state.loading = true
    })
    builder.addCase(
      fetchNotifications.fulfilled,
      (state: INotificationState, action) => {
        state.notifications = action.payload
        state.loading = false
        state.fetched = true
      },
    )
    builder.addCase(
      fetchNotifications.rejected,
      (state: INotificationState, action) => {
        state.error = action.payload?.message
        state.loading = false
      },
    )
  },
})

export const { clear, toggleNotifications } = notificationSlice.actions
export default notificationSlice.reducer
