import React, { useCallback, useEffect } from 'react'
import { Input, Select } from '@/components'

import { ReactComponent as SearchIcon } from '@/assets/icons/Search.svg'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { onFilterChange } from '@/store/slices/pages/successPortal/organization-summary.slice'
import { dateFilterOptions } from '@/constants'
import { IDateFilterType } from '@/types'
import { getDatesByFilterType } from '@/utils'

const OrganizationSummaryFilter = () => {
  const dispatch = useAppDispatch()
  const [selectedDate, setSelectedDate] = React.useState<IDateFilterType>('all')
  const {
    filter: { search, startDate },
  } = useAppSelector(state => state.organizationSummary)

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(onFilterChange({ search: e.target.value, page: 1 }))
    },
    [dispatch],
  )

  useEffect(() => {
    if (selectedDate !== 'custom') {
      const dates = getDatesByFilterType(selectedDate)
      dispatch(
        onFilterChange({
          startDate: dates.startDate,
          page: 1,
        }),
      )
    }
  }, [dispatch, selectedDate])

  const onSelectChange = useCallback((value: IDateFilterType) => {
    setSelectedDate(value)
  }, [])

  return (
    <div className="grid grid-cols-5 gap-3">
      <Input
        IconLeft={SearchIcon}
        placeholder="Search"
        value={search}
        onChange={handleInputChange}
      />
      <Select
        options={dateFilterOptions}
        onChange={newValue => {
          if (newValue) {
            onSelectChange(newValue._id)
          }
        }}
        value={dateFilterOptions.find(option => option._id === selectedDate)}
      />
    </div>
  )
}

export default OrganizationSummaryFilter
