import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { CheckIcon, EyeIcon } from '@heroicons/react/24/solid'
import { ReactComponent as ClockIcon } from '@/assets/icons/Clock.svg'
import moment from 'moment'

import { generatePath, generateQueryParams } from '@/utils'
import { Button, Drawer, DrawerSize, Typography } from '@/components'
import { INotification } from '@/types'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { ORGANIZATION } from '@/constants'
import CardWrapper from '@/sections/CardWrapper'
import { toggleNotifications } from '@/store/slices/notification.slice'

export const Notifications = () => {
  const dispatch = useAppDispatch()
  const { notifications, isOpen } = useAppSelector(state => state.notifications)

  const onClose = useCallback(() => {
    dispatch(toggleNotifications(false))
  }, [dispatch])

  return (
    <Drawer
      size={DrawerSize.SM}
      title={
        <div className="flex gap-5 items-center justify-between">
          <span>Notifications</span>
          <Button status="secondary" size="small">
            <CheckIcon className="w-4 h-4" />
            Mark all as read
          </Button>
        </div>
      }
      open={isOpen}
      onClose={onClose}
    >
      <div className="overflow-y-auto flex flex-col gap-4">
        {notifications.map(notification => (
          <React.Fragment key={notification._id}>
            <NotificationCard notification={notification} onClose={onClose} />
          </React.Fragment>
        ))}
      </div>
    </Drawer>
  )
}

export const NotificationCard = ({
  notification,
  onClose,
}: {
  notification: INotification
  onClose: () => void
}) => {
  const navigate = useNavigate()

  const viewTask = useCallback(() => {
    navigate(
      generatePath(
        ORGANIZATION,
        { id: notification.meta.organizationId },
        true,
      ) + generateQueryParams({ taskId: notification._id }),
    )
    onClose()
  }, [navigate, notification._id, notification.meta.organizationId, onClose])

  return (
    <CardWrapper className="py-3">
      <div className="flex items-start">
        <div className="flex-1">
          <Typography variant="regular" weight="medium">
            {notification.title}
          </Typography>
          <Typography variant="small" className="text-black-80 mt-1">
            {notification.message}
          </Typography>
          <div className="mt-2 flex justify-between">
            <Typography
              variant="tiny"
              className="flex gap-2 text-black-50 items-center"
            >
              <ClockIcon />
              {moment(notification.createdAt).fromNow(true)}
            </Typography>
            <div className="flex">
              <Button size="small" status="primary" onClick={viewTask}>
                <EyeIcon className="w-4 h-4" />
                View
              </Button>
            </div>
          </div>
        </div>
      </div>
    </CardWrapper>
  )
}
