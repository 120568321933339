import React, { ReactNode } from 'react'

import styles from './styles.module.scss'
import { LoadingSkeleton, Typography } from '@/components'
import clsx from 'clsx'
import { rem } from '@/utils'
import { AutomationStatus } from '@/types'
import { statuses } from '@/constants/automations'

interface StatsCardProps {
  title: string
  value?: string | number
  icon?: ReactNode
  className?: string
  loading?: boolean
  status?: AutomationStatus
}
export const StatsCard = ({
  title,
  value,
  icon,
  className,
  loading,
  status,
}: StatsCardProps) => {
  return (
    <div
      className={clsx(
        styles.wrapper,
        className,
        status ? styles[statuses[status].color] : '',
      )}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      <div>
        <Typography variant="small" className={styles.title}>
          {title}
        </Typography>
        {loading ? (
          <LoadingSkeleton height={rem(24)} width="90%" />
        ) : (
          <Typography variant="H3" weight="semibold" className={styles.value}>
            {value}
          </Typography>
        )}
      </div>
    </div>
  )
}
