import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'

import { ReactComponent as CloseIcon } from '@/assets/icons/Close.svg'
import { Typography } from '@/components'
import clsx from 'clsx'
import { ReactNode } from 'react'

import styles from './styles.module.scss'
import { TAppTheme } from '@/types'

export enum DrawerSize {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = 'xxl',
  FULL = 'full',
}

export interface DrawerProps {
  open: boolean
  onClose: () => void
  title: string | ReactNode
  description?: string | ReactNode
  headerClass?: string
  contentClass?: string
  children?: ReactNode
  size?: DrawerSize
  theme?: TAppTheme
}

export const Drawer = ({
  open,
  onClose,
  title,
  description,
  headerClass,
  contentClass,
  children,
  size = DrawerSize.SM,
  theme,
}: DrawerProps) => {
  const isDark = theme === 'dark'
  return (
    <Dialog open={open} onClose={() => onClose()} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-black-100 bg-opacity-50 transition-opacity duration-300 ease-in-out data-[closed]:opacity-0"
      />
      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10">
            <DialogPanel
              transition
              className={clsx(
                'pointer-events-auto w-screen transform transition duration-300 ease-in-out data-[closed]:-translate-x-full',
                styles[`size-${size}`],
                {
                  'bg-[#242142] text-white-100': isDark,
                  'bg-white-100': !isDark,
                },
              )}
            >
              <div className="flex h-full flex-col gap-8 overflow-y-scroll bg-white relative p-8">
                <div className={clsx(headerClass)}>
                  <div className="flex flex-col">
                    <Typography variant="H3" weight="semibold" className="pr-8">
                      {title}
                    </Typography>

                    {description && (
                      <Typography
                        variant="regular"
                        className="text-black-100 mt-2"
                      >
                        {description}
                      </Typography>
                    )}
                  </div>
                  <div className="absolute top-8 right-8 z-20">
                    <CloseIcon
                      onClick={onClose}
                      className={clsx(
                        'relative cursor-pointer',
                        isDark ? 'text-[#A3A1B7]' : 'text-black-100',
                      )}
                    />
                  </div>
                </div>
                <div
                  className={clsx(
                    'relative flex-1 flex flex-col',
                    contentClass,
                  )}
                >
                  {children}
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
