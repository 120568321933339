import { IBaseListItem, IGlobalState, IPageMeta, Maybe } from '@/types/global'

export interface IAppointmentFilter extends IPageMeta {
  name: string
  endDate: string
  startDate: string
  sortBy: TDateFields | ''
  phoneNumber: string
  organization?: string
  status: TAppointmentStatus | ''
}
export interface IAppointmentState extends IGlobalState {
  filter: IAppointmentFilter
}

export interface IAppointment {
  crm: string
  lastName: string
  firstName: string
  automation: string
  phoneNumber: string
  failedAt: Maybe<string>
  location: IBaseListItem
  canceledAt: Maybe<string>
  confirmedAt: Maybe<string>
  rescheduledAt: Maybe<string>
  appointmentTimestamp: string
  createdAt: Date
  organization: IBaseListItem
}

export enum TAppointmentStatus {
  CONFIRMED = 'confirmed',
  RESCHEDULED = 'rescheduled',
  CANCELED = 'canceled',
  FAILED = 'failed',
}

export enum TDateFields {
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
}
