import React from 'react'
import PageHeading from '@/sections/PageHeading'
import Filter from '@/pages/Leads/Filter'
import { DataTable } from '@/components'
import { leadTableColumns } from '@/pages/Leads/config'
import Pagination from '@/components/Pagination'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { onFilterChange } from '@/store/slices/pages/lead.slice'
import { useParams } from 'react-router-dom'
import { useGetLeadsQuery } from '@/api/services/organization/lead'

const OrganizationLeads = () => {
  const { id } = useParams()
  const { filter } = useAppSelector(state => state.leads)
  const dispatch = useAppDispatch()
  const {
    data: { data = [], pageMeta: { totalItems = 0 } = {} } = {},
    isFetching,
  } = useGetLeadsQuery({ ...filter, organization: id })

  const onPageChange = (page: number) => {
    dispatch(
      onFilterChange({
        page,
      }),
    )
  }
  return (
    <div className="w-full flex flex-col gap-6">
      <Filter />
      <DataTable loading={isFetching} columns={leadTableColumns} data={data} />
      <Pagination
        currentPage={filter.page}
        totalCount={totalItems}
        onPageChange={onPageChange}
      />
    </div>
  )
}

export default OrganizationLeads
