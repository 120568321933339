import React from 'react'
import { DASHBOARD } from '@/constants'
import { useAppSelector } from '@/store/hooks'
import { availablePaths } from '@/store/slices/user.slice'
import { Button } from '@/components'

export const Unauthorized = () => {
  const availableRoutes = useAppSelector(availablePaths)
  return (
    <div className="h-screen flex justify-center items-center">
      <div className="text-center flex flex-col gap-6">
        <p className="text-8xl">403</p>
        <h1 className="text-2xl">Access to this page is restricted</h1>
        <p className="text-lg text-gray-400">
          Please check with the site admin if you believe this is a mistake.
        </p>
        <div className="links-section max-w-max mx-auto">
          <Button
            status="secondary"
            size="large"
            to={
              availableRoutes[0]?.subMenus?.[0]?.path ||
              availableRoutes[0]?.path ||
              DASHBOARD
            }
          >
            Go back home
          </Button>
        </div>
      </div>
    </div>
  )
}
