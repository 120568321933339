import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { clearFilter, onFilterChange } from '@/store/slices/pages/ai-bot.slice'
import { Button, Input, Option, Select } from '@/components'
import DatePicker from '@/components/DatePicker'

import { ReactComponent as SearchIcon } from '@/assets/icons/Search.svg'
import { useParams } from 'react-router-dom'
import { useGetOrganizationsQuery } from '@/api/services/organization-list'

const AiBotFilter = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const { data: organizations = [] } = useGetOrganizationsQuery(undefined)

  const {
    filter: { organization, startDate, endDate, phoneNumber },
  } = useAppSelector(state => state.aiBot)

  const onSelectChange = useCallback(
    (field: string, value: Option | null) => {
      dispatch(
        onFilterChange({
          [field]: (!Array.isArray(value) && value?._id) || '',
          page: 1,
        }),
      )
    },
    [dispatch],
  )

  const reset = useCallback(() => {
    dispatch(clearFilter())
  }, [dispatch])

  const onDateChange = useCallback(
    (type: 'startDate' | 'endDate') => (date: Date | undefined) => {
      dispatch(
        onFilterChange({
          [type]: date,
          page: 1,
        }),
      )
    },
    [dispatch],
  )

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(onFilterChange({ phoneNumber: e.target.value, page: 1 }))
    },
    [dispatch],
  )

  return (
    <div className="flex items-end">
      <div className="grid grid-cols-6 gap-3 w-full">
        {!id && (
          <Select
            isSearchable
            label="Organization"
            options={organizations}
            value={organizations.find(org => org._id === organization) || null}
            onChange={val => onSelectChange('organization', val)}
          />
        )}
        <DatePicker
          label="Start Date"
          value={new Date(startDate)}
          onChange={onDateChange('startDate')}
          placeholder="Select a date"
        />
        <DatePicker
          label="End Date"
          value={new Date(endDate)}
          onChange={onDateChange('endDate')}
          placeholder="Select a date"
        />
        <div className="flex items-end">
          <Input
            label="Phone Number"
            IconLeft={SearchIcon}
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <Button className="ml-auto" status="text" size="small" onClick={reset}>
        Clear All
      </Button>
    </div>
  )
}

export default AiBotFilter
