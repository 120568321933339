import React, { useEffect } from 'react'
import AutomationFilter from '@/pages/Automations/Filter'
import { DataTable } from '@/components'
import { automationTableColumns } from '@/pages/Automations/config'
import Pagination from '@/components/Pagination'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import {
  clearFilter,
  onFilterChange,
  setDetails,
} from '@/store/slices/pages/automation.slice'
import { useParams } from 'react-router-dom'
import { useGetTasksQuery } from '@/api/services/task'
import { ColumnDef } from '@tanstack/react-table'
import { ITask } from '@/types'
import AutomationTableActions from '@/pages/Automations/TableActions'
import AutomationDetails from '@/pages/Automations/Details'

const OrganizationTasks = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const { filter } = useAppSelector(state => state.automation)
  const {
    data: { data = [], pageMeta: { totalItems = 0 } = {} } = {},
    isFetching,
    refetch,
  } = useGetTasksQuery({
    ...filter,
    organizationIds: id ? [id] : [],
  })
  const onPageChange = (page: number) => {
    dispatch(
      onFilterChange({
        page,
      }),
    )
  }
  useEffect(() => {
    return () => {
      dispatch(clearFilter())
    }
  }, [])

  const onStatusClick = (row: ITask) => {
    if (row.status === 'pending') {
      return
    }
    dispatch(setDetails(row))
  }
  return (
    <div className="w-full flex flex-col gap-4">
      <AutomationFilter onRefresh={refetch} />
      <DataTable
        loading={isFetching}
        columns={
          automationTableColumns.map((col: ColumnDef<ITask, any>) => {
            if (col.id === 'status') {
              return {
                ...col,
                cell: info => (
                  <AutomationTableActions
                    task={info.row.original as ITask}
                    afterAction={() => refetch()}
                    onClick={() => onStatusClick(info.row.original)}
                  />
                ),
              }
            }
            return col
          }) as ColumnDef<ITask, any>[]
        }
        data={data}
      />
      <Pagination
        currentPage={filter.page}
        totalCount={totalItems}
        onPageChange={onPageChange}
      />
      <AutomationDetails />
    </div>
  )
}

export default OrganizationTasks
