import React from 'react'
import Toggle from '@/components/Toggle'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { onAvailabilityChange } from '@/store/slices/superhuman.slice'
import { Typography } from '@/components'
import Card from '@/components/Card'

const SuperhumanHeading = () => {
  const dispatch = useAppDispatch()
  const { available, job, delayJobCount, totalJobCount } = useAppSelector(
    state => state.superhuman,
  )

  const onToggleChange = (val: boolean) => {
    dispatch(onAvailabilityChange(val))
  }
  return (
    <div className="grid grid-cols-7 gap-8">
      <Card className="col-span-5">
        <div className="flex flex-col gap-3">
          <div className="flex gap-2 items-center">
            <Toggle checked={available} onChange={onToggleChange} />
            <Typography variant="small" weight="medium">
              {available ? 'Available' : 'Unavailable'}
            </Typography>
          </div>
          <Typography variant="small" weight="medium">
            {!job && !available
              ? 'Set yourself as available to start your shift'
              : ''}
            {job && !available
              ? 'After completing the current job, you will not receive new jobs'
              : ''}
            {!job && available ? 'Waiting for jobs...' : ''}
          </Typography>
        </div>
      </Card>

      <Card className="col-span-2">
        <div className="flex flex-col gap-2 ">
          <Typography variant="regular" weight="medium">
            Total Jobs: {totalJobCount}
          </Typography>
          <Typography variant="regular" weight="medium">
            Late Jobs: {delayJobCount}
          </Typography>
        </div>
      </Card>
    </div>
  )
}

export default SuperhumanHeading
