import React from 'react'
import FormActionState from '@/sections/FormActionState'
import { useAppSelector } from '@/store/hooks'
import { Button, Typography } from '@/components'
import { V2_DASHBOARD } from '@/constants'
import { generatePath } from '@/utils'
import { useParams } from 'react-router-dom'

const SaveState = () => {
  const { id } = useParams()
  const { status } = useAppSelector(state => state.workflow)
  return (
    <div className="text-center flex flex-col justify-center items-center pb-4">
      <FormActionState status={status} />
      {status === 'completed' && (
        <>
          <Typography variant="regular" weight="semibold" className="mb-6">
            Workflow saved successfully! We'll notify you when the first action
            is performed.
          </Typography>
          <Button
            size="small"
            status="text"
            as="Link"
            to={generatePath(V2_DASHBOARD, { id })}
          >
            Go to dashboard
          </Button>
        </>
      )}
    </div>
  )
}

export default SaveState
