import React, { ReactNode } from 'react'

import styles from './styles.module.scss'
import clsx from 'clsx'
import { Typography } from '@/components'
import { TagStatus } from '@/types'

interface TagProps {
  status: TagStatus
  children?: ReactNode
  IconLeft?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  IconRight?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  variant?: 'light' | 'dark'
  className?: string
  onIconClick?: () => void
  onClick?: () => void
}

export const Tag = ({
  status = 'default',
  children,
  IconLeft,
  IconRight,
  variant = 'dark',
  className,
  onIconClick,
  onClick,
  ...rest
}: TagProps) => {
  const onIconClickHandler = (e: React.MouseEvent<SVGSVGElement>) => {
    if (onIconClick) {
      e.stopPropagation()
      onIconClick()
    }
  }
  return (
    <span
      className={clsx(styles.tag, styles[status], styles[variant], className, {
        [styles.round]: !children && (!IconLeft || !IconRight),
        'cursor-pointer': onClick,
      })}
      onClick={onClick}
      {...rest}
    >
      {IconLeft && (
        <span className={styles.icon}>
          <IconLeft onClick={onIconClickHandler} />
        </span>
      )}
      {children && (
        <Typography variant="small" weight="semibold">
          {children}
        </Typography>
      )}
      {IconRight && (
        <span className={styles.icon}>
          <IconRight onClick={onIconClickHandler} />
        </span>
      )}
    </span>
  )
}
