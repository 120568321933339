import React, { useCallback } from 'react'
import { Select, Option } from '@/components'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import {
  clearFilter,
  onFilterChange,
} from '@/store/slices/pages/automation.slice'
import { Button } from '@/components'
import { automationStatusOptions, automationTypeOptions } from '@/constants'
import DatePicker from '@/components/DatePicker'
import { useParams } from 'react-router-dom'
import { useGetLocationsQuery } from '@/api/services/location'
import { useGetCrmsQuery } from '@/api/services/crm'
import { useGetOrganizationsQuery } from '@/api/services/organization-list'
import clsx from 'clsx'
import { MultiValue } from 'react-select'
import { ReactComponent as RefreshIcon } from '@/assets/icons/Refresh.svg'

const AutomationFilter = ({ onRefresh }: { onRefresh: () => void }) => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const { data: organizations = [] } = useGetOrganizationsQuery(undefined)
  const { data: { data: locations = [] } = {} } =
    useGetLocationsQuery(undefined)
  const { data: { data: crms = [] } = {} } = useGetCrmsQuery(undefined)

  const {
    filter: {
      organizationIds,
      locationId,
      crm,
      status,
      automationType,
      startDate,
      endDate,
    },
  } = useAppSelector(state => state.automation)
  const onOrganizationChange = useCallback(
    (value: MultiValue<Option>) => {
      dispatch(
        onFilterChange({
          organizationIds:
            (Array.isArray(value) && value?.map(org => org._id)) || [],
          page: 1,
        }),
      )
    },
    [dispatch],
  )

  const onSelectChange = useCallback(
    (field: string, value: Option | null) => {
      dispatch(
        onFilterChange({
          [field]: (!Array.isArray(value) && value?._id) || '',
          page: 1,
        }),
      )
    },
    [dispatch],
  )

  const reset = useCallback(() => {
    dispatch(clearFilter())
  }, [dispatch])

  const onDateChange = useCallback(
    (type: 'startDate' | 'endDate') => (date: Date | undefined) => {
      dispatch(
        onFilterChange({
          [type]: date,
          page: 1,
        }),
      )
    },
    [dispatch],
  )

  return (
    <div className="flex items-end ">
      <div
        className={clsx(
          'grid gap-3 w-full',
          !id ? 'grid-cols-7' : 'grid-cols-4',
        )}
      >
        {!id && (
          <>
            <Select
              isMulti
              isSearchable
              label="Organization"
              options={organizations}
              value={organizations.filter(org =>
                organizationIds.includes(org._id),
              )}
              onChange={onOrganizationChange}
            />
            <Select
              disabled={!organizationIds.length}
              label="Location"
              options={locations}
              value={locations.find(loc => loc._id === locationId) || null}
              onChange={val => onSelectChange('locationId', val)}
            />
            <Select
              label="CRMs"
              options={crms}
              value={crms.find(_crm => _crm._id === crm) || null}
              onChange={val => onSelectChange('crm', val)}
            />
          </>
        )}

        <DatePicker
          label="Start Date"
          value={new Date(startDate)}
          onChange={onDateChange('startDate')}
          placeholder="Select a date range"
        />
        <DatePicker
          label="End Date"
          value={new Date(endDate)}
          onChange={onDateChange('endDate')}
          placeholder="Select a date range"
        />
        <Select
          label="Status"
          options={automationStatusOptions}
          value={
            automationStatusOptions.find(_status => _status._id === status) ||
            null
          }
          onChange={val => onSelectChange('status', val)}
        />
        <Select
          label="Task Type"
          options={automationTypeOptions}
          value={automationTypeOptions.find(
            _type => _type._id === automationType,
          )}
          onChange={val => onSelectChange('automationType', val)}
        />
      </div>
      <Button size="small" className="ml-auto" status="text" onClick={reset}>
        Clear All
      </Button>
      <RefreshIcon
        className="text-black-80 hover:text-black-100 cursor-pointer mb-2.5 transition-all duration-300"
        onClick={onRefresh}
      />
    </div>
  )
}

export default AutomationFilter
