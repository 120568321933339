import React, { useCallback, useEffect, useRef } from 'react'
import { parseJsonLogic } from 'react-querybuilder/parseJsonLogic'
import {
  formatQuery,
  QueryBuilder as QueryBuilderBase,
} from 'react-querybuilder'
import 'react-querybuilder/dist/query-builder.scss'

import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { onFormChange } from '@/store/slices/workflow.slice'
import { defaultCombinator, initialQuery } from '@/constants/automations'
import Card from '@/components/Card'
import { Button, Input, Select, Typography } from '@/components'

import { ReactComponent as PlusIcon } from '@/assets/icons/Plus.svg'
import { ReactComponent as CloseIcon } from '@/assets/icons/Close.svg'

interface CustomCombinatorProps {
  options: { label: string; name: string; value: string }[]
  value?: string
  onChange: (value?: string) => void
}

const RemoveButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button
      className="outline-none border-none p-2 bg-red-100 text-white-100 rounded-md"
      onClick={onClick}
    >
      <CloseIcon />
    </button>
  )
}
const CustomSelect = ({ options, value, onChange }: CustomCombinatorProps) => {
  const customOptions =
    options?.map(option => ({
      name: option.label,
      _id: option.name,
    })) || []
  const customValue = customOptions.find(item => item._id === value)
  return (
    <Select
      options={customOptions}
      value={customValue}
      onChange={newValue => {
        onChange(newValue?._id)
      }}
    />
  )
}

const CustomValueEditor = ({
  value,
  options,
  onChange,
  type,
}: CustomCombinatorProps & { type: string }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current?.focus()
    }
  }, [value])
  if (type === 'select') {
    return <CustomSelect value={value} options={options} onChange={onChange} />
  }
  return (
    <Input
      ref={inputRef}
      value={value}
      onChange={e => {
        onChange(e.target.value)
      }}
    />
  )
}

const QueryBuilder = () => {
  const dispatch = useAppDispatch()
  const { workFlowId } = useParams()
  const isNewWorkflow = workFlowId === 'new'
  const { form } = useAppSelector(state => state.workflow)
  const [query, setQuery] = React.useState(
    parseJsonLogic(
      isNewWorkflow
        ? (form?.template?.combinator ?? defaultCombinator)
        : (form.combinator ?? defaultCombinator),
    ) || initialQuery,
  )

  const onQueryChange = useCallback(
    (q: any) => {
      setQuery(q)
      dispatch(onFormChange({ combinator: formatQuery(q, 'jsonlogic') }))
    },
    [dispatch],
  )

  const fields = form?.template?.actions.map(action => ({
    ...action,
    ValueSelector: action.valueEditorType === 'select' ? Select : Input,
    values: action.values?.map(value => ({
      _id: value.name,
      name: value.label,
    })),
    operators: action.operators?.map(operator => ({
      _id: operator.name,
      name: operator.label,
    })),
  }))

  console.log(form?.template?.actions, fields)

  return (
    <div className="combinator">
      <Typography
        variant="regular"
        weight="medium"
        className="text-black-80 mb-4"
      >
        Add filters and criteria on which you want to base communications for
        this workflow.
      </Typography>
      <Card>
        <QueryBuilderBase
          // @ts-ignore
          query={query}
          onQueryChange={onQueryChange}
          // @ts-ignore
          fields={form?.template?.actions}
          controlClassnames={{
            queryBuilder: 'rounded-lg border-none',
            ruleGroup: 'rounded-lg bg-white-100 border-none rule-group',
          }}
          controlElements={{
            valueEditor: props => (
              <CustomValueEditor
                // @ts-ignore
                type={props.fieldData.valueEditorType}
                value={props.value}
                // @ts-ignore
                options={props.fieldData.values}
                onChange={props.handleOnChange}
              />
            ),
            combinatorSelector: props => (
              <CustomSelect
                value={props.value}
                // @ts-ignore
                options={props.options}
                onChange={props.handleOnChange}
              />
            ),
            operatorSelector: props => (
              <CustomSelect
                value={props.value}
                // @ts-ignore
                options={props.fieldData.operators}
                onChange={props.handleOnChange}
              />
            ),
            valueSelector: props => {
              return (
                <CustomSelect
                  value={props.value}
                  // @ts-ignore
                  options={props.options}
                  onChange={props.handleOnChange}
                />
              )
            },
            addRuleAction: props => {
              return (
                <Button
                  status="primary"
                  size="small"
                  IconLeft={PlusIcon}
                  onClick={props.handleOnClick}
                >
                  Rule
                </Button>
              )
            },
            addGroupAction: props => {
              return (
                <Button
                  status="dark"
                  size="small"
                  IconLeft={PlusIcon}
                  onClick={props.handleOnClick}
                >
                  Group
                </Button>
              )
            },
            removeGroupAction: props => {
              return <RemoveButton onClick={props.handleOnClick} />
            },
            removeRuleAction: props => {
              return <RemoveButton onClick={props.handleOnClick} />
            },
          }}
        />
      </Card>
    </div>
  )
}

export default QueryBuilder
