import React from 'react'
import { Select } from '@/components'
import { useGetWorkflowsQuery } from '@/api/services/workflow'

interface IWorkflowSelectProps {
  value: string
  label?: string
  locationId?: string
  onChange: (_e: any) => void
}
const WorkflowSelect = ({
  value,
  onChange,
  locationId,
  label,
}: IWorkflowSelectProps) => {
  const { data: { data = [] } = {} } = useGetWorkflowsQuery(
    { id: locationId || '' },
    { skip: !locationId },
  )
  const options = data?.map(({ name, _id }) => ({ name, _id })) || []
  const selectedValue = options.find(option => option._id === value)
  return (
    <div>
      <Select
        placeholder="Select Workflow"
        label={label}
        options={options}
        value={selectedValue}
        onChange={val => onChange(val?._id)}
      />
    </div>
  )
}

export default WorkflowSelect
