import { AuthType, init, EmbedEvent } from '@thoughtspot/visual-embed-sdk'
import { SearchEmbed } from '@thoughtspot/visual-embed-sdk/react'
import { useCallback, useEffect } from 'react'

const MetricsIntelligence = () => {
  useEffect(() => {
    init({
      thoughtSpotHost: 'nebula-redeyeinnovate.thoughtspotdev.cloud',
      authType: AuthType.None,
    })
  }, [])
  const onInit = useCallback(() => {
    console.log(EmbedEvent.Init, {})
  }, [])

  const onLoad = useCallback(() => {
    console.log(EmbedEvent.Load, {})
  }, [])
  return (
    <div className="w-full h-full">
      <SearchEmbed
        frameParams={{
          height: '70vh',
          width: '100%',
        }}
        dataSource={'1ed078a8-9804-44e3-85d2-8b7949e69ae2'}
        onLoad={onLoad}
        onInit={onInit}
      />
    </div>
  )
}

export default MetricsIntelligence
