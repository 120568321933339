import React, { useCallback } from 'react'
import { modalFormFields } from '@/pages/SuccessPortal/A2P/constants'
import { Button, Drawer, Select, Input } from '@/components'
import { internalApiRequest } from '@/api'
import { IA2pWorkflow, IObjectType } from '@/types'

interface IPendingFormEditProps {
  id: string
}
const PendingFormEdit = ({ id }: IPendingFormEditProps) => {
  const [errors, setErrors] = React.useState<Record<string, string>>({})
  const [form, setForm] = React.useState<IObjectType | null>(null)
  const [selectedWorkflow, setSelectedWorkflow] = React.useState<
    (IA2pWorkflow & { viewName: string }) | null
  >(null)
  const [fetchingForm, setFetchingForm] = React.useState(false)

  const onUpdateClick = useCallback((id: string) => {
    setFetchingForm(true)
    internalApiRequest
      .get(`/workflows/${id}`)
      .then(res => {
        setSelectedWorkflow(res.data)
        setForm(res.data.form)
      })
      .catch(err => {
        throw JSON.stringify(err)
      })
      .finally(() => {
        setFetchingForm(false)
      })
  }, [])

  const onSave = () => {
    const keys = form ? Object.keys(form) : []
    const errorList: any = {}
    keys.forEach(key => {
      if (form?.[key] === '') {
        errorList[key] = 'Field is required'
      }
    })
    setErrors(errorList)
    const errorKeys = Object.keys(errorList)
    if (errorKeys.length) {
      document.querySelector(`[name="${errorKeys[0]}"]`)?.scrollIntoView()
      return
    }
    internalApiRequest
      .put(`/workflows/${selectedWorkflow?._id}`, {
        form,
      })
      .then(() => {
        console.log('updated')
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        setForm(null)
      })
  }
  return (
    <div>
      <Button
        status="secondary"
        size="small"
        onClick={() => onUpdateClick(id)}
        loading={fetchingForm}
      >
        Update
      </Button>
      <Drawer
        title={selectedWorkflow?.viewName}
        open={!!form}
        onClose={() => setForm(null)}
      >
        <div className="flex-col flex gap-4">
          {form &&
            modalFormFields.map(field => {
              return field.allowedValues ? (
                <div className={'form-group'} id="form-body" key={field.slug}>
                  <Select
                    error={errors[field.slug]}
                    placeholder="Select"
                    className="w-full block mt-2"
                    label={field.slug}
                    value={
                      field.allowedValues
                        ?.filter(el => el === form[field.slug])
                        .map(_v => ({
                          _id: _v,
                          name: _v,
                        }))?.[0]
                    }
                    onChange={option =>
                      setForm({
                        ...form,
                        [field.slug]: option?._id,
                      })
                    }
                    options={
                      field.allowedValues?.map(_v => ({
                        _id: _v,
                        name: _v,
                      })) || []
                    }
                  />
                </div>
              ) : (
                <div className="form-group" key={field.slug}>
                  <Input
                    error={errors[field.slug]}
                    type="text"
                    label={field.slug}
                    className="block w-full mt-2"
                    onChange={e =>
                      setForm({
                        ...form,
                        [field.slug]: e.target.value,
                      })
                    }
                    value={(form[field.slug] as string) || ''}
                  />
                </div>
              )
            })}
        </div>
        <Button status="primary" size="small" onClick={onSave} className="mt-6">
          Save
        </Button>
      </Drawer>
    </div>
  )
}

export default PendingFormEdit
