import { createSlice } from '@reduxjs/toolkit'
import { defaultPageMeta } from '@/constants'
import moment from 'moment-timezone'

import { IAppointmentState } from '@/types'

const initialState: IAppointmentState = {
  loading: false,
  error: undefined,
  filter: {
    ...defaultPageMeta,
    startDate: moment().subtract(7, 'd').toString(),
    endDate: moment().endOf('day').toString(),
    organization: undefined,
    status: '',
    phoneNumber: '',
    name: '',
    sortBy: '',
  },
}

const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    clearFilter: state => {
      state.filter = initialState.filter
    },
    onFilterChange: (state, action) => {
      state.filter = { ...state.filter, ...action.payload }
    },
  },
})

export const { clearFilter, onFilterChange } = appointmentSlice.actions
export default appointmentSlice.reducer
