import React from 'react'
import { TPingTestType } from '@/types'

const automationImg: Record<TPingTestType, string> = {
  failed: 'failed.svg',
  completed: 'success.gif',
  pending: 'loading.gif',
}

const statusImage = (automation: TPingTestType) => {
  return `https://automations.matador.ai/${automationImg[automation]}`
}

const FormActionState = ({ status }: { status?: TPingTestType }) => {
  if (!status) {
    return null
  }
  return (
    <div className="w-full flex justify-center">
      <img alt="Status" width="" src={statusImage(status)} />
    </div>
  )
}

export default FormActionState
