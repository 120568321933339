import React, { useEffect, useState } from 'react'
import { Button, Drawer, DrawerSize, Typography } from '@/components'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { onShowFilesChange } from '@/store/slices/pages/inventory.slice'
import {
  useGetOrganizationFilesQuery,
  useLazyGetOrganizationFileUrlQuery,
} from '@/api/services/inventory'
import ListLoadingSkeleton from '@/sections/ListLoadingSkeleton'
import { IInventoryFile } from '@/types/inventory'
import moment from 'moment-timezone'
import { DATE_FORMAT_FULL } from '@/constants'
import FileViewer from '@/pages/Inventory/FileViewer'
import clsx from 'clsx'

const FileList = () => {
  const dispatch = useAppDispatch()
  const [fileName, setFileName] = useState<string | null>(null)
  const [fileUrl, setFileUrl] = useState<string | null>(null)
  const { showFiles } = useAppSelector(state => state.inventory)
  const [getFileUrl] = useLazyGetOrganizationFileUrlQuery()
  const { data: { data: files = [] } = {}, isFetching } =
    useGetOrganizationFilesQuery(
      {
        organizationId: showFiles?.organization?._id,
        dealer: showFiles?.dealer_id,
      },
      {
        skip: !showFiles,
      },
    )

  useEffect(() => {
    if (fileName && showFiles) {
      getFileUrl({
        fileName,
        vendor: showFiles?.vendor,
      })
        .unwrap()
        .then(res => {
          setFileUrl(res.data.url)
        })
    }
  }, [fileName, getFileUrl, showFiles?.vendor])
  return (
    <Drawer
      open={!!showFiles}
      onClose={() => {
        dispatch(onShowFilesChange(undefined))
        setFileName(null)
        setFileUrl(null)
      }}
      title="List of FTP files"
      size={DrawerSize.XL}
    >
      <div className="flex gap-6">
        <div className="max-w-[420px] w-full shrink-0">
          {isFetching ? (
            <ListLoadingSkeleton rows={6} />
          ) : (
            <div className="flex flex-col gap-3 w-full">
              {files.map((file: IInventoryFile) => (
                <div
                  key={file.name}
                  className={clsx(
                    'p-4 flex justify-between gap-3 w-full border border-bg-stroke rounded-2xl hover:bg-blue-10 transition-all duration-300 items-center',
                    fileName === file.name ? 'bg-blue-20' : '',
                  )}
                >
                  <div className="flex flex-col gap-1">
                    <Typography
                      variant="regular"
                      weight="medium"
                      className="text-black-100"
                    >
                      {file.name}
                    </Typography>
                    <Typography
                      className="text-black-50"
                      variant="small"
                      weight="normal"
                    >
                      Modified at:{' '}
                      {moment(file.modifiedAt).format(DATE_FORMAT_FULL)}
                    </Typography>
                  </div>
                  <Button
                    status="secondary"
                    size="small"
                    onClick={() => {
                      setFileName(file.name)
                    }}
                  >
                    View
                  </Button>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="w-full overflow-auto">
          <FileViewer url={fileUrl} defaultLoading={isFetching} />
        </div>
      </div>
    </Drawer>
  )
}

export default FileList
