import React, { useCallback, useEffect, useMemo } from 'react'
import { Button, DataTable, Drawer, DrawerSize } from '@/components'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { useGetOrganizationsQuery } from '@/api/services/organization-list'
import { accessControlPermissionColumns } from '@/pages/AccessControl/config'
import { ReactComponent as MenuIcon } from '@/assets/icons/Menu Vertical.svg'
import { ReactComponent as PencilIcon } from '@/assets/icons/Pencil.svg'
import { ReactComponent as DeleteIcon } from '@/assets/icons/Delete.svg'
import {
  combineParsedPermission,
  levelList,
  parsePermission,
  resourceList,
} from '@/utils'
import {
  Level,
  ParsedPermissionWidthActions,
  PermissionValueTypeWithOrg,
  Resource,
} from '@/types'
import ResourceSection from '@/pages/AccessControl/Edit/ResourceSection'
import LevelSection from '@/pages/AccessControl/Edit/LevelSection'
import OrganizationSection from '@/pages/AccessControl/Edit/OrganizationSection'
import { editPermission, setGrantAccess } from '@/store/slices/access.slice'
import {
  useSetAccessControlMutation,
  useSetPermissionsMutation,
} from '@/api/services/acsess'
import toast from 'react-hot-toast'
import DropdownMenu from '@/components/DropdownMenu'

const defaultRowItem = (index: number) => ({
  resource: '' as Resource,
  level: '' as Level,
  organizations: ['*'],
  edit: false,
  delete: false,
  add: true,
  index,
})

const defaultErrorState = {
  resource: '',
  level: '',
  organizations: '',
}

const EditUser = ({ refetch }: { refetch: () => void }) => {
  const [setPermissions] = useSetPermissionsMutation()
  const [setAccess] = useSetAccessControlMutation()
  const dispatch = useAppDispatch()
  const { data: organizations = [] } = useGetOrganizationsQuery(undefined)
  const [error, setError] = React.useState(defaultErrorState)
  const [rowItem, setRowItem] =
    React.useState<ParsedPermissionWidthActions | null>(null)
  const { editUser, isGrantAccess } = useAppSelector(state => state.access)
  const [data, setData] = React.useState<ParsedPermissionWidthActions[]>([])

  const resources = useMemo(() => {
    return resourceList.map(resource => ({
      name: resource,
      _id: resource,
      disabled:
        data.filter(el => el.resource === resource).length === levelList.length,
    }))
  }, [data])

  useEffect(() => {
    const item = data.find(el => el.edit || el.add || el.delete)
    setRowItem(item || null)
  }, [data])

  const levels = useMemo(() => {
    return levelList.map(level => ({
      name: level,
      _id: level,
      disabled:
        data.filter(el => el.level === level).length === resourceList.length ||
        !!data.find(
          el => el.level === level && el.resource === rowItem?.resource,
        ),
    }))
  }, [data, rowItem?.resource])

  useEffect(() => {
    if (!editUser) {
      setData([])
    } else {
      setData(
        editUser.permissions.map(parsePermission).map((permission, index) => ({
          ...permission,
          edit: false,
          delete: false,
          add: false,
          index,
        })),
      )
    }
  }, [editUser])

  const onPermissionAdd = useCallback(() => {
    const item = defaultRowItem(data.length)
    setError(defaultErrorState)
    setData([...data, item])
    setRowItem(item)
  }, [data])

  const onAction = useCallback((index: number, action: 'edit' | 'delete') => {
    setError(defaultErrorState)
    setData(prev =>
      prev.map((el, i) => {
        if (i === index) {
          return {
            ...el,
            [action]: true,
          }
        }
        return el
      }),
    )
  }, [])

  const onCancel = useCallback((index: number, isAdd?: boolean) => {
    setError(defaultErrorState)
    if (isAdd) {
      setData(prev => prev.slice(0, prev.length - 1))
    } else {
      setData(prev =>
        prev.map((el, i) => {
          if (i === index) {
            return {
              ...el,
              edit: false,
              delete: false,
              add: false,
            }
          }
          return el
        }),
      )
    }
    setRowItem(null)
  }, [])

  const selectChange = (val: string, field: 'resource' | 'level') => {
    if (rowItem) {
      setRowItem({
        ...rowItem,
        [field]: val,
      })
    }
  }

  const orgChange = (val: string[]) => {
    if (rowItem) {
      setRowItem({
        ...rowItem,
        organizations: val,
      })
    }
  }

  const onSave = useCallback(
    (idx: number) => {
      const formError = { ...error }
      let hasError = false
      if (!rowItem?.resource) {
        formError.resource = 'Resource is required'
        hasError = true
      }
      if (!rowItem?.level) {
        formError.level = 'Level is required'
        hasError = true
      }
      if (!rowItem?.organizations.length) {
        formError.organizations = 'Organizations is required'
        hasError = true
      }
      setError(formError)
      if (hasError) return
      setData(prev => {
        if (rowItem) {
          return prev.map((el, index) => {
            if (index === idx) {
              return {
                ...rowItem,
                edit: false,
                delete: false,
                add: false,
              }
            }
            return el
          })
        }
        return prev
      })
    },
    [error, rowItem],
  )

  const onSubmit = () => {
    const permissions = combineParsedPermission(data.filter(el => !el.delete))
    if (isGrantAccess) {
      setAccess({
        hasAccess: true,
        permissions: permissions as PermissionValueTypeWithOrg[],
        email: editUser?.email || '',
      }).then(res => {
        if (!res.error) {
          toast.success('Access successfully granted')
          refetch()
          dispatch(editPermission(null))
          dispatch(setGrantAccess(false))
        } else {
          toast.error('Error granting access')
        }
      })
    } else {
      setPermissions({
        permissions: permissions as PermissionValueTypeWithOrg[],
        email: editUser?.email || '',
      }).then(res => {
        console.log(res)
        if (!res.error) {
          toast.success('Permissions updated successfully')
          refetch()
          dispatch(editPermission(null))
        } else {
          toast.error('Error updating permissions')
        }
      })
    }
  }
  const disabled = data.some(el => el.edit || el.add)

  if (!editUser) return null
  return (
    <Drawer
      size={DrawerSize.XL}
      open={!!editUser}
      title={`Manage permissions for ${
        isGrantAccess ? editUser?.email : editUser?.name
      }`}
      onClose={() => {
        dispatch(editPermission(null))
        dispatch(setGrantAccess(false))
      }}
    >
      <div className="w-full grow flex flex-col gap-6">
        <div>
          <Button
            status="secondary"
            size="small"
            onClick={onPermissionAdd}
            disabled={disabled}
          >
            Add Permission
          </Button>
        </div>
        <DataTable
          className="!overflow-visible"
          data={data}
          // @ts-ignore
          columns={accessControlPermissionColumns.map(column => {
            if (column.id === 'resource') {
              return {
                ...column,
                cell: ({ row }) => {
                  const item = row.original as ParsedPermissionWidthActions
                  const hasAction = item.edit || item.add
                  const permission = hasAction && rowItem ? rowItem : item
                  return (
                    <ResourceSection
                      error={error.resource}
                      resources={resources}
                      permission={permission}
                      onChange={val => selectChange(val as string, 'resource')}
                    />
                  )
                },
              }
            }
            if (column.id === 'level') {
              return {
                ...column,
                cell: ({ row }) => {
                  const item = row.original as ParsedPermissionWidthActions
                  const hasAction = item.edit || item.add
                  const permission = hasAction && rowItem ? rowItem : item
                  return (
                    <LevelSection
                      error={error.level}
                      levels={levels}
                      permission={permission}
                      onChange={val => selectChange(val as string, 'level')}
                    />
                  )
                },
              }
            }
            if (column.id === 'organizations') {
              return {
                ...column,
                cell: ({ row }) => {
                  const item = row.original as ParsedPermissionWidthActions
                  const hasAction = item.edit || item.add
                  const permission = hasAction && rowItem ? rowItem : item

                  return (
                    <OrganizationSection
                      error={error.organizations}
                      organizations={[
                        { name: 'All', _id: '*' },
                        ...organizations,
                      ]}
                      permission={permission}
                      onChange={val => orgChange(val)}
                    />
                  )
                },
              }
            }
            if (column.id === 'actions') {
              return {
                ...column,
                cell: ({ row }) => {
                  const value = row.original as ParsedPermissionWidthActions
                  const isEdit = value.edit
                  const isDelete = value.delete
                  const isAdd = value.add

                  if (isEdit) {
                    return (
                      <div className="w-full flex items-center gap-3 justify-end">
                        <Button
                          status="primary"
                          size="small"
                          onClick={() => onSave(value.index)}
                        >
                          Save
                        </Button>
                        <Button
                          status="secondary"
                          size="small"
                          onClick={() => onCancel(value.index)}
                        >
                          Cancel
                        </Button>
                      </div>
                    )
                  }
                  if (isAdd) {
                    return (
                      <div className="w-full flex items-center gap-3 justify-end">
                        <Button
                          status="primary"
                          size="small"
                          onClick={() => onSave(value.index)}
                        >
                          Save
                        </Button>
                        <Button
                          status="secondary"
                          size="small"
                          onClick={() => onCancel(value.index, true)}
                        >
                          Cancel
                        </Button>
                      </div>
                    )
                  }
                  if (isDelete) {
                    return (
                      <div className="w-full flex items-center gap-3 justify-end">
                        <Button
                          status="secondary"
                          size="small"
                          onClick={() => onCancel(value.index)}
                        >
                          Cancel
                        </Button>
                      </div>
                    )
                  }
                  return (
                    <div className="w-full flex items-center gap-3 justify-end">
                      <DropdownMenu
                        trigger={
                          <MenuIcon className="cursor-pointer text-black-50 hover:text-black-80 transition-all" />
                        }
                        items={[
                          {
                            label: 'Edit',
                            Icon: PencilIcon,
                            onClick: () => {
                              setRowItem(value)
                              onAction(value.index, 'edit')
                            },
                          },
                          {
                            label: 'Delete',
                            Icon: DeleteIcon,
                            onClick: () => {
                              setRowItem(null)
                              onAction(value.index, 'delete')
                            },
                          },
                        ]}
                      />
                    </div>
                  )
                },
              }
            }
            return column
          })}
        />
        <div>
          <Button
            status="primary"
            size="large"
            onClick={onSubmit}
            disabled={disabled}
          >
            Save
          </Button>
        </div>
      </div>
    </Drawer>
  )
}

export default EditUser
