import React from 'react'

import clsx from 'clsx'
import { Button, LoadingSkeleton, Typography } from '@/components'

import { ReactComponent as CloclIcon } from '@/assets/icons/Clock.svg'
import { ReactComponent as CloseIcon } from '@/assets/icons/Close.svg'
import { rem } from '@/utils'

interface TaskCardProps {
  className?: string
  icon: React.ReactNode
  title: string | React.ReactNode
  description: string | React.ReactNode
  date?: Date
  onClick?: () => void
  onClose?: () => void
  isClosable?: boolean
  loading?: boolean
  children?: React.ReactNode
}
const TaskCard = ({
  loading,
  isClosable = false,
  icon,
  title,
  description,
  date,
  onClick,
  onClose,
  className,
  children,
  ...rest
}: TaskCardProps) => {
  return (
    <div
      className={clsx(
        'p-5 bg-white-100 relative border border-gray-100 flex flex-col gap-3 rounded-2xl z-10',
        className,
      )}
      {...rest}
    >
      {isClosable && (
        <div
          className="absolute top-3 right-3 cursor-pointer text-black-50 hover:text-black-800 transition-all"
          onClick={onClose}
        >
          <CloseIcon />
        </div>
      )}
      <div className="flex gap-2 items-center">
        {loading ? <LoadingSkeleton height={rem(32)} width={rem(32)} /> : icon}
        {loading ? (
          <LoadingSkeleton height={rem(20)} width="80%" />
        ) : (
          <Typography variant="regular" weight="medium" className="pr-7">
            {title}
          </Typography>
        )}
      </div>
      {loading ? (
        <LoadingSkeleton height={rem(20)} width="80%" />
      ) : (
        <Typography variant="small" className="text-black-50">
          {description}
        </Typography>
      )}

      <div className="flex justify-between items-center gap-2 mt-5">
        {date && (
          <div className="px-2 flex gap-1 items-center">
            <CloclIcon />
            <Typography
              variant="tiny"
              weight="medium"
              className="text-black-50"
            >
              {date.toLocaleDateString()}
            </Typography>
          </div>
        )}
        {onClick && (
          <Button
            size="small"
            status="text"
            onClick={onClick}
            className="ml-auto"
          >
            Details
          </Button>
        )}
        {children}
      </div>
    </div>
  )
}

export default TaskCard
