import React, { useCallback } from 'react'
import { Button } from '@/components'
import { KeyIcon, UserMinusIcon } from '@heroicons/react/16/solid'
import { IListUserWithActions } from '@/types'
import { useAppDispatch } from '@/store/hooks'
import { editPermission } from '@/store/slices/access.slice'
import { useSetAccessControlMutation } from '@/api/services/acsess'

const AccessControlTableActions = ({
  user,
  onSuccess,
}: {
  user: IListUserWithActions
  onSuccess: () => void
}) => {
  const [setAccess] = useSetAccessControlMutation()
  const dispatch = useAppDispatch()

  const handleRemoveAccess = useCallback(() => {
    setAccess({
      email: user.email,
      hasAccess: false,
      permissions: user.permissions,
    }).then(() => {
      onSuccess()
    })
  }, [onSuccess, setAccess, user.email, user.permissions])

  const handleEditPermissions = useCallback(() => {
    dispatch(editPermission(user))
  }, [dispatch, user])
  return (
    <div className="flex items-center gap-1 justify-end w-full">
      <Button
        status="primary"
        size="small"
        onClick={handleEditPermissions}
        IconLeft={KeyIcon}
      >
        Permissions
      </Button>
      <Button
        status="secondary"
        size="small"
        onClick={handleRemoveAccess}
        IconLeft={UserMinusIcon}
      >
        Remove Access
      </Button>
    </div>
  )
}

export default AccessControlTableActions
