import React from 'react'

import { ReactComponent as LogoSmallIcon } from '@/assets/icons/Logo Small.svg'
import { ReactComponent as LogoIcon } from '@/assets/icons/Logo.svg'
import clsx from 'clsx'

interface LogoProps {
  type?: 'small' | 'large'
  className?: string
}

export const Logo = ({ type = 'small', className }: LogoProps) => {
  const Element = type === 'small' ? LogoSmallIcon : LogoIcon
  return <Element className={clsx('w-36 h-auto block max-w-full', className)} />
}
