import * as Yup from 'yup'

export const loginFormSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().min(6).required(),
})

export const checkForDynamicFieldsValidity = (
  message: string,
  fields?: string[],
) => {
  const extractTags = (str: string) => {
    const regex = /{{(.*?)}}/g
    const matches = []
    let match
    while ((match = regex.exec(str)) !== null) {
      matches.push(match[1])
    }
    return matches
  }
  const tags = extractTags(message) || []

  for (const tag of tags) {
    if (!fields?.includes(tag)) {
      return false
    }
  }
  return true
}

export const checkImage = (url: string) => {
  return new Promise(resolve => {
    const img = new Image()
    img.onload = () => resolve(true)
    img.onerror = () => resolve(false)
    img.src = url
  })
}

export const grantAccessSchema = Yup.object().shape({
  email: Yup.string().email().required(),
})
