import React, { useMemo } from 'react'
import { Option, Select } from '@/components'
import { ParsedPermissionWidthActions } from '@/types'

const OrganizationSection = ({
  permission,
  organizations,
  onChange,
  error,
}: {
  permission: ParsedPermissionWidthActions
  organizations: Option<string>[]
  onChange: (val: string[]) => void
  error: string
}) => {
  const value = permission.organizations
  const isEdit = permission.edit
  const isAdd = permission.add
  const isDelete = permission.delete

  const organizationName = useMemo(() => {
    let name: string | number = ''
    if (value[0] === '*') {
      name = 'All'
    } else if (organizations.length === 1) {
      name = organizations.find(org => org._id === value[0])?.name || ''
    } else {
      name = value
        .map((org: string) => {
          const orgName = organizations.find(o => o._id === org)?.name || ''
          return orgName
        })
        .join(', ')
    }
    return name
  }, [organizations, value])

  return (
    <div className="w-full">
      <div className="w-full">
        {isEdit || isAdd ? (
          <Select
            error={error}
            isMulti={true}
            className="w-full"
            options={organizations}
            value={organizations.filter(org => value.includes(org._id))}
            onChange={val => onChange(val.map(v => v._id as string))}
          />
        ) : (
          <span className={isDelete ? 'line-through opacity-70' : ''}>
            {organizationName}
          </span>
        )}
      </div>
    </div>
  )
}

export default OrganizationSection
