import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { AUTOMATIONS, ORGANIZATION } from '@/constants'
import { generatePath } from '@/utils'

const Redirect = () => {
  const { id } = useParams()
  return (
    <Navigate to={generatePath(ORGANIZATION + AUTOMATIONS, { id })} replace />
  )
}

export default Redirect
