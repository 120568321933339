import React from 'react'
import clsx from 'clsx'

import { ILoadingSkeletonProps } from './types'
import './styles.scss'

export const LoadingSkeleton: React.FC<ILoadingSkeletonProps> = ({
  className,
  WrapEl = 'div',
  width,
  height,
  borderRadius,
  style,
  ...rest
}) => {
  return (
    <WrapEl
      className={clsx('skeleton-container', className)}
      style={{
        height,
        width,
        borderRadius,
        ...style,
      }}
      {...rest}
    />
  )
}

export default LoadingSkeleton
