import { TBroadcastingScreen } from '@/types'
import InventorySetUp from '@/pages/SuccessPortal/Documentation/InventorySetUp'
import InventoryBugFixes from '@/pages/SuccessPortal/Documentation/InventoryBugFixes'
import CDKDrive from '@/pages/SuccessPortal/Documentation/CDKDrive'
import Reynolds from '@/pages/SuccessPortal/Documentation/Reynolds'
import DealerTrack from '@/pages/SuccessPortal/Documentation/DealerTrack'
import Cycles from '@/pages/SuccessPortal/Documentation/Cycles'
import BroadcastingCampaign from '@/pages/SuccessPortal/Broadcasting/Campaign'
import BroadcastingContent from '@/pages/SuccessPortal/Broadcasting/Content'
import BroadcastingScheduler from '@/pages/SuccessPortal/Broadcasting/Scheduler'

export const broadcastingScreenList: {
  _id: TBroadcastingScreen
  name: string
}[] = [
  {
    _id: 'campaign',
    name: 'Campaign',
  },
  {
    _id: 'content',
    name: 'Content',
  },
  {
    _id: 'scheduler',
    name: 'Scheduler',
  },
]

export const screenComponentList = {
  campaign: BroadcastingCampaign,
  content: BroadcastingContent,
  scheduler: BroadcastingScheduler,
}
