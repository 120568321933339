import { createSlice } from '@reduxjs/toolkit'
import { defaultPageMeta } from '@/constants'
import moment from 'moment-timezone'

import { IServiceAppointmentState } from '@/types'

const initialState: IServiceAppointmentState = {
  filter: {
    ...defaultPageMeta,
    startDate: moment().subtract(7, 'd').toString(),
    endDate: moment().endOf('day').toString(),
    location: '',
    workflowIds: [],
    externalId: '',
    organization: '',
  },
}

const serviceAppointmentSlice = createSlice({
  name: 'serviceAppointment',
  initialState,
  reducers: {
    clearFilter: state => {
      state.filter = initialState.filter
    },
    onFilterChange: (state, action) => {
      state.filter = { ...state.filter, ...action.payload }
    },
  },
})

export const { clearFilter, onFilterChange } = serviceAppointmentSlice.actions
export default serviceAppointmentSlice.reducer
