import React from 'react'
import { rem } from '@/utils'
import { LoadingSkeleton } from '@/components'

const ListLoadingSkeleton = ({ rows = 10 }: { rows: number }) => {
  return (
    <div className="flex gap-4 flex-col">
      {Array(rows)
        .fill(0)
        .map((_, idx) => (
          <LoadingSkeleton key={idx} height={rem(24)} width="100%" />
        ))}
    </div>
  )
}

export default ListLoadingSkeleton
