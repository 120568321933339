import { TCDKDriveDocumentType } from '@/types'
import InventorySetUp from '@/pages/SuccessPortal/Documentation/InventorySetUp'
import InventoryBugFixes from '@/pages/SuccessPortal/Documentation/InventoryBugFixes'
import CDKDrive from '@/pages/SuccessPortal/Documentation/CDKDrive'
import Reynolds from '@/pages/SuccessPortal/Documentation/Reynolds'
import DealerTrack from '@/pages/SuccessPortal/Documentation/DealerTrack'
import Cycles from '@/pages/SuccessPortal/Documentation/Cycles'
import { ColumnDef } from '@tanstack/react-table'
import clsx from 'clsx'
import { Typography } from '@/components'

export const documentationCardList: {
  title: string
  image: string
}[] = [
  {
    title: 'Service (DMS)',
    image: '/images/Success Portal Service.png',
  },
  {
    title: 'Inventory',
    image: '/images/Success Portal Inventory.png',
  },
  {
    title: 'Cycle',
    image: '/images/Success Portal Cycle.png',
  },
]

export const screenComponentList = {
  inventorySetUp: InventorySetUp,
  inventoryBugFixes: InventoryBugFixes,
  cdkDrive: CDKDrive,
  reynolds: Reynolds,
  dealerTrack: DealerTrack,
  cycles: Cycles,
}

export const inventorySetUpSteps = [
  {
    title: 'Step 1',
    content:
      'Description of the actions that need to be performed when Inventory Set Up(Step 1)',
  },
  {
    title: 'Step 2',
    content:
      'Description of the actions that need to be performed when Inventory Set Up(Step 2)',
  },
  {
    title: 'Step 3',
    content:
      'Description of the actions that need to be performed when Inventory Set Up(Step 3)',
  },
  {
    title: 'Step 4',
    content:
      'Description of the actions that need to be performed when Inventory Set Up(Step 4)',
  },
]

export const inventoryBugFixesSteps = [
  {
    title: 'Problem 1',
    problem: 'Problem info 1',
    solution: 'Solution info 1',
  },
  {
    title: 'Problem 2',
    problem: 'Problem info 2',
    solution: 'Solution info 2',
  },
  {
    title: 'Problem 3',
    problem: 'Problem info 3',
    solution: 'Solution info 3',
  },
  {
    title: 'Problem 4',
    problem: 'Problem info 4',
    solution: 'Solution info 4',
  },
]

export const cicleList = [
  {
    title: 'Cycle 34 (November 19th - December 2nd)',
    content:
      'Description of the actions that need to be performed for Cycle 34',
  },
  {
    title: 'Cycle 35 (December 3rd - December 16th)',
    content:
      'Description of the actions that need to be performed for Cycle 35',
  },
  {
    title: 'Cycle 36 (December 17th - December 30th)',
    content:
      'Description of the actions that need to be performed for Cycle 36',
  },
  {
    title: 'Cycle 37 (December 31st - January 13th)',
    content:
      'Description of the actions that need to be performed for Cycle 37',
  },
  {
    title: 'Cycle 38 (January 14th - January 27th)',
    content:
      'Description of the actions that need to be performed for Cycle 38',
  },
  {
    title: 'Cycle 39 (January 28th - February 10th)',
    content:
      'Description of the actions that need to be performed for Cycle 39',
  },
  {
    title: 'Cycle 40 (February 11th - February 24th)',
    content:
      'Description of the actions that need to be performed for Cycle 40',
  },
  {
    title: 'Cycle 41 (February 25th - March 10th)',
    content:
      'Description of the actions that need to be performed for Cycle 41',
  },
]

// TODO: add types when get data from backend
export const cdkDriveDealerTableColumns: ColumnDef<any, any>[] = [
  {
    id: 'dealer',
    accessorKey: 'dealer',
    header: () => <span>Dealer Name</span>,
    cell: info => (
      <Typography variant="regular" weight="medium" className="text-blue-100">
        {info.getValue()?.name}
      </Typography>
    ),
  },
]

// TODO: add types when get data from backend
export const cdkDriveWorkflowTableColumns: ColumnDef<any, any>[] = [
  {
    id: 'active',
    accessorKey: 'active',
    header: () => <span></span>,
    cell: info => {
      const value = info.getValue()
      return (
        <span
          className={clsx('w-2 h-2 rounded-full', {
            'bg-green-100': value,
            'bg-black-10': !value,
          })}
        ></span>
      )
    },
    size: 20,
  },
  {
    id: 'feature',
    accessorKey: 'feature',
    header: () => <span>Features</span>,
    cell: info => info.getValue(),
    size: 1000,
  },
  {
    id: 'isCDK',
    accessorKey: 'isCDK',
    header: () => <span>CDKDrive</span>,
    size: 1000,
  },
]

export const onboardingDmsDealerList = [
  {
    title: 'Title 1',
    content:
      'Description of the actions that need to be performed for 1st DMS Dealer',
  },
  {
    title: 'Title 2',
    content:
      'Description of the actions that need to be performed for 2nd DMS Dealer',
  },
  {
    title: 'Title 3',
    content:
      'Description of the actions that need to be performed for 3rd DMS Dealer',
  },
]

export const workflowsPerDmsList = [
  {
    title: 'Title 1',
    content:
      'Description of the actions that need to be performed for workflow 1',
  },
  {
    title: 'Title 2',
    content:
      'Description of the actions that need to be performed for workflow 2',
  },
]

export const trackingRoList = [
  {
    title: 'Title 1',
    content: 'Description of the actions that need to be performed for RO 1',
  },
  {
    title: 'Title 2',
    content: 'Description of the actions that need to be performed for RO 2',
  },
  {
    title: 'Title 3',
    content: 'Description of the actions that need to be performed for RO 3',
  },
]

export const documentViewModalTexts: Record<
  TCDKDriveDocumentType,
  { text: string; data: { title: string; content: string }[] }
> = {
  onboarding: {
    text: 'Onboarding per DMS',
    data: onboardingDmsDealerList,
  },
  workflows: {
    text: 'Workflows per DMS',
    data: workflowsPerDmsList,
  },
  tracking: {
    text: 'Tracking per RO',
    data: trackingRoList,
  },
}
