import { IParams, permissionType, permissionValueType, TMenu } from '@/types'
import { A2P, ADMIN_MAIN_MENU, REGISTRATION } from '@/constants'
import { checkPermissions } from '@/utils/permissions'
import { calculateStartEndDateWithTimezone } from '@/utils/date'

export const generateQueryParams = (params: IParams): string => {
  const queryParams = new URLSearchParams()

  Object.keys(params).forEach(param => {
    const value = params[param]

    if (Array.isArray(value)) {
      if (value.length) {
        value.forEach((item: string) => {
          queryParams.append(`${param}[]`, item)
        })
      }
      return
    }

    if (value) {
      if (param === 'startDate' || param === 'endDate') {
        const dates = calculateStartEndDateWithTimezone({
          start: params.startDate,
          end: params.endDate,
        })
        if (param === 'startDate') {
          queryParams.append(param, dates.startDate)
        } else {
          queryParams.append(param, dates.endDate)
        }
      } else {
        queryParams.append(
          param,
          value instanceof Date ? value.toISOString() : value.toString(),
        )
      }
    }
  })

  return `?${queryParams.toString()}`
}

export const generatePath = (
  path: string,
  params: IParams = {},
  fullUrl?: boolean,
): string => {
  let newPath: string = path
  Object.keys(params).forEach(param => {
    newPath = newPath.replace(`:${param}`, `${params[param] || ''}`)
  })

  if (fullUrl) {
    const baseUrl = import.meta.env.VITE_APP_URL
    return `${baseUrl}${newPath}`
  }
  return newPath
}

export const generateNavigationPathsByPermissions = (
  permissions: permissionValueType[],
): TMenu[] => {
  const paths: TMenu[] = []
  return ADMIN_MAIN_MENU.reduce((acc, item) => {
    if (
      item.path.includes(A2P) &&
      checkPermissions(permissions, [permissionType.ONBOARDING_READ]) &&
      !checkPermissions(permissions, [permissionType.ONBOARDING_WRITE])
    ) {
      item.path = `${A2P}/${REGISTRATION}`
      acc.push(item)
      return acc
    }
    if (checkPermissions(permissions, item.access || [])) {
      if (item.subMenus) {
        const subMenus = item.subMenus.filter(child =>
          checkPermissions(permissions, child.access || []),
        )
        if (subMenus.length) {
          acc.push({ ...item, subMenus })
        }
        return acc
      }
      acc.push(item)
    }
    return acc
  }, paths)
}

export const isValidUrl = (url: string): boolean => {
  const regex = /^(?!www\.)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/
  return regex.test(url)
}
