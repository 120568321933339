import { createSlice } from '@reduxjs/toolkit'
import { defaultPageMeta } from '@/constants'
import moment from 'moment-timezone'

import { ISystemMonitoringState } from '@/types'

const initialState: ISystemMonitoringState = {
  loading: false,
  error: undefined,
  filter: {
    ...defaultPageMeta,
    automationType: 'automation',
    startDate: moment().subtract(7, 'd').toString(),
    endDate: moment().endOf('day').toString(),
    organizationIds: [],
    crm: '',
    errorCode: '',
  },
  errors: [
    {
      _id: '66b0de1ab06b9f01b4973d5b',
      crm: 'CRM hardcoded',
      automation: {
        _id: '66b0de15e970d84bfe403c22',
        name: 'Test for another org',
        type: 'automation',
      },
      organization: {
        _id: '5ece76aa87f481001336c85b',
        name: 'Test Organization [TEST]',
      },
      createdAt: moment().toString(),
      errorCode: '#341500',
    },
  ],
  totalItems: 0,
}

const systemMonitoringSlice = createSlice({
  name: 'systemMonitoring',
  initialState,
  reducers: {
    clearFilter: state => {
      state.filter = initialState.filter
    },
    onFilterChange: (state, action) => {
      state.filter = { ...state.filter, ...action.payload }
    },
  },
})

export const { clearFilter, onFilterChange } = systemMonitoringSlice.actions
export default systemMonitoringSlice.reducer
