import React, { useCallback, useEffect, useState } from 'react'

import { Checkbox, Select } from '@/components'
import { ICustomField } from '@/types'

interface IActivixProps {
  current: string | boolean
  field: ICustomField
  onChange: (_value: string | boolean | undefined | number) => void
}
const Activix: React.FC<IActivixProps> = ({ current, field, onChange }) => {
  const [value, setValue] = useState<any>()
  const [active, setActive] = useState(false)

  useEffect(() => {
    setActive(!!current)
    setValue(current)
  }, [current])

  const toggleActive = useCallback(() => {
    setActive(!active)
    onChange?.(!active ? value : undefined)
  }, [active, onChange, value])

  return (
    <div className="flex w-full justify-between mt-2">
      <Checkbox
        checked={active}
        label={field.viewName}
        onChange={toggleActive}
      />
      <Select
        className="w-1/2"
        disabled={!active}
        value={field.options?.find(el => el === current)}
        placeholder="Select an option"
        options={(field.options || []).map(option => ({
          name: option,
          _id: option,
        }))}
        onChange={_option => onChange?.(_option?._id)}
      />
    </div>
  )
}

export default Activix
