import { ColumnDef } from '@tanstack/react-table'
import OrganizationLink from '@/sections/OrganizationLink'
import React from 'react'
import { IOrganizationSummary } from '@/types'

// TODO: Add type for summary table columns
export const summaryTableColumns: ColumnDef<IOrganizationSummary, any>[] = [
  {
    id: 'organization',
    accessorKey: 'organization',
    header: () => <span>Organization</span>,
    cell: info => {
      const data = info.row.original.organization
      return <OrganizationLink id={data._id} name={data.name} />
    },
  },
  {
    id: 'automations',
    accessorKey: 'automations',
    header: () => <span>Automations</span>,
  },
  {
    id: 'appointments',
    accessorKey: 'appointments',
    header: () => <span>Appointments</span>,
  },
  {
    id: 'tasks',
    accessorKey: 'tasks',
    header: () => <span>Tasks</span>,
  },
  {
    id: 'leads',
    accessorKey: 'leads',
    header: () => <span>Leads</span>,
  },
  {
    id: 'aiBotCustomers',
    accessorKey: 'aiBotCustomers',
    header: () => <span>AI Bot Customers</span>,
  },
  {
    id: 'repairOrders',
    accessorKey: 'repairOrders',
    header: () => <span>Repair Orders</span>,
  },
  {
    id: 'twilioMetrics',
    accessorKey: 'twilioMetrics',
    header: () => <span>Twilio Metrics</span>,
    cell: ({ getValue }) => `$${getValue()}`,
  },
]
