import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINT, AUTH_TOKEN_KEY } from '@/constants'
import { endpoints } from '@/api/constants'
import {
  IAutomationData,
  IAutomationFilter,
  IAutomationMutationPayload,
  IMedia,
  IPaginatedRes,
  IPingTestResponse,
  IResponse,
  ITask,
} from '@/types'
import { generateQueryParams, getStorageValue } from '@/utils'

export const automationApi = createApi({
  reducerPath: 'automationApi',
  tagTypes: ['Automations'],
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT,
    prepareHeaders: headers => {
      const token = getStorageValue(AUTH_TOKEN_KEY)
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  endpoints: builder => ({
    getOrganizationAutomations: builder.query<
      IAutomationData[],
      { id?: string }
    >({
      query: ({ id }) => ({
        url: endpoints.automations,
        body: { id },
        method: 'POST',
      }),
      transformResponse: (response: IAutomationData[]) => {
        return response.sort((a, b) => {
          if (a.location.name < b.location.name) return -1
          if (a.location.name > b.location.name) return 1
          if (a.name < b.name) return -1
          if (a.name > b.name) return 1
          return 0
        })
      },
    }),
    getAllTask: builder.query<IPaginatedRes<ITask>, Partial<IAutomationFilter>>(
      {
        query: query => ({
          url: endpoints.allTask + generateQueryParams(query),
        }),
      },
    ),
    automationFileUpload: builder.mutation<
      IResponse<IMedia | null>,
      { image: File | null }
    >({
      query: ({ image }) => {
        const formData = new FormData()
        formData.append('file', image as Blob)
        return {
          url: endpoints.uploadImage,
          method: 'POST',
          body: formData,
        }
      },
    }),
    editAutomation: builder.mutation<string, IAutomationMutationPayload>({
      query: data => {
        return {
          url: endpoints.editAutomation,
          method: 'POST',
          body: data,
          responseHandler: response => response.text(),
        }
      },
    }),
    addAutomation: builder.mutation<string, IAutomationMutationPayload>({
      query: data => {
        return {
          url: endpoints.addAutomation,
          method: 'POST',
          body: data,
        }
      },
    }),
    pingTest: builder.query<IPingTestResponse, { id: string | null }>({
      query: query => {
        return {
          url: endpoints.automationPingTest,
          method: 'POST',
          body: query,
        }
      },
    }),
    retry: builder.mutation<undefined, { id: string }>({
      query: query => {
        return {
          url: endpoints.automationsRetry,
          method: 'POST',
          responseHandler: response => response.text(),
          body: query,
        }
      },
    }),
    acknowledge: builder.mutation<undefined, { id: string }>({
      query: query => {
        return {
          url: endpoints.acknowledged,
          method: 'POST',
          body: query,
        }
      },
    }),
    getEventImage: builder.query<string, { s3Path?: string }>({
      query: query => {
        return {
          url: endpoints.eventImagePath,
          method: 'POST',
          body: query,
        }
      },
    }),
    toggleAutomation: builder.mutation<
      string,
      { id: string; organization: string }
    >({
      query: ({ id }) => {
        return {
          url: endpoints.toggleAutomation,
          method: 'POST',
          body: { id },
          responseHandler: response => response.text(),
        }
      },
      async onQueryStarted({ id, organization }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(
              automationApi.util.updateQueryData(
                'getOrganizationAutomations',
                { id: organization },
                draft => {
                  const taskIndex = draft.findIndex(task => task._id === id) // Find the index of the task
                  if (taskIndex !== -1) {
                    draft[taskIndex].active = !draft[taskIndex].active // Toggle the active status
                  }
                },
              ),
            )
          }
        } catch (err) {
          console.log('Error', err)
        }
      },
    }),
    deleteAutomation: builder.mutation<
      string,
      { id: string; organization: string }
    >({
      query: ({ id }) => {
        return {
          url: endpoints.deleteAutomation,
          method: 'POST',
          body: { id },
          responseHandler: response => response.text(),
        }
      },
      async onQueryStarted({ id, organization }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(
              automationApi.util.updateQueryData(
                'getOrganizationAutomations',
                { id: organization },
                draft => {
                  const index = draft.findIndex(task => task._id === id)
                  if (index !== -1) {
                    draft.splice(index, 1)
                  }
                },
              ),
            )
          }
        } catch (err) {
          console.log('Error', err)
        }
      },
    }),
  }),
})

export const {
  useGetOrganizationAutomationsQuery,
  useAutomationFileUploadMutation,
  useEditAutomationMutation,
  useAddAutomationMutation,
  usePingTestQuery,
  useRetryMutation,
  useAcknowledgeMutation,
  useGetAllTaskQuery,
  useGetEventImageQuery,
  useToggleAutomationMutation,
  useDeleteAutomationMutation,
} = automationApi
