import AutomationFormRules from '@/pages/Organization/AutomationForm/Rules'
import Frequency from '@/pages/Organization/AutomationForm/Frequency'
import Uploader from '@/pages/Organization/AutomationForm/Uploader'
import { useGetSingleOrganizationsQuery } from '@/api/services/organization'
import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { Button, Drawer, DrawerSize, LoadingSkeleton } from '@/components'
import {
  defaultState,
  onFormChange,
  onFormTypeChange,
  resetForm,
  setFormErrors,
} from '@/store/slices/pages/organization/organization-automations.slice'
import { checkForDynamicFieldsValidity } from '@/utils'
import { IAutomationMutationPayload, TPingTestType } from '@/types'
import {
  useAddAutomationMutation,
  useEditAutomationMutation,
  useGetOrganizationAutomationsQuery,
  usePingTestQuery,
} from '@/api/services/organization/organization-automation'
import {
  frequencyDefaultState,
  reviewInviteUrlTag,
} from '@/constants/automations'
import AutomationStatusModal from '@/pages/Organization/AutomationForm/StatusModal'
import Restrictions from '@/pages/Organization/AutomationForm/Restrictions'

const AutomationForm = () => {
  const { id } = useParams()
  const { automationFormType: automation } = useAppSelector(
    state => state.organizationAutomations,
  )
  const [pingStatus, setPingStatus] = useState<TPingTestType | null>(null)
  const [automationId, setAutomationId] = useState<string | null>(null)
  const isEditing = automation !== 'create'

  const dispatch = useAppDispatch()
  const [editAutomation] = useEditAutomationMutation()
  const [addAutomation] = useAddAutomationMutation()
  const { data: organization } = useGetSingleOrganizationsQuery({ id })
  const { refetch } = useGetOrganizationAutomationsQuery({ id })
  const { data: { status } = {} } = usePingTestQuery(
    { id: automationId },
    {
      skip: !automationId && (pingStatus !== 'pending' || !pingStatus),
      pollingInterval: 3000,
    },
  )

  const { form, formErrors } = useAppSelector(
    state => state.organizationAutomations,
  )
  const { data = [], isLoading } = useGetOrganizationAutomationsQuery(
    { id },
    {
      skip: !isEditing,
    },
  )

  useEffect(() => {
    if (status && status !== 'pending') {
      setPingStatus(status)
      setAutomationId(null)
    }
  }, [status])

  useEffect(() => {
    return () => {
      dispatch(resetForm())
    }
  }, [dispatch, automation])

  useEffect(() => {
    if (automation && automation !== 'create') {
      const currentAutomation = data.find(_v => _v._id === automation)
      if (currentAutomation) {
        dispatch(
          onFormChange({
            edit: true,
            meta: currentAutomation?.meta,
            name: currentAutomation?.name,
            ruleId: currentAutomation?.rule,
            message: currentAutomation?.message,
            automationId: currentAutomation._id,
            media: currentAutomation.media,
            locationId: currentAutomation?.location?._id,
            end: currentAutomation?.endOffset?.value,
            endUnit: currentAutomation?.endOffset?.unit,
            start: currentAutomation?.startOffset?.value,

            startUnit: currentAutomation?.startOffset?.unit,
            frequencies: currentAutomation?.frequencies?.length
              ? currentAutomation.frequencies.map(freq => {
                  return {
                    increment: freq.increment,
                    time: freq.time,
                    days: [1],
                    weekdays: freq.weekdays || [],
                  }
                })
              : [
                  {
                    ...frequencyDefaultState,
                    time: {
                      ...frequencyDefaultState.time,
                      isoTimezone: organization?.timezone,
                    },
                  },
                ],

            isReviewInvite: currentAutomation?.message === reviewInviteUrlTag,
            assignedUsers:
              currentAutomation?.assignedUsers?.map(user => user) || [],
          }),
        )
      }
    }
  }, [automation, data, dispatch, organization?.timezone])

  const locationCredentials = organization?.credentials?.find(
    _v => _v.location === form.locationId,
  )

  const rule = useMemo(() => {
    let currentRule = organization?.rules
      ? [...organization.rules]?.find(_v => _v._id === form.ruleId)
      : undefined
    if (currentRule && currentRule?.dynamicFields) {
      currentRule = { ...currentRule }
      currentRule.dynamicFields = [
        ...new Set([
          ...currentRule.dynamicFields,
          'organization_name',
          'location_name',
        ]),
      ]
    }

    return currentRule
  }, [organization?.rules, form.ruleId])

  const setError = (values: Record<string, string>) => {
    dispatch(setFormErrors(values))
  }

  const onSubmit = useCallback(async () => {
    setError({})
    if (!form.isReviewInvite) {
      const canSubmit = checkForDynamicFieldsValidity(
        form?.message || '',
        rule?.dynamicFields,
      )

      if (!canSubmit) {
        setError({
          ...formErrors,
          message: 'Dynamic fields can be any of available fields only.',
        })
        return
      }
    }

    const body: IAutomationMutationPayload = {
      id: form?.edit ? form.automationId : undefined,
      location: form?.locationId,
      name: form?.name,
      crm: locationCredentials?.crm,
      rule: form?.ruleId,
      message: form?.message,
      startOffset: [form?.startUnit, form?.start],
      endOffset: [form?.endUnit, form?.end],
      meta: form?.meta,
      frequencies: form.frequencies,
      media: form.media,
      assignedUsers: undefined,
    }

    if (rule?.type === 'automation' || rule?.type === 'resurrection') {
      if (!form?.frequencies?.length) {
        setError({
          ...formErrors,
          frequencies: 'There must be at least one frequency',
        })
        return
      }
    }
    if (rule?.type === 'appointment') {
      body.name = 'Appointments Confirmations'
      body.message = 'none'
      body.frequencies = undefined
    }

    if (rule?.type === 'lead') {
      // @ts-ignore
      if (form?.meta?.source_description) {
        body.name =
          // @ts-ignore
          'Lead Importer (' + form?.meta.source_description + ')'
        // @ts-ignore
      } else if (form?.meta?.source_name) {
        // @ts-ignore
        body.name = 'Lead Importer (' + form?.meta.source_name + ')'
        // @ts-ignore
      } else if (form?.meta?.lead_source) {
        // @ts-ignore
        body.name = 'Lead Importer (' + form?.meta.lead_source + ')'
        // @ts-ignore
      } else if (form?.meta?.leadsource) {
        // @ts-ignore
        body.name = 'Lead Importer (' + form?.meta.leadsource + ')'
        // @ts-ignore
      } else if (form?.meta?.source) {
        // @ts-ignore
        body.name = 'Lead Importer (' + form?.meta.source + ')'
        // @ts-ignore
        if (form?.meta?.subSource) {
          // @ts-ignore
          body.name += ' (' + form?.meta.subSource + ')'
        }
      } else {
        body.name = 'Lead Importer'
      }
      body.message = 'none'
      body.frequencies = undefined
    }

    if (rule?.type === 'workflow') {
      body.message = 'none'
      body.frequencies = undefined
    }

    if (rule?.type === 'aibot') {
      body.name = 'AI Bot Manager'
      // @ts-ignore
      if (form?.meta?.source) {
        // @ts-ignore
        body.name += ' (' + form?.meta.source + ')'
        // @ts-ignore
        if (form?.meta?.subSource) {
          // @ts-ignore
          body.name += ' (' + currentState?.meta.subSource + ')'
        }
      }
      body.message = 'none'
      body.frequencies = undefined
    }

    if (rule?.type === 'weeklyreport') {
      body.name = 'Weekly automations report'
      body.message = 'none'
      body.frequencies = undefined
    }

    if (rule?.type === 'resurrection') {
      // @ts-ignore
      body.name = 'Resurrection ' + form?.meta?.source_name
    }
    if (rule?.type === 'automation') {
      // @ts-ignore
      body.assignedUsers = form?.assignedUsers
    }

    const request = form?.edit ? editAutomation : addAutomation

    try {
      const response = await request(body).unwrap()
      if (form?.edit && response === 'OK') {
        setPingStatus('completed')
      }
      if (response !== 'OK') {
        setAutomationId(response)
        setPingStatus('pending')
        dispatch(onFormChange(defaultState))
      }
      refetch()
    } catch (err) {
      console.log(err)
      setPingStatus('failed')
    }
  }, [
    form,
    setError,
    locationCredentials?.crm,
    rule?.type,
    rule?.dynamicFields,
    editAutomation,
    addAutomation,
    formErrors,
    dispatch,
    refetch,
  ])
  return (
    <Drawer
      open={!!automation}
      onClose={() => {
        dispatch(onFormTypeChange(undefined))
      }}
      title={isEditing ? 'Edit Automation' : 'Create Automation'}
      size={DrawerSize.FULL}
    >
      <div className="flex flex-grow gap-10 w-full bg-white-100">
        {isEditing && isLoading ? (
          <>
            <LoadingSkeleton width="50%" height="100%" borderRadius={20} />
            <LoadingSkeleton width="50%" height="100%" />
          </>
        ) : (
          <>
            <AutomationFormRules rule={rule} />
            <div className="lg:w-1/2 py-1 flex flex-col justify-between">
              <div className="flex flex-col gap-6">
                {!!rule &&
                  rule.type !== 'lead' &&
                  rule.type !== 'appointment' &&
                  rule.type !== 'workflow' &&
                  rule.type !== 'aibot' && <Restrictions />}
                <Frequency rule={rule} />
              </div>
              {rule?.type === 'automation' &&
                rule &&
                rule?.crm !== 'vinsolutions' &&
                rule?.crm !== 'subarucareconnect' &&
                rule?.crm !== 'dealercenter' && <Uploader />}
              <div className="flex justify-end">
                <Button
                  status="primary"
                  size="large"
                  onClick={onSubmit}
                  className="mt-auto"
                >
                  {rule?.type === 'automation'
                    ? 'Save Automation'
                    : 'Save Rule'}
                </Button>
              </div>
            </div>
          </>
        )}
        {pingStatus && <AutomationStatusModal status={pingStatus} />}
      </div>
    </Drawer>
  )
}

export default AutomationForm
