import React, { ReactNode, useMemo } from 'react'
import { Tooltip as TooltipBase } from 'react-tooltip'

interface TooltipProps {
  children: ReactNode
  content: string
  place?: 'top' | 'right' | 'bottom' | 'left'
}

import styles from './styles.module.scss'
import { makeId } from '@/utils'

const Tooltip = ({ content, children, place = 'right' }: TooltipProps) => {
  const id = useMemo(() => makeId(), [])
  return (
    <span className={styles.tooltipWrapper}>
      <span id={id}>{children}</span>
      <TooltipBase
        place={place}
        anchorSelect={`#${id}`}
        content={content}
        className={styles.tooltip}
      />
    </span>
  )
}

export default Tooltip
