import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { IconButton, StatsCard } from '@/components'

import { ReactComponent as ClockIcon } from '@/assets/icons/Clock.svg'
import { AutomationStatus } from '@/types'

const RealTime = ({ text, timezone }: { text: string; timezone: string }) => {
  const [time, setTime] = useState(moment().tz(timezone).format('hh:mm:ss A'))
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment().tz(timezone).format('hh:mm:ss A'))
    }, 1000)

    return () => clearInterval(interval)
  }, [timezone])
  return (
    <StatsCard
      status={AutomationStatus.COMPLETED}
      className="max-w-max"
      icon={
        <IconButton
          wrapperClassName="bg-white-20 text-white-100"
          Icon={ClockIcon}
          size={52}
        />
      }
      title={text}
      value={time}
    />
  )
}

export default RealTime
