import React from 'react'
import { DataTable } from '@/components'
import Pagination from '@/components/Pagination'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { useParams } from 'react-router-dom'
import { onFilterChange } from '@/store/slices/service-appointment.slice'
import { serviceAppointmentsTableColumns } from './config'
import { useGetServiceAppointmentsQuery } from '@/api/services/service-appointment'
import ServiceAppointmentsFilter from '@/pages/Organization/ServiceAppointments/Filter'

const ServiceAppointments = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const { filter } = useAppSelector(state => state.serviceAppointments)
  const {
    data: { data = [], pageMeta: { totalItems = 0 } = {} } = {},
    isFetching,
  } = useGetServiceAppointmentsQuery({
    ...filter,
    organization: id,
  })
  console.log(filter)
  const onPageChange = (page: number) => {
    dispatch(
      onFilterChange({
        page,
      }),
    )
  }

  return (
    <div className="w-full flex flex-col gap-6">
      <ServiceAppointmentsFilter />
      <DataTable
        loading={isFetching}
        columns={serviceAppointmentsTableColumns}
        data={data}
      />
      <Pagination
        currentPage={filter.page}
        totalCount={totalItems}
        onPageChange={onPageChange}
      />
    </div>
  )
}

export default ServiceAppointments
