import React from 'react'
import { DateTimePicker } from '@mui/x-date-pickers'
import moment from 'moment-timezone'
import { setAppointment } from '@/store/slices/superhuman.slice'
import { Button, Typography, IconButton } from '@/components'

import { ReactComponent as CloseIcon } from '@/assets/icons/Close.svg'
import { useAppDispatch, useAppSelector } from '@/store/hooks'

const AppointmentForm = () => {
  const dispatch = useAppDispatch()
  const { job, appointment } = useAppSelector(state => state.superhuman)
  return (
    <div>
      <div className="flex gap-3 justify-between items-center mt-3">
        <DateTimePicker
          label="Create a new appointment"
          defaultValue={undefined}
          timezone={'UTC'}
          // @ts-ignore
          value={moment.utc(appointment)}
          onChange={newValue => {
            dispatch(setAppointment(moment.utc(newValue).toDate()))
          }}
          className="w-full"
        />
        {appointment && (
          <IconButton
            Icon={CloseIcon}
            size={24}
            wrapperClassName="bg-red-50 text-red-100"
            onClick={() => {
              dispatch(setAppointment(null))
            }}
          />
        )}
      </div>
      {job?.ai_recommended_appointment ? (
        <div>
          <Typography variant="regular" weight="semibold" className="mt-3">
            Recommended appointment datetime
          </Typography>
          <div className="flex justify-between items-center gap-2">
            {moment(job?.ai_recommended_appointment)
              .tz('UTC', false)
              .format('DD-MM-YYYY HH:mm')}
            <Button
              size="small"
              status="secondary"
              onClick={() => {
                dispatch(
                  setAppointment(
                    moment(job?.ai_recommended_appointment)
                      .tz('UTC', false)
                      .toDate(),
                  ),
                )
              }}
            >
              Use
            </Button>
          </div>
        </div>
      ) : null}

      <Typography variant="tiny" className="block text-black-50 mt-6">
        You are using {moment.tz.guess()} timezone
      </Typography>
    </div>
  )
}

export default AppointmentForm
