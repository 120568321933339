import React from 'react'
import { ReactComponent as SettingsIcon } from '@/assets/icons/Settings.svg'
import { ReactComponent as RefreshIcon } from '@/assets/icons/Refresh.svg'
import { ReactComponent as SearchIcon } from '@/assets/icons/Search.svg'
import { ReactComponent as MenuIcon } from '@/assets/icons/Menu Vertical.svg'
import { ReactComponent as EyeIcon } from '@/assets/icons/Eye.svg'
import { ReactComponent as FolderIcon } from '@/assets/icons/Folder.svg'
import { ReactComponent as AuditIcon } from '@/assets/icons/Audit Trail.svg'
import { ReactComponent as DeleteIcon } from '@/assets/icons/Delete.svg'
import { IInventory } from '@/types/inventory'
import DropdownMenu from '@/components/DropdownMenu'
import { useAppDispatch } from '@/store/hooks'
import {
  onEventIdChange,
  onFormChange,
  onFormToggle,
  onImportJobsChange,
  onShowFilesChange,
} from '@/store/slices/pages/inventory.slice'
import ConfirmModal from '@/sections/ConfirmModal'
import {
  useDeleteInventoryMutation,
  useLazySyncInventoryQuery,
} from '@/api/services/inventory'
import toast from 'react-hot-toast'

const InventoryTableActions = ({
  item,
  onExpand,
  onSuccess,
}: {
  item: IInventory
  onExpand: () => void
  onSuccess: () => void
}) => {
  const [deleteItem, setDeleteItem] = React.useState<string | null>(null)
  const dispatch = useAppDispatch()
  const [deleteInventory, { isLoading }] = useDeleteInventoryMutation()
  const [sync] = useLazySyncInventoryQuery()
  return (
    <div className="flex gap-1 items-center justify-end w-full">
      <SearchIcon
        className="cursor-pointer text-black-50 hover:text-black-80 transition-all"
        onClick={onExpand}
      />
      <DropdownMenu
        trigger={
          <MenuIcon className="cursor-pointer text-black-50 hover:text-black-80 transition-all" />
        }
        items={[
          {
            label: 'Edit',
            Icon: SettingsIcon,
            onClick: () => {
              dispatch(
                onFormChange({
                  id: item.id,
                  domain_url: item.domain_url?.split(',') || [''],
                  dealer_id: item.dealer_id,
                  organization_id: item.organization._id,
                  vendor: item.vendor,
                }),
              )
              dispatch(onFormToggle(true))
            },
          },
          {
            label: 'Sync Inventory',
            Icon: RefreshIcon,
            onClick: () => {
              sync({ organizationId: item.organization._id })
                .unwrap()
                .then(() => {
                  toast.success('Inventory sync has been queued')
                })
            },
          },
          {
            label: 'Imports',
            Icon: EyeIcon,
            onClick: () => {
              dispatch(onImportJobsChange(item.id))
            },
          },
          {
            label: 'Files',
            Icon: FolderIcon,
            onClick: () => {
              dispatch(onShowFilesChange(item))
            },
          },
          {
            label: 'Audit trail',
            Icon: AuditIcon,
            onClick: () => {
              dispatch(onEventIdChange(item.id))
            },
          },
          {
            label: 'Delete',
            Icon: DeleteIcon,
            onClick: () => {
              setDeleteItem(item.id)
            },
          },
        ]}
      />
      <ConfirmModal
        title="Confirm invetory deletion"
        onClose={() => setDeleteItem(null)}
        open={!!deleteItem}
        isLoading={isLoading}
        content="Are you sure you want to delete this inventory?"
        onConfirm={() => {
          if (!deleteItem) return
          deleteInventory({ inventoryId: deleteItem })
            .unwrap()
            .then(() => {
              onSuccess()
              setDeleteItem(null)
              toast.success('Inventory deleted successfully')
            })
            .catch(() => {
              toast.error('Error deleting inventory')
            })
        }}
      />
    </div>
  )
}

export default InventoryTableActions
