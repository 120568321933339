import { createSlice } from '@reduxjs/toolkit'
import { IAutomationForm } from '@/types'

export const defaultState: IAutomationForm = {
  edit: false,
  name: undefined,
  ruleId: undefined,
  locationId: undefined,
  automationId: undefined,
  meta: {
    start_2: '0',
    startUnit_2: 'days',
    end_2: '0',
    endUnit_2: 'days',
    deal_status: [],
  },
  message: undefined,
  start: '0',
  startUnit: 'days',
  end: '0',
  endUnit: 'days',

  image: null,
  media: null,
  imageError: null,
  frequencies: [],
  timezone: '',
  isReviewInvite: false,
  assignedUsers: [],
}

const defaultFilter = {
  location: '',
  type: '',
  startDate: undefined,
  endDate: undefined,
}
const initialState: {
  filter: {
    location: string
    type: string
    startDate?: string
    endDate?: string
  }
  form: IAutomationForm
  formErrors: Record<string, string>
  automationFormType?: string
} = {
  filter: defaultFilter,
  form: defaultState,
  formErrors: {},
  automationFormType: undefined,
}

const organizationAutomationSlice = createSlice({
  name: 'organizationAutomation',
  initialState,
  reducers: {
    clearFilter: state => {
      state.filter = defaultFilter
    },
    onFilterChange: (state, action) => {
      state.filter = { ...state.filter, ...action.payload }
    },
    onFormChange: (state, action) => {
      state.form = { ...state.form, ...action.payload }
    },
    setFormErrors: (state, action) => {
      state.formErrors = action
    },
    resetForm: state => {
      state.form = defaultState
    },
    onFormTypeChange: (state, action) => {
      state.automationFormType = action.payload
    },
  },
})

export const {
  clearFilter,
  onFilterChange,
  onFormChange,
  setFormErrors,
  resetForm,
  onFormTypeChange,
} = organizationAutomationSlice.actions
export default organizationAutomationSlice.reducer
