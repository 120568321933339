import React, { useCallback, useState } from 'react'

import { Button, Typography } from '@/components'
import { useRevealPasswordMutation } from '@/api/services/settings'

interface IPasswordRevealProps {
  locationId: string
}

export const PasswordReveal = ({ locationId }: IPasswordRevealProps) => {
  const [password, setPassword] = useState<string>('')
  const [revealPassword] = useRevealPasswordMutation()
  // const { mutateAsync: revealPassword } = useRevealPassword(locationId)

  const onRevealPassword = useCallback(async () => {
    try {
      const res = await revealPassword({ id: locationId }).unwrap()
      setPassword(res.password)
    } catch (err) {
      alert('Failed to reveal password')
    }
  }, [locationId, revealPassword])

  return (
    <div className="flex items-center gap-3 my-2">
      <Button size="small" status="secondary" onClick={onRevealPassword}>
        Reveal Password
      </Button>
      <div className="ml-4">
        {password && (
          <Typography variant="small">
            Password is: <b>{password}</b>
          </Typography>
        )}
      </div>
    </div>
  )
}
