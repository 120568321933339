import React from 'react'
import moment from 'moment-timezone'
import { DATE_FORMAT, DATE_FORMAT_FULL } from '@/constants'

import { ColumnDef } from '@tanstack/react-table'

import OrganizationLink from '@/sections/OrganizationLink'
import {
  IImportJob,
  IImportJobStatus,
  IInventory,
  IInventoryEvent,
} from '@/types/inventory'
import { capitalize, renderUserFullName, truncateString } from '@/utils'
import { Tag, Typography } from '@/components'
import { TagStatus } from '@/types'

export const inventoryTableColumns: ColumnDef<IInventory, any>[] = [
  {
    id: 'organization',
    accessorKey: 'organization',
    header: () => <span>Organization</span>,
    cell: info => {
      const data = info.row.original.organization
      return <OrganizationLink id={data._id} name={data.name} />
    },
  },
  {
    id: 'vendor',
    accessorKey: 'vendor',
    header: () => <span>Vendor</span>,
    cell: info => info.getValue(),
  },
  {
    id: 'dealer_id',
    accessorKey: 'dealer_id',
    header: () => <span>Dealer</span>,
    cell: info => info.getValue(),
  },
  {
    id: 'status',
    accessorKey: 'status',
    header: () => <span>Status</span>,
  },
  {
    id: 'lastSyncedAt',
    accessorKey: 'lastSyncedAt',
    header: () => <span>Last Synced</span>,
    cell: info => moment(info.getValue()).format(DATE_FORMAT_FULL),
  },
  {
    id: 'modifiedAt',
    accessorKey: 'modifiedAt',
    header: () => <span>Last file received</span>,
    cell: info => moment(info.getValue()).format(DATE_FORMAT_FULL),
  },
  {
    id: 'id',
    accessorKey: 'id',
    header: () => '',
  },
]

const statusMap: Record<IImportJobStatus, TagStatus> = {
  success: 'success',
  failed: 'error',
  pending: 'warning',
}

export const importJobsTableColumns: ColumnDef<IImportJob, any>[] = [
  {
    accessorKey: 'status',
    header: () => <span>Status</span>,
    cell: ({ getValue }) => {
      const status = getValue() as IImportJobStatus
      return (
        <Tag status={statusMap[status]} variant="light">
          {capitalize(getValue())}
        </Tag>
      )
    },
  },
  {
    accessorKey: 'message',
    header: () => <span>Message</span>,
    cell: info => {
      const value = info.getValue()
      if (!value) {
        return '-'
      }
      return <span>{truncateString(value, 150)}</span>
    },
  },
  {
    accessorKey: 'created_at',
    header: () => <span>Created At</span>,
    cell: info => moment(info.getValue()).format(DATE_FORMAT),
  },
  {
    accessorKey: 'updated_at',
    header: () => <span>Updated At</span>,
    cell: info => moment(info.getValue()).format(DATE_FORMAT),
  },
]

export const auditTrailTableColumns: ColumnDef<IInventoryEvent, any>[] = [
  {
    accessorKey: 'user',
    header: () => <span>User</span>,
    cell: ({ getValue }) => {
      const user = getValue()
      return (
        <div className="flex flex-col gap-1 h-full">
          <Typography
            variant="regular"
            weight="medium"
            className="text-black-100"
          >
            {renderUserFullName(user.firstName, user.lastName)}
          </Typography>
          <Typography variant="small" className="text-black-80">
            {user.email}
          </Typography>
        </div>
      )
    },
  },
  {
    accessorKey: 'payload',
    header: () => <span>Data</span>,
    cell: info => {
      const value = info.getValue()

      const data = {
        dealer_id: value.dealer_id,
        vendor: value.vendor,
        status: value.status,
        organization: value.organization.name,
      }
      if (!value) {
        return '-'
      }
      return (
        <pre className="bg-bg-gray p-4 rounded-xl text-black-80 font-normal text-sm leading-[18px] w-full">
          {JSON.stringify(data, undefined, 2)}
        </pre>
      )
    },
  },
  {
    accessorKey: 'createdAt',
    header: () => <span>Updated At</span>,
    cell: info => moment(info.getValue()).format(DATE_FORMAT),
  },
]

export const syncFailureColumns: ColumnDef<any, any>[] = [
  {
    accessorKey: 'status',
    header: () => <span>Status</span>,
    cell: ({ getValue }) => {
      return (
        <Tag status="error" variant="light">
          {capitalize(getValue())}
        </Tag>
      )
    },
  },
  {
    accessorKey: 'message',
    header: () => <span>Message</span>,
    cell: info => {
      const value = info.getValue()
      if (!value) {
        return '-'
      }
      return <span>{truncateString(value, 150)}</span>
    },
  },
  {
    accessorKey: 'created_at',
    header: () => <span>Created At</span>,
    cell: info => moment(info.getValue()).format(DATE_FORMAT),
  },
  {
    accessorKey: 'updated_at',
    header: () => <span>Updated At</span>,
    cell: info => moment(info.getValue()).format(DATE_FORMAT),
  },
]
