import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import Header from '@/layout/Header'
import { Unauthorized } from '@/pages/Unauthorized'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { notPermitted } from '@/store/slices/user.slice'
import { fetchNotifications } from '@/store/slices/notification.slice'
import { Notifications } from '@/layout/Notifications'

const Layout = () => {
  const dispatch = useAppDispatch()

  const path = window.location.pathname
  const isNotPermitted = useAppSelector(state => notPermitted(state, path))
  const { isLoading, isLoggedIn } = useAppSelector(state => state.user)

  useEffect(() => {
    dispatch(fetchNotifications())
  }, [dispatch])
  if (isNotPermitted && !isLoading && isLoggedIn) {
    return <Unauthorized />
  }
  return (
    <div className="flex bg-white-100 w-full min-h-screen">
      <main className="flex flex-col gap-1 w-full grow">
        <Header />
        <Notifications />
        <div className="px-8 py-6 flex flex-col grow z-0">
          <Outlet />
        </div>
      </main>
    </div>
  )
}

export default Layout
