import { AxiosResponse } from 'axios'

export interface ICheckAuthResponse {
  _id: string
  isAdmin: boolean
  isSuperAdmin: boolean
  _organization_id: string
}

export interface IUserSettingsResponse {
  permissions: permissionValueType[]
  organizations: { _id: string; name: string }[]
}

export interface IUserState extends ICheckAuthResponse, IUserSettingsResponse {
  isLoading: boolean
  error?: string
  isLoggedIn: boolean
}

export enum permissionType {
  ADMIN_READ = 'admin:read',
  ADMIN_WRITE = 'admin:write',
  AUTOMATIONS_READ = 'automations:read',
  AUTOMATIONS_WRITE = 'automations:write',
  WORKFLOWS_READ = 'workflows:read',
  WORKFLOWS_WRITE = 'workflows:write',
  ONBOARDING_READ = 'onboarding:read',
  ONBOARDING_WRITE = 'onboarding:write',
  SUPERHUMAN_READ = 'superhuman:read',
  SUPERHUMAN_WRITE = 'superhuman:write',
  INVENTORY_ADMIN = 'inventory:admin',
}

export enum Resource {
  superhuman = 'superhuman',
  admin = 'admin',
  automations = 'automations',
  workflows = 'workflows',
  onboarding = 'onboarding',
  inventory = 'inventory',
}

export enum Level {
  read = 'read',
  write = 'write',
  admin = 'admin',
}

export type Organization = string

export type ParsedPermission = {
  resource: Resource
  level: Level
  organizations: string[]
}

export interface ParsedPermissionWidthActions extends ParsedPermission {
  edit: boolean
  delete: boolean
  add: boolean
  index: number
}

export type permissionValueType = `${permissionType}`

export type UserWithSettingsResponse = {
  user: AxiosResponse<ICheckAuthResponse>
  settings: AxiosResponse<IUserSettingsResponse>
}
export type PermissionValueTypeWithOrg = `${permissionType}/[${Organization}]`

export interface IUser {
  _id: string
  lastName: string
  firstName: string
}

export interface IUserWithFullName {
  _id: string
  fullName: string
}

export interface IListUser {
  name: string
  email: string
  hasAccess: boolean
  permissions: PermissionValueTypeWithOrg[]
}

export interface IListUserWithActions extends IListUser {
  isEdit?: boolean
  isDelete?: boolean
}

export interface IAccessControlState {
  users: IListUser[]
  editUser: IListUser | null
  isGrantAccess: boolean
}

export interface ISetAccessControlPayload {
  email: string
  hasAccess: boolean
  permissions: PermissionValueTypeWithOrg[]
}
