import React from 'react'
import PageHeading from '@/sections/PageHeading'
import { useAppDispatch, useAppSelector } from '@/store/hooks'

import AutomationFilter from './Filter'
import { DataTable } from '@/components'
import {
  onFilterChange,
  setDetails,
} from '@/store/slices/pages/automation.slice'
import { automationTableColumns } from '@/pages/Automations/config'
import Stats from './Stats'
import Pagination from '@/components/Pagination'
import { useParams } from 'react-router-dom'
import { useGetAllTaskQuery } from '@/api/services/organization/organization-automation'
import AutomationTableActions from '@/pages/Automations/TableActions'
import { ITask } from '@/types'
import AutomationDetails from '@/pages/Automations/Details'
import { ColumnDef } from '@tanstack/react-table'

const Automations = () => {
  const { id } = useParams()
  const { filter } = useAppSelector(state => state.automation)
  const {
    data: { data: automations = [], pageMeta: { totalItems = 0 } = {} } = {},
    isFetching: loading,
    refetch,
  } = useGetAllTaskQuery(filter)
  const dispatch = useAppDispatch()

  const onPageChange = (page: number) => {
    dispatch(
      onFilterChange({
        page,
      }),
    )
  }

  const onStatusClick = (row: ITask) => {
    if (row.status === 'pending') {
      return
    }
    dispatch(setDetails(row))
  }
  return (
    <div className="w-full flex flex-col gap-6">
      <PageHeading
        title="Automations"
        extra={!id && <div className="w-full"></div>}
      />
      <Stats />
      <div className="w-full flex flex-col gap-6">
        <AutomationFilter onRefresh={refetch} />

        <DataTable
          loading={loading}
          columns={
            automationTableColumns.map((col: ColumnDef<ITask, any>) => {
              if (col.id === 'status') {
                return {
                  ...col,
                  cell: info => (
                    <AutomationTableActions
                      task={info.row.original as ITask}
                      afterAction={() => refetch()}
                      onClick={() => onStatusClick(info.row.original)}
                    />
                  ),
                }
              }
              return col
            }) as ColumnDef<ITask, any>[]
          }
          data={automations}
        />
        <Pagination
          currentPage={filter.page}
          totalCount={totalItems}
          onPageChange={onPageChange}
        />
      </div>
      <AutomationDetails />
    </div>
  )
}

export default Automations
