import React from 'react'
import { DataTable, Drawer, DrawerSize } from '@/components'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { defaultPageMeta } from '@/constants'
import { useGetInventoryEventsQuery } from '@/api/services/inventory'
import { auditTrailTableColumns } from '@/pages/Inventory/config'
import Pagination from '@/components/Pagination'
import { onEventIdChange } from '@/store/slices/pages/inventory.slice'

const AuditTrailModal = () => {
  const dispatch = useAppDispatch()
  const [pageMeta, setPageMeta] = React.useState(defaultPageMeta)
  const { eventId } = useAppSelector(state => state.inventory)
  const {
    data: { data: auditTrails = [], pageMeta: { totalItems = 0 } = {} } = {},
    isFetching,
  } = useGetInventoryEventsQuery(
    { id: eventId, ...pageMeta },
    {
      skip: !eventId,
    },
  )
  return (
    <Drawer
      open={!!eventId}
      onClose={() => {
        dispatch(onEventIdChange(undefined))
        setPageMeta(defaultPageMeta)
      }}
      title="Audit trail"
      size={DrawerSize.XL}
    >
      <DataTable
        data={auditTrails}
        columns={auditTrailTableColumns}
        loading={isFetching}
      />
      <Pagination
        currentPage={pageMeta.page}
        totalCount={totalItems}
        onPageChange={val => setPageMeta(prev => ({ ...prev, page: val }))}
      />
    </Drawer>
  )
}

export default AuditTrailModal
