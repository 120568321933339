import './App.scss'
import { BrowserRouter as Router } from 'react-router-dom'
import Navigation from '@/navigation'
import { Toaster } from 'react-hot-toast'
import { ErrorBoundary } from 'react-error-boundary'

function App() {
  const onError = (error: Error) => {
    console.error(error)
  }
  return (
    <ErrorBoundary onError={onError} fallback={<div />}>
      <Router>
        <Navigation />
        <Toaster
          position="bottom-left"
          toastOptions={{
            className: 'toast',
            duration: 5000,
            success: {
              className: '!bg-green-100  !text-white-100',
            },
            error: {
              className: '!bg-red-100  !text-white-100',
            },
          }}
        />
      </Router>
    </ErrorBoundary>
  )
}

export default App
