import { createSlice } from '@reduxjs/toolkit'
import { defaultPageMeta } from '@/constants'

import { IAnalyticsState } from '@/types/analytics'

const initialState: IAnalyticsState = {
  loading: false,
  error: undefined,
  filter: {
    ...defaultPageMeta,
    startDate: undefined,
    endDate: undefined,
    organization: undefined,
  },
}

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    clearFilter: state => {
      state.filter = initialState.filter
    },
    onFilterChange: (state, action) => {
      state.filter = { ...state.filter, ...action.payload }
    },
  },
})

export const { clearFilter, onFilterChange } = analyticsSlice.actions
export default analyticsSlice.reducer
