import { createSlice } from '@reduxjs/toolkit'
import { defaultPageMeta } from '@/constants'
import moment from 'moment-timezone'

import { ILeadState } from '@/types'

const initialState: ILeadState = {
  loading: false,
  error: undefined,
  filter: {
    ...defaultPageMeta,
    date: moment().subtract(7, 'd').toString(),
    organization: '',
    crm: '',
    phoneNumber: '',
    name: '',
  },
}

const leadSlice = createSlice({
  name: 'lead',
  initialState,
  reducers: {
    clearFilter: state => {
      state.filter = initialState.filter
    },
    onFilterChange: (state, action) => {
      state.filter = { ...state.filter, ...action.payload }
    },
  },
})

export const { clearFilter, onFilterChange } = leadSlice.actions
export default leadSlice.reducer
