import apiService from '@/api'
import { endpoints } from '@/api/constants'
import {
  ICheckAuthResponse,
  IUserSettingsResponse,
  UserWithSettingsResponse,
} from '@/types/user'

export const getUserWithSettings =
  async (): Promise<UserWithSettingsResponse> => {
    // Using Promise.all to handle multiple promises
    const [userResponse, settingsResponse] = await Promise.all([
      apiService.post<ICheckAuthResponse>(endpoints.checkAuthStatus),
      apiService.get<IUserSettingsResponse>(endpoints.userSettings),
    ])

    // Return the combined response
    return { user: userResponse, settings: settingsResponse }
  }
