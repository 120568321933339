import React, { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { useParams } from 'react-router-dom'
import SelectLocation from '@/pages/V2Dashboard/WorkflowForm/Steps/SelectLocation'
import SelectOptions from '@/pages/V2Dashboard/WorkflowForm/Steps/SelectOptions'
import SaveState from '@/pages/V2Dashboard/WorkflowForm/Steps/Save'
import { Button, LoadingSkeleton, Typography } from '@/components'
import {
  onFormChange,
  onLoadingChange,
  onStepChange,
  reset,
} from '@/store/slices/workflow.slice'
import { V2_DASHBOARD } from '@/constants'
import {
  useGetOrganizationWorkflowLocationsQuery,
  useGetSingleWorkflowQuery,
  useGetWorkflowTemplatesQuery,
} from '@/api/services/workflow'
import { defaultCombinator } from '@/constants/automations'
import { ReactComponent as ArrowLeft } from '@/assets/icons/Arrow Left.svg'
import { ITemplate, IWorkflowLocation } from '@/types'
import { generatePath } from '@/utils'

const steps: Record<
  number,
  React.FC<{
    templates: ITemplate[]
    locations: IWorkflowLocation[]
    goBack: (val: number) => void
  }>
> = {
  1: SelectLocation,
  2: SelectOptions,
  3: SaveState,
}

const WorkflowForm = () => {
  const {
    currentStep,
    form: { name, location },
    isLoading,
  } = useAppSelector(state => state.workflow)

  const dispatch = useAppDispatch()
  const { id = '', workFlowId } = useParams()

  useEffect(() => {
    if (workFlowId === 'new') {
      dispatch(onLoadingChange(false))
    }
  }, [dispatch, workFlowId])

  const { data: workflow } = useGetSingleWorkflowQuery(
    { id: workFlowId },
    {
      skip: workFlowId === 'new',
    },
  )
  const { data: { templates = [] } = {} } = useGetWorkflowTemplatesQuery(
    {
      id: location,
    },
    {
      skip: !location,
    },
  )
  const { data: { locations = [] } = {} } =
    useGetOrganizationWorkflowLocationsQuery({
      id,
    })

  useEffect(() => {
    if (workflow) {
      dispatch(
        onFormChange({
          name: workflow?.name,
          message: workflow?.message,
          actions: workflow?.actions,
          location: workflow?.location,
          sendAfter: workflow?.sendAfter,
          combinator: workflow?.combinator ?? defaultCombinator,
          workflowTemplate: workflow?.workflowTemplate,
        }),
      )
    }
  }, [dispatch, workflow])

  useEffect(() => {
    if (workflow) {
      const template = templates?.find(
        el => el.id === workflow?.workflowTemplate,
      )
      if (template) {
        dispatch(
          onFormChange({
            template,
          }),
        )
        dispatch(onStepChange(2))
        dispatch(onLoadingChange(false))
      }
    }
  }, [dispatch, templates, workflow])

  const CurrentStep = steps[currentStep]
  const goBack = useCallback(() => {
    dispatch(onStepChange(currentStep - 1))
  }, [currentStep, dispatch])
  return (
    <div className="flex flex-col gap-8 w-full grow">
      <div className="flex flex-col gap-3 w-full">
        <div className="flex items-center justify-between">
          <Typography
            variant="H3"
            weight="semibold"
            className="flex gap-2 items-center"
          >
            {name ? name : 'Add Workflow'}
          </Typography>
          <div>
            <Button
              status="secondary"
              size="small"
              as="Link"
              to={generatePath(V2_DASHBOARD, { id })}
              onClick={() => dispatch(reset())}
            >
              <ArrowLeft className="w-4 h-4 mr-2" />
              Workflows List
            </Button>
          </div>
        </div>
        <div className="w-full h-px bg-bg-gray rounded-xl relative"></div>
      </div>
      {isLoading ? (
        <LoadingSkeleton width="100%" height="50vh" />
      ) : (
        <div className="w-full pb-5 grow flex">
          <CurrentStep
            templates={templates}
            locations={locations}
            goBack={goBack}
          />
        </div>
      )}
    </div>
  )
}

export default WorkflowForm
