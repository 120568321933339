import { ColumnDef } from '@tanstack/react-table'
import { ISales, ISalesRow, TAppTheme } from '@/types'
import { Tag, Typography } from '@/components'
import { ReactComponent as ArrowDown } from '@/assets/icons/Arrow Down.svg'
import React from 'react'
import clsx from 'clsx'
import moment from 'moment-timezone'
import { capitalize } from '@/utils'

const darkModeTagStyles = {
  new: 'bg-[#48AF80] text-white-100',
  used: 'bg-[#3F8CFF] text-white-100',
}
export const salesTableColumns = (
  theme?: TAppTheme,
  onClick?: (row: ISales) => void,
): ColumnDef<ISales, any>[] => {
  return [
    {
      id: 'subRows',
      maxSize: 0,
      header: () => '',
      cell: ({ row }) => {
        const isExpanded = row.getIsExpanded()
        const canExpand = row.getCanExpand()
        if (!canExpand) return null
        return (
          <ArrowDown
            className={clsx('w-6 h-6 cursor-pointer', {
              'rotate-180': isExpanded,
            })}
            onClick={() => row.toggleExpanded()}
          />
        )
      },
    },
    {
      accessorKey: 'name',
      header: () => <span>Name</span>,
      cell: ({ getValue }) => capitalize(getValue()),
    },
    {
      accessorKey: 'description',
      header: () => <span>Description</span>,
    },
    {
      accessorKey: 'leads',
      header: () => <span>Leads</span>,
      cell: ({ getValue }) => (
        <Typography variant="regular" weight="medium">
          {getValue()}
        </Typography>
      ),
    },
    {
      accessorKey: 'engagedCustomers',
      header: () => <span>Engaged Customers</span>,
      cell: ({ getValue }) => (
        <Typography variant="regular" weight="medium">
          {getValue()}
        </Typography>
      ),
    },
    {
      accessorKey: 'sales',
      header: () => <span>Sales</span>,
      cell: ({ getValue, row }) => (
        <Typography
          variant="regular"
          weight="medium"
          className={clsx('text-blue-100 cursor-pointer', {
            underline: theme === 'dark',
          })}
          onClick={() => onClick?.(row.original)}
        >
          {getValue()}
        </Typography>
      ),
    },
  ]
}

export const salesRowTableColumns = (
  theme?: TAppTheme,
): ColumnDef<ISalesRow, any>[] => {
  const isDark = theme === 'dark'
  return [
    {
      accessorKey: 'firstName',
      header: () => <span>Name</span>,
      cell: ({ row }) => {
        const { firstName, lastName } = row.original
        return `${firstName} ${lastName}`
      },
    },
    {
      accessorKey: 'mainPhoneNumber',
      header: () => <span>Phone</span>,
    },
    {
      accessorKey: 'vehicleMake',
      header: () => <span>Vehicle</span>,
      cell: ({ row }) => {
        const { vehicleMake, vehicleModel, vehicleYear } = row.original
        return `${vehicleYear} ${vehicleMake} ${vehicleModel}`
      },
    },
    {
      accessorKey: 'vehicleVIN',
      header: () => <span>VIN</span>,
    },
    {
      maxSize: 0,
      accessorKey: 'vehicleType',
      header: () => <span>New/Used</span>,
      cell: ({ getValue }) => {
        const value = getValue()
        const isNew = value === 'new'
        return !isDark ? (
          <Tag variant="light" status={isNew ? 'success' : 'info'}>
            {capitalize(value)}
          </Tag>
        ) : (
          <Typography
            variant="small"
            weight="medium"
            className={clsx(
              'rounded-20 py-1 px-3',
              isNew ? darkModeTagStyles.new : darkModeTagStyles.used,
            )}
          >
            {capitalize(value)}
          </Typography>
        )
      },
    },
    {
      maxSize: 0,
      accessorKey: 'purchasePrice',
      header: () => <span>Price</span>,
      cell: ({ getValue }) => `$${getValue()}`,
    },
    {
      accessorKey: 'saleDate',
      header: () => <span>Sale Date</span>,
      cell: ({ getValue }) => moment(getValue()).format('YYYY-MM-DD HH:mm:ss'),
    },
  ]
}
