import React from 'react'
import ComingSoon from '@/sections/ComingSoon'

import { ReactComponent as DocumentIcon } from '@/assets/icons/Document.svg'
import { documentationCardList } from '@/pages/SuccessPortal/Documentation/config'
import Tab from '@/components/Tab'
import { Typography } from '@/components'

const Documentation = () => {
  return (
    <div className="flex justify-center gap-4">
      {documentationCardList.map(({ title, image }) => (
        <div
          key={title}
          className="overflow-hidden rounded-2xl border border-bg-stroke max-w-md w-full"
        >
          <img className="w-full" src={image} alt={title} />
          <div className="p-4 flex items-center gap-2">
            <DocumentIcon />
            <Typography variant="regular" weight="medium">
              {title}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Documentation
