import React, { ReactNode, useCallback, useEffect } from 'react'
import {
  useCheckV2AccessQuery,
  useGetSingleOrganizationsQuery,
} from '@/api/services/organization'
import { useGetStatsQuery } from '@/api/services/statistic'
import { useParams } from 'react-router-dom'
import { Button, DatePicker, Select, Tag, Typography } from '@/components'
import Card from '@/components/Card'
import { dateFilterOptions, V2_DASHBOARD } from '@/constants'
import { generatePath, getDatesByFilterType } from '@/utils'
import SubmenuSection from '@/sections/SubmenuSection'
import { IDateFilterType } from '@/types'
import { DateRange } from 'react-day-picker'

import { ReactComponent as CalendarIcon } from '@/assets/icons/Calendar.svg'
import {
  onFilterChange,
  onFormTypeChange,
} from '@/store/slices/pages/organization/organization-automations.slice'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import AutomationForm from '@/pages/Organization/AutomationForm'

const SectionCard = ({
  title,
  value,
}: {
  title: string
  value?: string | number
}) => {
  return (
    <Card className="!p-5">
      <Typography variant="regular" weight="medium">
        {title}
      </Typography>
      <Typography variant="H2" weight="semibold">
        {value}
      </Typography>
    </Card>
  )
}

const DateSelect = () => {
  const dispatch = useAppDispatch()
  const [selectedDate, setSelectedDate] =
    React.useState<IDateFilterType>('today')

  const [selectedCustomDate, setSelectedCustomDate] = React.useState<DateRange>(
    {
      from: undefined,
      to: undefined,
    },
  )
  const onSelectChange = useCallback((value: IDateFilterType) => {
    setSelectedDate(value)
  }, [])

  const onDateChange = useCallback((dates: DateRange | undefined) => {
    if (!dates) return
    setSelectedCustomDate(dates)
  }, [])

  useEffect(() => {
    if (selectedDate !== 'custom') {
      const dates = getDatesByFilterType(selectedDate)
      dispatch(
        onFilterChange({
          ...dates,
        }),
      )
    } else {
      dispatch(
        onFilterChange({
          startDate: selectedCustomDate?.from?.toISOString(),
          endDate: selectedCustomDate?.to?.toISOString(),
        }),
      )
    }
  }, [dispatch, selectedCustomDate?.from, selectedCustomDate?.to, selectedDate])

  return (
    <div className="flex flex-col gap-2">
      <Select
        options={dateFilterOptions}
        onChange={newValue => {
          if (newValue) {
            onSelectChange(newValue._id)
          }
        }}
        value={dateFilterOptions.find(option => option._id === selectedDate)}
      />
      {selectedDate === 'custom' && (
        <DatePicker
          mode="range"
          value={selectedCustomDate}
          onChange={onDateChange}
          placeholder="Select dates"
          CustomIcon={CalendarIcon}
        />
      )}
    </div>
  )
}
export const WrapperAside = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const {
    filter: { startDate, endDate },
  } = useAppSelector(state => state.organizationAutomations)
  const { data: organization } = useGetSingleOrganizationsQuery({ id })
  const { data: statistic, isLoading } = useGetStatsQuery({
    organization: id,
    startDate,
    endDate,
  })
  const { data: hasAccessV2 } = useCheckV2AccessQuery({ id })

  const onMenuClick = useCallback(
    (key?: string) => {
      dispatch(onFormTypeChange('create'))
    },
    [dispatch],
  )

  return (
    <div className="grid grid-cols-[256px_1fr] gap-6 grow">
      <div>
        <div>
          <Typography variant="H2" weight="semibold">
            {organization?.name}
          </Typography>
          <div className="flex flex-col gap-1 mt-2">
            <Typography
              variant="regular"
              weight="normal"
              className="text-black-80"
            >
              Automations status:
            </Typography>
            {organization?.credentials.length ? (
              <Tag status="success" variant="light">
                Completed
              </Tag>
            ) : (
              <Tag status="error" variant="light">
                Failed
              </Tag>
            )}
          </div>
        </div>
        {hasAccessV2 && (
          <Button
            status="secondary"
            size="small"
            className="mt-8"
            as="Link"
            to={generatePath(V2_DASHBOARD, { id })}
          >
            Access V2 Dashboard
          </Button>
        )}
        <div className="mt-8 flex flex-col gap-4">
          <DateSelect />
          <SectionCard
            title="Tasks Completed"
            value={statistic?.automation.completed}
          />
          <SectionCard
            title="Broadcast Success Rate"
            value={`${statistic?.automation.successRate}%`}
          />
          <SectionCard
            title="Appointment Confirmations Success Rate"
            value={`${statistic?.appointment.successRate}%`}
          />
        </div>
      </div>
      <div className="flex-grow flex flex-col gap-6 pt-12">
        <SubmenuSection onClick={onMenuClick} />
        {children}
      </div>
      <AutomationForm />
    </div>
  )
}
