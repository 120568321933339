import React, { useEffect, useState } from 'react'

import { uuid as uuidGenerator } from '@/utils'

import { Input } from '@/components'

interface IDealerSocketUUIDProps {
  value: string
  label?: string
  onChange?: (_value: string) => void
}
const DealerSocketUUID: React.FC<IDealerSocketUUIDProps> = ({
  value,
  label,
  onChange,
}) => {
  const [uuid, setUuid] = useState(value)

  useEffect(() => {
    if (value) setUuid(value)
    else {
      const id = uuidGenerator()
      setUuid(id)
      onChange?.(id)
    }
  }, [onChange, value])

  return <Input label={label} className="block w-full" value={uuid} disabled />
}

export default DealerSocketUUID
