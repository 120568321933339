import React, { ReactNode } from 'react'
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import { ReactComponent as CloseIcon } from '@/assets/icons/Close.svg'
import clsx from 'clsx'
import { Typography } from '@/components'
export interface ModalProps {
  open: boolean
  onClose?: () => void
  title?: string | ReactNode
  description?: string | ReactNode
  headerClass?: string
  contentClass?: string
  children?: ReactNode
  footer?: ReactNode
  showClose?: boolean
}
const Modal = ({
  open,
  onClose,
  children,
  title,
  description,
  headerClass,
  contentClass,
  footer,
  showClose = true,
}: ModalProps) => {
  return (
    <Dialog open={open} onClose={() => onClose?.()} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-black-100 bg-opacity-50 transition-opacity duration-300 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel
          transition
          className="relative bg-white-100 pointer-events-auto max-w-xl w-full rounded-2xl"
        >
          <div className="flex h-full flex-col gap-6 overflow-y-scroll p-8">
            {showClose && (
              <div className="absolute top-8 right-8 z-20">
                <CloseIcon
                  onClick={onClose}
                  className="relative cursor-pointer text-black-50 hover:text-black-80 transition-all"
                />
              </div>
            )}
            <div className={clsx(headerClass)}>
              <div className="flex flex-col">
                <Typography variant="H3" weight="semibold" className="pr-8">
                  {title}
                </Typography>

                {description && (
                  <Typography variant="small" className="text-black-50 mt-1">
                    {description}
                  </Typography>
                )}
              </div>
            </div>
            <div
              className={clsx(
                'relative flex-1 overflow-auto max-h-[80vh] text-base',
                contentClass,
              )}
            >
              {children}
            </div>
            {footer && <div className="flex gap-2 w-full">{footer}</div>}
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  )
}

export default Modal
