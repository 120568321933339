import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { clearFilter, onFilterChange } from '@/store/slices/pages/lead.slice'
import { Button, Select, Input, Option } from '@/components'
import DatePicker from '@/components/DatePicker'

import { ReactComponent as SearchIcon } from '@/assets/icons/Search.svg'
import { useGetCrmsQuery } from '@/api/services/crm'

const AutomationFilter = () => {
  const dispatch = useAppDispatch()

  const { data: { data: crms = [] } = {} } = useGetCrmsQuery(undefined)

  const {
    filter: { crm, date, phoneNumber, name },
  } = useAppSelector(state => state.leads)

  const onSelectChange = useCallback(
    (field: string, value: Option | null) => {
      dispatch(
        onFilterChange({
          [field]: (!Array.isArray(value) && value?._id) || '',
          page: 1,
        }),
      )
    },
    [dispatch],
  )

  const reset = useCallback(() => {
    dispatch(clearFilter())
  }, [dispatch])

  const onDateChange = useCallback(
    (date: Date | undefined) => {
      dispatch(
        onFilterChange({
          date,
          page: 1,
        }),
      )
    },
    [dispatch],
  )

  const handleInputChange = useCallback(
    (field: 'name' | 'phoneNumber') =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(onFilterChange({ [field]: e.target.value, page: 1 }))
      },
    [dispatch],
  )

  return (
    <div className="flex items-end">
      <div className="grid grid-cols-6 gap-3 w-full">
        <DatePicker
          label="Start Date"
          value={new Date(date)}
          onChange={onDateChange}
          placeholder="Select a date"
        />
        <Select
          label="CRM"
          placeholder="CRMs"
          options={crms}
          value={crms.find(_crm => _crm._id === crm) || null}
          onChange={val => onSelectChange('crm', val)}
        />
        <Input
          label="Phone"
          IconLeft={SearchIcon}
          placeholder="Search"
          value={phoneNumber}
          onChange={handleInputChange('phoneNumber')}
          className="mt-auto"
        />
        <Input
          label="Name"
          IconLeft={SearchIcon}
          placeholder="Search"
          value={name}
          onChange={handleInputChange('name')}
          className="mt-auto"
        />
      </div>
      <Button size="small" className="ml-auto" status="text" onClick={reset}>
        Clear All
      </Button>
    </div>
  )
}

export default AutomationFilter
