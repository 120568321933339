import React from 'react'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import {
  addUserToList,
  jobAvailableUsers,
  jobUsersToTag,
  onTagMessageChange,
  tagUser,
  untagUser,
} from '@/store/slices/superhuman.slice'
import { Button, Typography, Tag } from '@/components'
import Modal from '@/components/Modal'

import { ReactComponent as CloseIcon } from '@/assets/icons/Close.svg'
import TextArea from '@/components/TextArea'

const TagActionModal = () => {
  const dispatch = useAppDispatch()
  const [open, setOpen] = React.useState(false)
  const availableUsers = useAppSelector(jobAvailableUsers)
  const usersToTag = useAppSelector(jobUsersToTag)
  const { tagMessage } = useAppSelector(state => state.superhuman)
  return (
    <div>
      <Button
        className="w-full"
        size="small"
        status="primary"
        onClick={() => setOpen(true)}
      >
        Add/Remove users to tag
      </Button>
      <Modal open={open} onClose={() => setOpen(false)} title="Users To Tag">
        <div className="flex flex-col gap-6">
          <div>
            <Typography variant="regular" weight="semibold" className="mb-2">
              Tagged users
            </Typography>
            <div className="flex flex-wrap gap-2">
              {usersToTag.map(({ fullName, _id }) => (
                <Tag
                  IconRight={CloseIcon}
                  status="success"
                  key={_id}
                  onIconClick={() => {
                    dispatch(untagUser(_id))
                  }}
                >
                  {fullName}
                </Tag>
              ))}
            </div>
          </div>
          <div>
            <Typography variant="regular" weight="semibold" className="mb-2">
              Available users
            </Typography>
            <div className="flex flex-wrap gap-2">
              {availableUsers
                .filter(el => !usersToTag.find(user => user._id! === el._id))
                .map(({ fullName, _id }) => (
                  <Tag
                    status="default"
                    key={_id}
                    onClick={() => {
                      dispatch(addUserToList(_id))
                      dispatch(tagUser(_id))
                    }}
                  >
                    {fullName}
                  </Tag>
                ))}
            </div>
          </div>
          <div className="border border-gray-100"></div>
          <TextArea
            label="Message"
            placeholder="A tagging message is not required"
            value={tagMessage}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              dispatch(onTagMessageChange(e.target.value))
            }}
          />
        </div>
      </Modal>
    </div>
  )
}

export default TagActionModal
