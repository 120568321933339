import { capitalize } from '@/utils'
import { TAppointmentStatus, TDateFields } from '@/types'

export const appointmentStatusFilter = Object.values(TAppointmentStatus).map(
  key => ({
    name: capitalize(key),
    _id: key,
  }),
)

export const appointmentSortByFilter = Object.values(TDateFields).map(key => ({
  name: capitalize(key),
  _id: key,
}))
