import React, { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { PageLoadingWrapper } from '@/components'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { getUser } from '@/store/slices/user.slice'
import { DASHBOARD, SIGN_IN } from '@/constants'

const AuthWrapper = () => {
  const { isLoading, isLoggedIn } = useAppSelector(state => state.user)
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getUser())
  }, [dispatch])
  if (isLoading) {
    return <PageLoadingWrapper isLoading />
  }

  if (!isLoading && !isLoggedIn) {
    return <Navigate to={SIGN_IN} />
  }
  return <Outlet />
}

export default AuthWrapper
