import React, { useEffect } from 'react'
import { useGetUsersListQuery } from '@/api/services/acsess'
import { DataTable } from '@/components'
import { accessControlColumns } from '@/pages/AccessControl/config'
import { IListUserWithActions } from '@/types'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { accessList } from '@/store/slices/user.slice'
import PageHeading from '@/sections/PageHeading'
import { setUsers } from '@/store/slices/access.slice'
import AccessControlTableActions from '@/pages/AccessControl/TableActions'
import EditUser from '@/pages/AccessControl/Edit'
import GrantAccess from '@/pages/AccessControl/GrantAccess'

const AccessControl = () => {
  const dispatch = useAppDispatch()
  const accesses = useAppSelector(accessList)
  const { users } = useAppSelector(state => state.access)
  const { isLoading, data, refetch } = useGetUsersListQuery(undefined)

  useEffect(() => {
    if (!data) return
    dispatch(
      setUsers(
        data.map(user => ({
          ...user,
          isEdit: false,
          isDelete: false,
        })),
      ),
    )
  }, [dispatch, data])

  return (
    <div className="w-full flex flex-col gap-6">
      <PageHeading title="Access Control" extra={<GrantAccess />} />
      <DataTable
        data={users}
        // @ts-ignore
        columns={
          accesses.ADMIN_WRITE
            ? accessControlColumns.map(column => {
                if (column.id === 'actions') {
                  return {
                    ...column,
                    cell: ({ row }) => (
                      <AccessControlTableActions
                        user={row.original as IListUserWithActions}
                        onSuccess={refetch}
                      />
                    ),
                  }
                }
                return column
              })
            : accessControlColumns.filter(column => column.id !== 'hasAccess')
        }
        loading={isLoading}
      />
      <EditUser refetch={refetch} />
    </div>
  )
}

export default AccessControl
