import React, { useState } from 'react'
import { useAppSelector } from '@/store/hooks'
import { Input, Typography } from '@/components'
import clsx from 'clsx'

import styles from '../styles.module.scss'
import RealTime from '@/sections/RealTime'
import Accordion from '@/components/Accordion'
import moment from 'moment-timezone'

import { ReactComponent as LocationIcon } from '@/assets/icons/Location.svg'
import InventorySearch from '@/sections/InventorySearch'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'

const OrganizationIntentList = ({
  instructions = {},
}: {
  instructions?: { [key: string]: string }
}) => {
  const [search, setSearch] = useState('')
  if (!Object.keys(instructions).length) {
    return null
  }
  const intents = Object.entries(instructions).sort((a, b) => {
    return a[0].localeCompare(b[0])
  })

  const filteredIntents = search
    ? [...intents].filter(([key]) => {
        return key.toLowerCase().includes(search.toLowerCase())
      })
    : intents
  return (
    <div className="mt-3">
      <Disclosure>
        {({ open }) => (
          <div>
            <DisclosureButton className=" flex w-full justify-between items-center mb-3">
              <Typography variant="regular" weight="semibold">
                Organization intents list
              </Typography>
              <ChevronUpIcon
                className={`${!open ? 'rotate-180 transform' : ''} h-5 w-5 mr-4`}
              />
            </DisclosureButton>
            <DisclosurePanel className="pb-2">
              <div className="flex flex-col gap-2">
                <Input
                  className="mb-3"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  placeholder="Search intent"
                />

                <Accordion
                  items={filteredIntents.map(([key, value]) => {
                    return {
                      title: key,
                      content: value,
                    }
                  })}
                />
              </div>
            </DisclosurePanel>
          </div>
        )}
      </Disclosure>
    </div>
  )
}

const SuperhumanJobOrganization = () => {
  const { job, jobVendor } = useAppSelector(state => state.superhuman)
  const customized_intents = job?.organization?.instructions?.customized_intents
  const items = customized_intents ? Object.entries(customized_intents) : []
  return (
    <>
      <div className={styles.sectionHeading}>
        <Typography variant="H3">{job?.organization.name}</Typography>
        {job?.location && (
          <Typography
            variant="small"
            className="text-black-50 mt-2 flex gap-1 items-center"
          >
            <LocationIcon className="w-4 h-4" />
            {job?.location.address}
          </Typography>
        )}
      </div>

      {jobVendor && job?.organization && (
        <div className={styles.sectionContent}>
          <InventorySearch
            vendor={jobVendor}
            organization={job.organization._id}
          />
        </div>
      )}

      <div className={styles.sectionContent}>
        <RealTime
          text={`Current time in ${job?.timezone}`}
          timezone={job?.timezone || 'UTC'}
        />
        {items.length > 0 && (
          <div>
            <Typography variant="regular" weight="semibold" className="my-3">
              Intent instructions
            </Typography>
            <Accordion
              items={items.map(([key, value]) => {
                return {
                  title: key,
                  content: value,
                }
              })}
            />
          </div>
        )}
        <div>
          <Typography variant="regular" weight="semibold" className="my-3">
            Global Instructions
          </Typography>
          <Accordion
            items={[
              {
                title: 'General instructions',
                content:
                  job?.organization?.instructions.general ||
                  'No general instructions',
              },
              {
                title: 'Promotions',
                content:
                  job?.organization?.instructions.promotions || 'No specials',
              },
              {
                title: 'Human Instructions',
                content: job?.organization?.instructions.human || 'No specials',
              },
            ]}
          />
        </div>
        <OrganizationIntentList
          instructions={
            job?.organization?.instructions.organization_instructions
          }
        />
        <span className="border border-gray-100 my-3"></span>
        <div>
          <Typography variant="regular" weight="semibold" className="mb-3">
            Opening Hours
          </Typography>
          <div className="flex flex-col gap-1">
            {Object.keys(job?.organization.openingHours || {}).map(day => {
              return job?.organization.openingHours?.[day].isOpen ? (
                <div key={day} className="grid grid-cols-3">
                  <Typography variant="small" weight="medium">
                    {day}
                  </Typography>{' '}
                  <Typography
                    variant="small"
                    className="text-black-50 col-span-2"
                  >
                    {job?.organization.openingHours?.[day].from} -{' '}
                    {job?.organization.openingHours?.[day].to}
                  </Typography>
                </div>
              ) : null
            })}
          </div>
        </div>
        <span className="border border-gray-100 my-3"></span>

        {!!job?.organization?.customWorkingHours?.length && (
          <div>
            <Typography variant="regular" weight="semibold" className="mb-3">
              Custom Working Hours
            </Typography>
            <div className="flex flex-col gap-1">
              {job?.organization?.customWorkingHours.map((hour, i) => {
                return (
                  <div
                    key={i}
                    className={clsx('grid grid-cols-3', {
                      'opacity-40': moment(hour.date, 'YYYY/MM/DD').isBefore(
                        moment(),
                      ),
                    })}
                  >
                    <Typography variant="small" weight="medium">
                      {moment(hour.date).format('YYYY-MM-DD')}
                    </Typography>
                    <Typography
                      variant="small"
                      className="text-black-50 col-span-2 flex"
                    >
                      {hour.from} - {hour.to}
                      <span
                        className={clsx(
                          'ml-auto',
                          hour.isOpen ? 'text-green-100' : 'text-red-100',
                        )}
                      >
                        {hour.isOpen ? 'Open' : 'Closed'}
                      </span>
                    </Typography>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default SuperhumanJobOrganization
