import React from 'react'
import { Toggle, Typography } from '@/components'
import Card from '@/components/Card'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { onFormChange } from '@/store/slices/pages/organization/organization-automations.slice'

const Restrictions = () => {
  const dispatch = useAppDispatch()
  const {
    form: { meta },
  } = useAppSelector(state => state.organizationAutomations)

  const onChangeMeta = (field: string) => (value: any) => {
    dispatch(onFormChange({ meta: { ...meta, [field]: value } }))
  }
  return (
    <Card className="flex flex-col gap-3 !shadow-none">
      <Typography variant="regular" weight="medium">
        Restrictions
      </Typography>
      <Toggle
        label="Exclude archived customers in Matador"
        checked={meta?.excludeArchived}
        onChange={_v => onChangeMeta('excludeArchived')(_v)}
      />
      <Toggle
        label="Allow for automations to run on non-working days"
        checked={meta?.ignoreRegular}
        onChange={_v => onChangeMeta('ignoreRegular')(_v)}
      />
      <Toggle
        label="Allow for automations to run on closed custom days"
        checked={meta?.ignoreCustom}
        onChange={_v => onChangeMeta('ignoreCustom')(_v)}
      />
      <Toggle
        label="Send consent messages to new customers"
        checked={meta?.newCustomers}
        onChange={_v => onChangeMeta('newCustomers')(_v)}
      />
      <Toggle
        label="Exclude DNC customers"
        checked={meta?.dncCustomers}
        onChange={_v => onChangeMeta('dncCustomers')(_v)}
      />
    </Card>
  )
}

export default Restrictions
