import React from 'react'
import { Drawer, DrawerSize, Typography } from '@/components'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { setDetails } from '@/store/slices/pages/automation.slice'

import Accordion from '@/components/Accordion'
import Title from '@/pages/Automations/Details/Title'
import Content from '@/pages/Automations/Details/Content'
import { DATE_FORMAT_FULL } from '@/constants'
import moment from 'moment-timezone'

const AutomationDetails = () => {
  const dispatch = useAppDispatch()
  const { details } = useAppSelector(state => state.automation)
  const onClose = () => {
    dispatch(setDetails(undefined))
  }

  return (
    <div>
      <Drawer
        size={DrawerSize.XL}
        open={!!details}
        onClose={onClose}
        title={details?.organization?.name}
        description={`Launched At: ${moment(details?.createdAt).format(DATE_FORMAT_FULL)}`}
      >
        {details && (
          <div className="flex flex-col gap-3">
            <Typography variant="small" className="text-black-80">
              Events:
            </Typography>
            <div>
              <Accordion
                items={(details?.events || []).map(event => {
                  const parsedPayload =
                    details?.automation?.type === 'automation' &&
                    event.type === 'TASK_PARSE' &&
                    details?.parsedPayload?.length
                      ? details.parsedPayload
                      : undefined
                  return {
                    title: (
                      <div>
                        <Title
                          message={event.payload.message || event.type}
                          type={event.payload.tag}
                        />
                      </div>
                    ),
                    content: (
                      <Content event={event} parsedPayload={parsedPayload} />
                    ),
                  }
                })}
              />
            </div>
          </div>
        )}
      </Drawer>
    </div>
  )
}

export default AutomationDetails
