import React from 'react'
import { Typography } from '@/components'
import { ReactComponent as InfoIcon } from '@/assets/icons/Info Blue.svg'
import { ReactComponent as ErrorIcon } from '@/assets/icons/Error Red.svg'
import { ReactComponent as CheckIcon } from '@/assets/icons/Check Green.svg'
const icons = {
  success: CheckIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const Title = ({ message, type }: { message: string; type: string }) => {
  console.log(message, type)
  const Icon =
    type === 'SUCCESS'
      ? icons.success
      : type === 'ERROR'
        ? icons.error
        : icons.info
  return (
    <div className="flex items-center gap-2">
      <Icon />
      <Typography variant="regular" weight="medium">
        {message}
      </Typography>
    </div>
  )
}

export default Title
