import React, { ReactNode, MouseEvent } from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'

export type TypographyVariant =
  | 'H1'
  | 'H2'
  | 'H3'
  | 'medium'
  | 'regular'
  | 'small'
  | 'tiny'

type TypographyWeight = 'semibold' | 'medium' | 'normal'

const typographyVariantElement: {
  [key in TypographyVariant]: React.ElementType
} = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  medium: 'div',
  regular: 'div',
  small: 'p',
  tiny: 'span',
}

export interface TypographyProps {
  variant: TypographyVariant
  weight?: TypographyWeight
  as?: string
  className?: string
  children?: ReactNode
  onClick?: (event: MouseEvent<HTMLElement>) => void
  disabled?: boolean
}

export const Typography = ({
  variant,
  weight = 'normal',
  as,
  className,
  children,
  onClick,
  ...rest
}: TypographyProps) => {
  const Element = as ? as : typographyVariantElement[variant]
  return (
    <Element
      className={clsx(className, styles[variant], {
        [styles[`text-${weight}`]]: weight,
      })}
      {...rest}
      onClick={onClick}
    >
      {children}
    </Element>
  )
}
