import React, { useEffect } from 'react'
import { internalApiRequest } from '@/api'
import { DataTable } from '@/components'
import { pendingFormColumns } from '@/pages/SuccessPortal/A2P/config'

const A2pPending = () => {
  const [loading, setLoading] = React.useState(true)
  const [workflowForms, setWorkflowForms] = React.useState([])

  useEffect(() => {
    internalApiRequest
      .get(
        `/workflows?names[]=a2p_registration&status[]=paused&status[]=failed&status[]=created`,
      )
      .then(res => {
        setWorkflowForms(res.data)
      })
      .catch(err => {
        throw JSON.stringify(err)
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false)
        }, 300)
      })
  }, [])

  return (
    <DataTable
      data={workflowForms}
      columns={pendingFormColumns}
      loading={loading}
    />
  )
}

export default A2pPending
