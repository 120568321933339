import React from 'react'
import ComingSoon from '@/sections/ComingSoon'

import { ReactComponent as BroadcastingIcon } from '@/assets/icons/Broadcasting.svg'
import Tab from '@/components/Tab'
import {
  broadcastingScreenList,
  screenComponentList,
} from '@/pages/SuccessPortal/Broadcasting/config'
import { TBroadcastingScreen } from '@/types'

const Broadcasting = () => {
  const [currentView, setCurrentView] = React.useState<TBroadcastingScreen>(
    broadcastingScreenList[0]._id,
  )
  const Component = screenComponentList[currentView]
  return (
    <div className="border-t border-bg-stroke pt-4">
      {/*<ComingSoon*/}
      {/*  Icon={BroadcastingIcon}*/}
      {/*  description="Easily split large lists and schedule broadcasts in a single step—no more manual downloads or repetitive uploads."*/}
      {/*/>*/}
      <div className="flex flex-col gap-6">
        <Tab
          items={broadcastingScreenList}
          active={currentView}
          onChange={setCurrentView}
        />
        <Component />
      </div>
    </div>
  )
}

export default Broadcasting
