import apiService from '@/api'
import {
  IAvailableJobCount,
  IResponse,
  ISuperhumanJob,
  ISuperhumanJobAppointment,
  ISuperhumanJobPayload,
} from '@/types'
import { endpoints } from '@/api/constants'
import { InventoryType } from '@/types/inventory'

export const getSuperhumanJobsCount = async () => {
  return await apiService.get<IAvailableJobCount>(endpoints.aiJobsQueueLength)
}

export const getSuperhumanJob = async ({ id }: { id: string }) => {
  return await apiService.get<ISuperhumanJob>(endpoints.aiJobsJob, { id })
}

export const getRequestJob = async () => {
  return await apiService.post<{ jobId: string }>(endpoints.aiJobsRequest)
}

export const getInventoryAccess = async ({
  organization,
}: {
  organization: string
}) => {
  return await apiService.get<IResponse<InventoryType | undefined>>(
    endpoints.inventoryOrganizationAccess,
    { organization },
  )
}

export const cancelJobAppointment = async ({
  id,
  appointment,
}: {
  id: string
  appointment: string
}) => {
  return await apiService.post<{ jobId: string }>(
    endpoints.aiJobAppointmentCancel,
    undefined,
    {
      id,
      appointment,
    },
  )
}

export const editJobAppointment = async ({
  id,
  appointment,
  startDate,
}: {
  id: string
  appointment: string
  startDate: Date
}) => {
  return await apiService.post<{
    message: string
    appointment: ISuperhumanJobAppointment
  }>(
    endpoints.aiJobAppointmentEdit,
    { startDate },
    {
      id,
      appointment,
    },
  )
}

export const cancelJob = async ({ id }: { id: string }) => {
  return await apiService.post<IResponse<{ message: string }>>(
    endpoints.aiJobCancel,
    undefined,
    {
      id,
    },
  )
}

export const approveJob = async ({
  message,
  tagging_message,
  id,
  users_to_tag,
  appointment_time,
}: ISuperhumanJobPayload) => {
  return await apiService.post<IResponse<{ message: string }>>(
    endpoints.aiJobSubmit,
    { message, tagging_message, appointment_time, users_to_tag },
    { id },
  )
}
