import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { Button, Drawer, DrawerSize, Input, Select } from '@/components'
import {
  onFormChange,
  onFormToggle,
} from '@/store/slices/pages/inventory.slice'
import { useGetOrganizationsQuery } from '@/api/services/organization-list'
import { formDefaultState, vendors } from '@/constants/inventory'
import { TrashIcon } from '@heroicons/react/16/solid'
import { ReactComponent as PlusIcon } from '@/assets/icons/Plus.svg'
import { IInventoryForm } from '@/types/inventory'
import { isValidUrl } from '@/utils'
import {
  useCreateInventoryMutation,
  useUpdateInventoryMutation,
} from '@/api/services/inventory'
import toast from 'react-hot-toast'

const InventoryForm = ({ onSuccess }: { onSuccess: () => void }) => {
  const [formError, setFormError] = useState<
    Partial<Record<keyof IInventoryForm, string>>
  >({})
  const [urlErrors, setUrlErrors] = useState<Record<string, string>>({})
  const { data: organizations = [] } = useGetOrganizationsQuery(undefined)
  const [createInventory, { isLoading }] = useCreateInventoryMutation()
  const [updateInventory, { isLoading: isUpdating }] =
    useUpdateInventoryMutation()
  const dispatch = useAppDispatch()
  const { form, showForm } = useAppSelector(state => state.inventory)
  const { domain_url, dealer_id, organization_id, vendor, id } = form
  const onChange = (field: string, value: any) => {
    dispatch(onFormChange({ [field]: value }))
  }
  const onModalClose = () => {
    dispatch(onFormChange(formDefaultState))
    dispatch(onFormToggle(false))
  }
  const organizationList = [
    { _id: '', name: 'Select Organization' },
    ...organizations,
  ]

  const vendorList = [{ _id: '', name: 'Select Vendor' }, ...vendors]

  const onSubmit = () => {
    setFormError({})
    setUrlErrors({})
    const values = { ...form }
    delete values.id
    const keys = Object.keys(values)

    const errors: Partial<Record<keyof IInventoryForm, string>> = {}
    keys
      .filter(key => key !== 'domain_url')
      .forEach(key => {
        if (!form[key as keyof IInventoryForm]) {
          errors[key as keyof IInventoryForm] = 'Field is required!'
        }
      })

    setFormError(errors)

    const urlErrorList: Record<string, string> = {}
    if (
      values.domain_url.length > 1 ||
      (values.domain_url.length === 1 && values.domain_url[0] !== '')
    ) {
      values.domain_url.forEach(url => {
        if (!isValidUrl(url)) {
          urlErrorList[url] = 'Invalid URL'
        }
      })
    }

    setUrlErrors(urlErrorList)

    if (Object.keys(errors).length || Object.keys(urlErrorList).length) {
      return
    }
    const action = id ? updateInventory : createInventory
    action({ ...form, domain_url: form.domain_url.join(',') })
      .then(res => {
        onModalClose()
        toast.success(res.data?.message || 'Action was successful!')
        onSuccess()
      })
      .catch(error => {
        toast.error(error.response.data.error || 'Something wend wrong!')
      })
  }

  return (
    <Drawer
      size={DrawerSize.MD}
      open={showForm}
      title={`${id ? 'Update' : 'Create'} Inventory`}
      onClose={onModalClose}
    >
      <div className="flex flex-col gap-4 flex-grow">
        <Input
          error={formError.dealer_id}
          label="Dealer ID"
          placeholder="Enter Dealer ID"
          value={dealer_id}
          onChange={e => onChange('dealer_id', e.target.value)}
        />
        <Select
          isSearchable
          error={formError.organization_id}
          options={organizationList}
          value={organizationList.find(o => o._id === organization_id)}
          onChange={val => {
            onChange('organization_id', val?._id)
          }}
          label="Organization"
          placeholder="Select Organization"
        />
        <Select
          error={formError.vendor}
          options={vendorList}
          value={vendorList.find(v => v._id === vendor)}
          onChange={val => {
            onChange('vendor', val?._id)
          }}
          label="Vendor"
          placeholder="Select Vendor"
        />
        {domain_url.map((url, index) => (
          <div key={index} className="flex items-start gap-3 w-full">
            <Input
              error={urlErrors[url]}
              className="w-full"
              placeholder="Domain URL"
              label={'Domain URL' + (index > 0 ? ` (${index + 1})` : '')}
              value={url}
              onChange={e => {
                const values = domain_url.map((v, i) =>
                  i === index ? e.target.value : v,
                )
                onChange('domain_url', values)
              }}
            />
            {domain_url.length > 1 && (
              <TrashIcon
                className="w-5 h-5 text-red-100 mt-8 cursor-pointer"
                onClick={() => {
                  const values = domain_url.filter((_, i) => i !== index)
                  onChange('domain_url', values)
                }}
              />
            )}
          </div>
        ))}
        <div className="flex gap-3 mt-2">
          <Button
            size="large"
            status="secondary"
            onClick={() => {
              onChange('domain_url', [...domain_url, ''])
            }}
            IconLeft={PlusIcon}
          >
            Add domain
          </Button>
          <Button
            status="primary"
            size="large"
            loading={isLoading || isUpdating}
            onClick={onSubmit}
          >
            Save
          </Button>
        </div>
      </div>
    </Drawer>
  )
}

export default InventoryForm
