import React, { useState } from 'react'

import { Button, Typography } from '@/components'
import { IUnusedFeed, IUnusedFeedResponseType } from '@/types/inventory'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { INVENTORY } from '@/constants'
import { useGetUnusedFeedsQuery } from '@/api/services/inventory'
import UnusedFeedModal from '@/pages/Inventory/Stats/UnusedFeedModal'
import Card from '@/components/Card'
import SyncFailureModal from '@/pages/Inventory/Stats/SyncFailureModal'

const InventoryStats = ({
  totalItems,
}: {
  totalItems: number
  loading: boolean
}) => {
  const [showSyncFailures, setShowSyncFailures] = useState(false)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { data: { data: unusedFeeds = {} } = {}, isLoading } =
    useGetUnusedFeedsQuery(undefined)

  const feedsCount = Object.values(unusedFeeds).reduce<number>(
    (acc, feed) => acc + (feed as IUnusedFeed[]).length,
    0,
  )

  const handleSearch = (value: boolean) => {
    navigate({
      pathname: INVENTORY,
      search: value ? `?unused=${true}` : undefined,
    })
  }

  return (
    <div className="grid grid-cols-4 gap-4">
      <Card className="!p-5 flex flex-col gap-2 col-span-1">
        <Typography variant="regular" weight="medium">
          All inventory feeds
        </Typography>
        <Typography variant="H2" weight="semibold">
          {totalItems}
        </Typography>
      </Card>
      <Card className="!p-5 flex flex-col gap-2 relative col-span-1">
        <Typography variant="regular" weight="medium">
          Unused
        </Typography>
        <Typography variant="H2" weight="semibold">
          {feedsCount}
        </Typography>
        <Button
          status="text"
          size="small"
          onClick={() => handleSearch(true)}
          className="!absolute top-2 right-5"
        >
          Unused Feeds
        </Button>
      </Card>
      {/*<Card className="!p-5 flex flex-col gap-2 relative col-span-1">*/}
      {/*  <Typography variant="regular" weight="medium">*/}
      {/*    Inventory Sync Failures*/}
      {/*  </Typography>*/}

      {/*  <Typography variant="H2" weight="semibold">*/}
      {/*    234*/}
      {/*  </Typography>*/}
      {/*  <Button*/}
      {/*    status="text"*/}
      {/*    size="small"*/}
      {/*    className="!absolute top-2 right-5"*/}
      {/*    onClick={() => setShowSyncFailures(true)}*/}
      {/*  >*/}
      {/*    View Failures*/}
      {/*  </Button>*/}
      {/*</Card>*/}
      <UnusedFeedModal
        loading={isLoading}
        open={!!searchParams.get('unused')}
        onClose={() => handleSearch(false)}
        unusedFeeds={unusedFeeds as IUnusedFeedResponseType}
      />
      <SyncFailureModal
        open={showSyncFailures}
        onClose={() => setShowSyncFailures(false)}
      />
    </div>
  )
}

export default InventoryStats
