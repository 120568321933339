import React, { ReactNode, useCallback, useEffect, useMemo } from 'react'
import { DateRange, DayPicker } from 'react-day-picker'
import clsx from 'clsx'
import 'react-day-picker/style.css'

import { formatDateTime, isDisableDate } from '@/utils'

import { PickerClasses } from './constants'
import './styles.scss'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { Button, Typography } from '@/components'

import { ReactComponent as ArrowIcon } from '@/assets/icons/Arrow Down.svg'
import { TAppTheme } from '@/types'

type Mode = 'single' | 'range'

export interface IDatePickerProps<TMode extends Mode = 'single'> {
  label?: string
  className?: string
  placeholder?: string
  placement?: string
  positioning?: string
  value?: TMode extends 'single' ? Date : DateRange
  minDate?: Date
  disabled?: boolean
  required?: boolean
  error?: string
  disabledDates?: Date[]
  format?: string
  onChange?: (
    date: TMode extends 'single' ? Date | undefined : DateRange | undefined,
  ) => void
  mode?: TMode
  [key: string]: any
  containerClassName?: string
  triggerClassName?: string
  CustomIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  theme?: TAppTheme
}

const PanelWrapper = ({
  children,
  open,
  onOpenChange,
}: {
  children: ReactNode
  onOpenChange: () => void
  open: boolean
  value: any
}) => {
  useEffect(() => {
    return () => {
      onOpenChange()
    }
  }, [onOpenChange, open])
  return <>{children}</>
}

export const DatePicker = <TMode extends Mode = 'single'>({
  className,
  placeholder,
  value,
  maxDate,
  minDate,
  disabled,
  disabledDates,
  format = 'D.MM.YYYY',
  onChange,
  mode = 'single' as TMode,
  containerClassName,
  triggerClassName,
  CustomIcon,
  label,
  theme = 'light',
  ...rest
}: IDatePickerProps<TMode>) => {
  const [range, setRange] = React.useState<DateRange | undefined>(
    mode === 'range' ? (value as DateRange) : undefined,
  )

  const [displayLabel, isPlaceholder]: [string | undefined, boolean] =
    useMemo(() => {
      if (!value) return [placeholder, true]

      if (
        mode === 'range' &&
        (value as DateRange).from &&
        (value as DateRange).to
      ) {
        const from = formatDateTime(
          (value as DateRange).from,
          format || 'D.MM.YY',
        )
        const to = formatDateTime((value as DateRange).to, format || 'D.MM.YY')
        return [`${from} - ${to}`, false]
      }

      if (
        mode === 'range' &&
        !(value as DateRange).from &&
        !(value as DateRange).to
      ) {
        return [placeholder, true]
      }

      return [formatDateTime(value as Date, format || 'D.MM.YY'), false]
    }, [format, placeholder, value, mode])

  const onOpenChange = useCallback(() => {
    if (mode === 'range') {
      setRange(value as DateRange)
    }
  }, [mode, value])

  useEffect(() => {
    if (mode === 'range') {
      setRange(value as DateRange)
    }
  }, [mode, value])

  return (
    <div className={clsx('custom-date-picker relative', className)}>
      {label && (
        <Typography
          variant="small"
          weight="medium"
          className={clsx('mb-1', {
            'text-white-100': theme === 'dark',
            'text-black-80': theme === 'light',
          })}
        >
          {label}
        </Typography>
      )}
      <Popover className={clsx('date-picker-container', containerClassName)}>
        <>
          <PopoverButton
            disabled={disabled}
            className={clsx('trigger-button', triggerClassName, {
              'border-[#302E47] bg-[#242042]': theme === 'dark',
            })}
          >
            <div className="trigger-wrapper">
              <Typography
                variant="small"
                weight="medium"
                className={clsx({ 'text-[#A3A6BE]': theme === 'dark' })}
              >
                {displayLabel ? displayLabel : 'Select a Date'}
              </Typography>
            </div>
            {CustomIcon ? (
              <CustomIcon
                className={clsx({ 'text-[#A4A5A6]': theme === 'dark' })}
              />
            ) : (
              <ArrowIcon
                className={clsx({ 'text-[#A4A5A6]': theme === 'dark' })}
              />
            )}
          </PopoverButton>
          <PopoverPanel className="date-popover-panel">
            {({ close, open }) => (
              <PanelWrapper
                open={open}
                onOpenChange={onOpenChange}
                value={value}
              >
                <div className="date-picker-panel">
                  <div className="item-bg">
                    <DayPicker
                      {...rest}
                      showOutsideDays
                      mode={mode}
                      defaultMonth={
                        mode === 'single'
                          ? ((value as Date) ?? new Date())
                          : ((value as DateRange)?.from ?? new Date())
                      }
                      // @ts-ignore
                      selected={mode === 'range' ? range : value}
                      onSelect={(val: Date | DateRange | undefined) => {
                        if (mode === 'single') {
                          // @ts-ignore
                          onChange?.(val as Date | undefined)
                          close()
                        }
                        if (mode === 'range') {
                          setRange(val as DateRange)
                        }
                      }}
                      toDate={new Date()}
                      numberOfMonths={mode === 'range' ? 2 : 1}
                      disabled={(curDate: Date) =>
                        isDisableDate(curDate, disabledDates, maxDate, minDate)
                      }
                      className="custom-date-picker-container"
                      modifiersClassNames={{ today: 'day-today' }}
                      classNames={{ ...PickerClasses }}
                      footer={
                        mode === 'range' ? (
                          <div className="flex justify-between gap-2 items-center px-4 pb-4">
                            <div className="flex gap-2 items-center">
                              {range?.from && (
                                <div className="h-9 px-3 border border-bg-stroke rounded-xl flex justify-center items-center">
                                  <Typography variant="small" weight="medium">
                                    {formatDateTime(range.from, format)}
                                  </Typography>
                                </div>
                              )}
                              {range?.to && (
                                <>
                                  <span>-</span>
                                  <div className="h-9 px-3 border border-bg-stroke rounded-xl flex justify-center items-center">
                                    <Typography variant="small" weight="medium">
                                      {formatDateTime(range.to, format)}
                                    </Typography>
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="range-footer">
                              <Button
                                type="button"
                                status="secondary"
                                size="small"
                                onClick={() => {
                                  close()
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                type="button"
                                status="primary"
                                size="small"
                                onClick={() => {
                                  // @ts-ignore
                                  onChange?.(range)
                                  close()
                                }}
                              >
                                Select
                              </Button>
                            </div>
                          </div>
                        ) : undefined
                      }
                    />
                  </div>
                </div>
              </PanelWrapper>
            )}
          </PopoverPanel>
        </>
      </Popover>
    </div>
  )
}

export default DatePicker
