import React, { ReactNode } from 'react'
import { RadioGroup, Radio as RadioBase, Field, Label } from '@headlessui/react'
import { Typography } from '@/components'
import clsx from 'clsx'

export interface RadioProps {
  onChange?: (val: string) => void
  label?: string
  options: { label: string | ReactNode; value: string }[]
  className?: string
  fieldClassName?: string
  value: string
}
export const Radio = ({
  value,
  onChange,
  options,
  className,
  fieldClassName,
}: RadioProps) => {
  return (
    <RadioGroup value={value} onChange={onChange} className={className}>
      {options.map(option => (
        <Field
          className={clsx('flex gap-3 items-center', fieldClassName)}
          key={option.value}
        >
          <RadioBase
            value={option.value}
            className="group flex size-4 items-center justify-center rounded-full border border-[#D0D5DD] data-[checked]:border-blue-100 bg-white transition-all disabled:bg-[#F9FAFB] outline-none"
          >
            <span className="invisible size-2 rounded-full bg-white-100 group-data-[checked]:visible group-data-[checked]:bg-blue-100 group-data-[disabled]:bg-[#D0D5DD]" />
          </RadioBase>
          {option.label && (
            <Label>
              <Typography variant="regular" weight="medium">
                {option.label}
              </Typography>
            </Label>
          )}
        </Field>
      ))}
    </RadioGroup>
  )
}
