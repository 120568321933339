import React from 'react'
import moment from 'moment-timezone'
import { DATE_FORMAT } from '@/constants'
import { ColumnDef } from '@tanstack/react-table'
import OrganizationLink from '@/sections/OrganizationLink'
import { Tag } from '@/components'
import { capitalize } from '@/utils'

export const errorTrackerColumns: ColumnDef<any, any>[] = [
  {
    id: 'organization',
    header: () => <span>Organization</span>,
    cell: info => {
      const data = info.row.original.organization
      return <OrganizationLink id={data._id} name={data.name} />
    },
  },
  {
    id: 'crm',
    header: () => <span>CRM</span>,
    cell: info => info.row.original.crm,
  },
  {
    id: 'error_code',
    header: () => <span>Error Code</span>,
    cell: info => (
      <Tag status="error" variant="light">
        #{info.row.original.error_code}
      </Tag>
    ),
  },
  {
    id: 'task',
    accessorKey: 'task',
    header: () => <span>Automation Tasks</span>,
    cell: ({ getValue }) => {
      const task = getValue()
      return task ? capitalize(task.type) : ''
    },
  },
  {
    id: 'createdAt',
    accessorKey: 'createdAt',
    header: () => <span>Date</span>,
    cell: info => {
      return moment(info.getValue()).format(DATE_FORMAT)
    },
  },
]
