import { ColumnDef } from '@tanstack/react-table'
import { ILockedLocation, IUser } from '@/types'
import React from 'react'
import {
  capitalize,
  generatePath,
  generateQueryParams,
  renderUserFullName,
  truncateString,
} from '@/utils'
import moment from 'moment-timezone'
import { DATE_FORMAT, ORGANIZATION, SETTINGS } from '@/constants'
import { Link } from 'react-router-dom'

export const lockedLocationColumns: ColumnDef<ILockedLocation, any>[] = [
  {
    accessorKey: 'location.name',
    header: () => <span>Location</span>,
    cell: info => (
      <Link
        className="text-blue-100"
        to={
          generatePath(ORGANIZATION + SETTINGS, {
            id: info.row.original.location._organization_id,
          }) +
          generateQueryParams({
            location: info.row.original.location._id,
          })
        }
      >
        {truncateString(info.getValue())}
      </Link>
    ),
  },

  {
    accessorKey: 'crm',
    header: () => <span>CRM</span>,
    cell: info => capitalize(info.getValue()),
  },
  {
    accessorKey: 'lockedBy',
    header: () => <span>Locked by</span>,
    cell: info => {
      const user = info.row.original.lockedBy
      return renderUserFullName(user.firstName, user.lastName)
    },
  },
  {
    accessorKey: 'reason',
    header: () => <span>Reason</span>,
    cell: info => truncateString(info.getValue(), 45),
  },
  {
    accessorKey: 'assignedUsers',
    header: () => <span>Assigned users</span>,
    cell: info => {
      const users = info.getValue()
      return (
        <div className="flex flex-col gap-1">
          {users.map((user: IUser) => (
            <div key={user._id}>
              {renderUserFullName(user.firstName, user.lastName)}
            </div>
          ))}
        </div>
      )
    },
  },
  {
    accessorKey: 'lockedAt',
    header: () => <span>Updated At</span>,
    cell: info => moment(info.getValue()).format(DATE_FORMAT),
  },
]
