import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import CSVReader from 'react-csv-reader'
import { Button, DataTable, Typography } from '@/components'

import noImage from '@/assets/images/image-not-available.png'
import { IInventory } from '@/types/inventory'
import Pagination from '@/components/Pagination'
import { ColumnDef } from '@tanstack/react-table'
const FileViewer = ({
  url,
  defaultLoading = false,
}: {
  url: string | null
  defaultLoading?: boolean
}) => {
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(defaultLoading)
  const [csvData, setCsvData] = useState<IInventory[] | null>(null)
  const [csvFile, setCsvFile] = useState<File | null>(null)
  const inputRef = createRef<HTMLInputElement>()

  useEffect(() => {
    setError('')
  }, [url])

  useEffect(() => {
    if (defaultLoading) {
      setLoading(true)
    }
  }, [defaultLoading])

  const fetchCsvData = useCallback(async () => {
    if (url) {
      setLoading(true)
      try {
        const response = await fetch(url)
        if (response.status === 404) {
          setError('File not found')
          return
        }

        if (!response.ok) {
          setError('Error fetching CSV data')
          return
        }
        const csvText = await response.text()
        const blob = new Blob([csvText], { type: 'text/csv' })
        const file = new File([blob], 'data.csv', { type: 'text/csv' })
        setCsvFile(file)
      } catch (error) {
        console.error('Error fetching CSV data:', error)
      }
    }
  }, [url])
  useEffect(() => {
    if (url) fetchCsvData()
  }, [fetchCsvData, url])

  useEffect(() => {
    if (csvFile && inputRef.current) {
      const dataTransfer = new DataTransfer()
      dataTransfer.items.add(csvFile)
      inputRef.current.files = dataTransfer.files
      inputRef.current.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }, [csvFile])

  const handleCsvData = (data: IInventory[]) => {
    setCsvData(data)
    setLoading(false)
  }

  return (
    <div>
      <CSVReader
        onFileLoaded={handleCsvData}
        ref={inputRef}
        inputStyle={{ display: 'none' }}
        parserOptions={{ header: true }}
      />
      {error && (
        <Typography variant="regular" className="text-center text-red-600">
          {error}
        </Typography>
      )}
      {url && !error && (
        <InventoryTable data={csvData ?? []} loading={loading} url={url} />
      )}
    </div>
  )
}

const InventoryTable = ({
  url,
  data,
  loading,
}: {
  url: string
  data: IInventory[]
  loading: boolean
}) => {
  const [pageMeta, setPageMeta] = useState({
    page: 1,
    pageSize: 10,
  })

  const columns = useMemo(() => {
    let info = data.length
      ? Object.keys(data[0]).map(key => ({
          accessorKey: key,
          header: key,
          cell: ({ getValue }: { getValue: () => any }) => (
            <span className="max-w-[200px] block !truncate">{getValue()}</span>
          ),
        }))
      : []

    info = [
      {
        accessorKey: 'ImageList',
        header: 'Image',
        cell: ({ getValue }) => {
          const images = getValue()?.split(',')
          const image = images?.length ? images[0] : noImage
          return <img className="w-16" src={image} alt="Inventory image" />
        },
      },
      ...info.filter(i => i.accessorKey !== 'ImageList'),
    ]
    return info
  }, [data])

  const onPageMetaChange = useCallback((_page: number) => {
    setPageMeta(prev => ({
      ...prev,
      page: _page,
    }))
  }, [])

  const pageData: IInventory[] = useMemo(() => {
    const currentData = data.slice(
      pageMeta.pageSize * (pageMeta.page - 1),
      pageMeta.pageSize * pageMeta.page,
    )
    return currentData ?? []
  }, [data, pageMeta])
  if (!url) {
    return null
  }
  return (
    <div className="w-full">
      <Button
        size="small"
        status="secondary"
        className="mb-4"
        onClick={() => {
          window.open(url, '_blank')
        }}
        disabled={loading}
      >
        Download
      </Button>
      <div className="overflow-auto">
        <DataTable
          loading={loading}
          className="inventory-table"
          data={pageData}
          columns={columns}
        />
      </div>
      <Pagination
        currentPage={pageMeta.page}
        totalCount={data.length}
        pageSize={pageMeta.pageSize}
        onPageChange={onPageMetaChange}
      />
    </div>
  )
}

export default FileViewer
