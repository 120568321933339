import React from 'react'
import moment from 'moment-timezone'
import { DATE_FORMAT } from '@/constants'
import { ColumnDef } from '@tanstack/react-table'
import { IAIBotCustomer } from '@/types'
import OrganizationLink from '@/sections/OrganizationLink'

export const aiBotTableColumns: ColumnDef<IAIBotCustomer, any>[] = [
  {
    accessorKey: 'organization.name',
    header: () => <span>Organization</span>,
    cell: info => {
      const data = info.row.original.organization
      return <OrganizationLink id={data._id} name={data.name} maxChars={50} />
    },
  },
  {
    accessorKey: 'name',
    header: () => <span>Name</span>,
    // TODO: missing property
    cell: info => info.getValue(),
  },
  {
    accessorKey: 'phoneNumber',
    header: () => <span>Phone Number</span>,
    cell: info => info.getValue(),
  },
  {
    accessorKey: 'createdAt',
    header: () => <span>Date</span>,
    cell: info => moment(info.getValue()).format(DATE_FORMAT),
  },
]
