import React from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'
import { Typography } from '@/components'

import { ReactComponent as EyeIcon } from '@/assets/icons/Eye.svg'
import { ReactComponent as EyeDisableIcon } from '@/assets/icons/Eye Disable.svg'

interface InputProps
  extends Omit<React.HTMLAttributes<HTMLInputElement>, 'onChange'> {
  IconLeft?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  IconRight?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  wrapperClass?: string
  error?: string
  onChange?: (_e: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  placeholder?: string
  type?: string
  value?: string | number
  label?: string
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      label,
      IconLeft,
      IconRight,
      wrapperClass,
      disabled,
      error,
      type = 'text',
      ...rest
    },
    ref,
  ) => {
    const [typeInput, setTypeInput] = React.useState(type)
    const PasswordIcon = typeInput === 'password' ? EyeIcon : EyeDisableIcon

    return (
      <label className="w-full">
        {label && (
          <Typography
            variant="small"
            weight="medium"
            className="mb-1 text-black-80"
          >
            {label}
          </Typography>
        )}
        <div
          className={clsx(wrapperClass, styles.inputContainer, {
            [styles.disabled]: disabled,
            [styles.error]: error,
          })}
        >
          {IconLeft && (
            <IconLeft
              className={clsx(
                'absolute top-1/2 left-4 -translate-y-1/2 z-10 w-6 h-6 text-black-50',
              )}
            />
          )}
          <input
            ref={ref}
            className={clsx(className, styles.input, {
              ['pl-12']: IconLeft,
              ['pr-12']: IconRight,
              [styles.error]: error,
            })}
            disabled={disabled}
            type={typeInput}
            {...rest}
          />
          {IconRight && type !== 'password' && (
            <IconRight
              className={clsx(
                'absolute top-1/2 right-4 -translate-y-1/2 z-10 w-6 h-6 text-black-50',
              )}
            />
          )}
          {type === 'password' && (
            <PasswordIcon
              className={clsx(
                'absolute top-1/2 right-4 -translate-y-1/2 z-10 w-6 h-6 cursor-pointer  text-black-50',
              )}
              onClick={() =>
                setTypeInput(prev =>
                  prev === 'password' ? 'text' : 'password',
                )
              }
            />
          )}
        </div>
      </label>
    )
  },
)
