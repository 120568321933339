import React from 'react'
import { AutomationStatus, TIcon } from '@/types'

import styles from './styles.module.scss'
import clsx from 'clsx'

interface IconButtonProps {
  Icon: TIcon
  onClick?: () => void
  size: 52 | 32 | 24
  iconClassName?: string
  wrapperClassName?: string
  status?: AutomationStatus
}

export const IconButton = ({
  Icon,
  size = 52,
  iconClassName,
  wrapperClassName,
  status,
  onClick,
  ...rest
}: IconButtonProps) => {
  return (
    <div
      className={clsx(
        styles.wrapper,
        wrapperClassName,
        styles[`size-${size}`],
        status,
        { 'cursor-pointer': onClick },
      )}
      onClick={onClick}
      {...rest}
    >
      <Icon className={iconClassName} />
    </div>
  )
}
