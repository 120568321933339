import React from 'react'
import SuperhumanHeading from '@/pages/Superhuman/Heading'
import SuperhumanPageWrapper from '@/pages/Superhuman/PageWrapper'
import SuperhumanJobOrganization from '@/pages/Superhuman/Organization'
import SuperhumanJobConversation from '@/pages/Superhuman/Conversation'
import SuperhumanJobActions from '@/pages/Superhuman/Actions'
import { useAppSelector } from '@/store/hooks'
import clsx from 'clsx'

import styles from './styles.module.scss'
import CardWrapper from '@/sections/CardWrapper'
import { LoadingSkeleton } from '@/components'

const Superhuman = () => {
  const { job, loading } = useAppSelector(state => state.superhuman)
  return (
    <SuperhumanPageWrapper>
      <SuperhumanHeading />
      {(job || loading) && (
        <div
          className={clsx('grid grid-cols-7 gap-8', styles.superhuman)}
          id="info-section"
        >
          <CardWrapper className={clsx(styles.section, 'col-span-2')}>
            {loading ? (
              <LoadingSkeleton height="100vh" width="100%" className="" />
            ) : (
              <SuperhumanJobOrganization />
            )}
          </CardWrapper>
          <CardWrapper className={clsx(styles.section, 'col-span-3')}>
            {loading ? (
              <LoadingSkeleton height="100vh" width="100%" className="" />
            ) : (
              <SuperhumanJobConversation />
            )}
          </CardWrapper>
          <CardWrapper className={clsx(styles.section, 'col-span-2')}>
            {loading ? (
              <LoadingSkeleton height="100vh" width="100%" className="" />
            ) : (
              <SuperhumanJobActions />
            )}
          </CardWrapper>
        </div>
      )}
    </SuperhumanPageWrapper>
  )
}

export default Superhuman
