import React from 'react'
import PageHeading from '@/sections/PageHeading'
import Filter from '@/pages/Leads/Filter'
import { DataTable } from '@/components'
import Pagination from '@/components/Pagination'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { onFilterChange } from '@/store/slices/pages/lead.slice'
import { leadTableColumns } from '@/pages/Leads/config'
import { useGetLeadsQuery } from '@/api/services/organization/lead'

const Leads = () => {
  const { filter } = useAppSelector(state => state.leads)

  const {
    data: { data: leads = [], pageMeta: { totalItems = 0 } = {} } = {},
    isFetching: loading,
  } = useGetLeadsQuery(filter)

  const onPageChange = (page: number) => {
    dispatch(
      onFilterChange({
        page,
      }),
    )
  }
  const dispatch = useAppDispatch()

  return (
    <div className="w-full flex flex-col gap-6">
      <PageHeading title="Leads" />
      <Filter />
      <DataTable loading={loading} columns={leadTableColumns} data={leads} />
      <Pagination
        currentPage={filter.page}
        totalCount={totalItems}
        onPageChange={onPageChange}
      />
    </div>
  )
}

export default Leads
