import React, { ReactNode } from 'react'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react'
import { Typography } from '@/components'
import clsx from 'clsx'

import { ReactComponent as ArrowIcon } from '@/assets/icons/Arrow.svg'

export interface AccordionItem {
  title: string | ReactNode
  content: string | ReactNode
  className?: string
}

export interface AccordionProps {
  items: AccordionItem[]
  defaultOpen?: number
  className?: string
}

const Accordion = ({ items, defaultOpen, className }: AccordionProps) => {
  return (
    <div className={clsx(className, 'flex flex-col gap-3')}>
      {items.map(({ title, content, className }, index) => (
        <Disclosure as="div" key={index} defaultOpen={defaultOpen === index}>
          {({ open }) => (
            <>
              <DisclosureButton
                className={clsx(
                  'text-black-80 w-full rounded-xl p-3 border border-bg-stroke hover:bg-bg-gray transition-colors duration-200',
                  className,
                )}
              >
                <Typography
                  variant="regular"
                  weight="medium"
                  className="flex justify-between items-center text-black-80"
                >
                  {title}
                  <ArrowIcon
                    className={clsx({ 'rotate-90': open, '-rotate-90': !open })}
                  />
                </Typography>
              </DisclosureButton>
              <DisclosurePanel transition className="p-4 text-black-100">
                <Typography variant="regular" weight="medium" as="div">
                  {content}
                </Typography>
              </DisclosurePanel>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  )
}

export default Accordion
