import { createApi } from '@reduxjs/toolkit/query/react'
import { endpoints } from '@/api/constants'
import { ILead, ILeadFilter, ILeadStatsFilter, IPaginatedRes } from '@/types'
import { createBaseQuery, generateQueryParams } from '@/utils'

export const leadApi = createApi({
  reducerPath: 'leadApi',
  tagTypes: ['Leads'],
  baseQuery: createBaseQuery(),
  endpoints: builder => ({
    getLeads: builder.query<IPaginatedRes<ILead>, Partial<ILeadFilter>>({
      query: query => {
        const params = generateQueryParams(query)
        return {
          url: endpoints.allLeads + params,
        }
      },
    }),
    getLeadsStats: builder.query<number, ILeadStatsFilter>({
      query: query => {
        const params = generateQueryParams(query)
        return {
          url: endpoints.leadsStats + params,
        }
      },
    }),
  }),
})

export const { useGetLeadsQuery, useGetLeadsStatsQuery } = leadApi
