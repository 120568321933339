import React, { useCallback, useMemo } from 'react'
import { useGetSingleOrganizationsQuery } from '@/api/services/organization'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  useGetLocationsQuery,
  useGetLockedLocationsQuery,
} from '@/api/services/location'
import { useAppSelector } from '@/store/hooks'
import { accessList } from '@/store/slices/user.slice'
import { IBaseListItem } from '@/types'
import { Tag, Typography } from '@/components'
import Accordion from '@/components/Accordion'
import { Credentials } from '@/pages/Organization/Settings/Credentials'
import AssignUsers from '@/pages/Organization/Settings/AssignUsers'
import ListLoadingSkeleton from '@/sections/ListLoadingSkeleton'

const Settings = () => {
  const [searchParams] = useSearchParams()
  const { id } = useParams()
  const { data: { data: locations = [] } = {}, isLoading } =
    useGetLocationsQuery(undefined)
  const {
    data: organization,
    refetch,
    isLoading: orgLoading,
  } = useGetSingleOrganizationsQuery({
    id,
  })
  const { data: { locations: lockedLocations = [] } = {} } =
    useGetLockedLocationsQuery(undefined)
  const accesses = useAppSelector(accessList)
  const { _organization_id, organizations } = useAppSelector(
    state => state.user,
  )

  const hasAccess = useMemo(() => {
    if (accesses.ADMIN_WRITE || accesses.ADMIN_READ) return true
    if (
      !(
        (_organization_id === id ||
          organizations?.find(org => org._id === id)) &&
        (accesses.AUTOMATIONS_READ || accesses.AUTOMATIONS_WRITE)
      )
    ) {
      return false
    }
    return true
  }, [
    accesses.ADMIN_READ,
    accesses.ADMIN_WRITE,
    accesses.AUTOMATIONS_READ,
    accesses.AUTOMATIONS_WRITE,
    id,
    _organization_id,
    organizations,
  ])
  const extractLocation = useCallback(
    (locationId: string): IBaseListItem => {
      const location = locations?.find(_v => _v._id === locationId)
      return {
        _id: locationId,
        name: location?.name ?? '',
      }
    },
    [locations],
  )

  const activeIndex = useMemo(() => {
    const activeLocation = searchParams.get('location')
    return (
      organization?.locations?.findIndex(_v => _v._id === activeLocation) || 0
    )
  }, [organization, searchParams])

  const checkIsLocked = useCallback(
    (locationId: string) => {
      return !!lockedLocations.find(_v => _v.location._id === locationId)
    },
    [lockedLocations],
  )

  const loading = isLoading || orgLoading

  return (
    <div className="w-full flex flex-col gap-6 grow">
      <div className="flex flex-col gap-4">
        <Typography variant="H3" weight="semibold">
          Users
        </Typography>
        <AssignUsers />
      </div>
      <div className="flex flex-col gap-4">
        <Typography variant="H3" weight="semibold">
          Locations
        </Typography>
        <div className="h-full">
          {loading ? (
            <ListLoadingSkeleton rows={8} />
          ) : (
            <Accordion
              defaultOpen={activeIndex}
              items={
                organization?.locations?.map(_location => {
                  const isLocked = checkIsLocked(_location._id)

                  return {
                    title: (
                      <div className="flex gap-4">
                        {extractLocation(_location._id)?.name}
                        {isLocked && <Tag status="error">Locked</Tag>}
                      </div>
                    ),
                    content: (
                      <Credentials
                        location={extractLocation(_location._id)}
                        credentials={organization?.credentials || []}
                        isLocked={isLocked}
                        onUpdate={refetch}
                      />
                    ),
                  }
                }) || []
              }
              className="gap-4"
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Settings
