import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINT, AUTH_TOKEN_KEY } from '@/constants'
import { endpoints } from '@/api/constants'
import { IAutomationFilter, IPaginatedRes, ITask } from '@/types'
import { generateQueryParams, getStorageValue } from '@/utils'

export const taskApi = createApi({
  reducerPath: 'taskApi',
  tagTypes: ['Tasks'],
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT,
    prepareHeaders: headers => {
      const token = getStorageValue(AUTH_TOKEN_KEY)
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  endpoints: builder => ({
    getTasks: builder.query<IPaginatedRes<ITask>, Partial<IAutomationFilter>>({
      query: query => {
        const params = generateQueryParams(query)
        return {
          url: endpoints.allTask + params,
        }
      },
    }),
  }),
})

export const { useGetTasksQuery } = taskApi
