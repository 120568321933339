import React, { useState } from 'react'
import ConfirmModal from '@/sections/ConfirmModal'
import { Button, Typography } from '@/components'
import {
  useGetLockedLocationsQuery,
  useUnlockLocationMutation,
} from '@/api/services/location'

const LocationUnlockModal = ({ locationId }: { locationId: string }) => {
  const [unlockLocation, { isLoading }] = useUnlockLocationMutation()
  const { refetch } = useGetLockedLocationsQuery(undefined)
  const [isOpened, setIsOpened] = useState(false)
  const onUnlockLocation = async () => {
    try {
      await unlockLocation({ locationId }).unwrap()
      refetch()
      setIsOpened(false)
    } catch (err) {
      // @ts-ignore
      console.log(err.data?.message)
    }
  }
  return (
    <>
      <Button size="small" status="secondary" onClick={() => setIsOpened(true)}>
        Unlock Location
      </Button>{' '}
      <ConfirmModal
        title="Unlock Location"
        onClose={() => setIsOpened(false)}
        open={isOpened}
        isLoading={isLoading}
        content={
          <div>
            <Typography variant="regular">
              Are you sure you want to unlock this location?
            </Typography>
          </div>
        }
        onConfirm={onUnlockLocation}
      />
    </>
  )
}

export default LocationUnlockModal
