import React from 'react'
import TaskCard from '@/sections/TaskCard'

import { ReactComponent as AlertIcon } from '@/assets/icons/Alert Circle.svg'
import { AutomationStatus } from '@/types'
import { Typography, IconButton } from '@/components'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { INVENTORY, LOCKED_LOCATIONS } from '@/constants'
import { useGetLockedLocationsQuery } from '@/api/services/location'
import { useGetUnusedFeedsQuery } from '@/api/services/inventory'
import { IUnusedFeedResponseType } from '@/types/inventory'
import UnusedFeedModal from '@/pages/Inventory/Stats/UnusedFeedModal'

const TaskList = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { data: { locations = [] } = {}, isFetching: lockedLocationLoading } =
    useGetLockedLocationsQuery(undefined)
  const { data: { data: unusedFeeds } = {}, isLoading: loading } =
    useGetUnusedFeedsQuery(undefined)
  const locationCount = locations.length
  const feedsCount = Object.values(unusedFeeds || {}).reduce(
    (acc, feed) => acc + feed.length,
    0,
  )

  const [searchParams] = useSearchParams()

  const handleSearch = (value: boolean) => {
    navigate({
      pathname: location.pathname,
      search: value ? `?unused=${true}` : undefined,
    })
  }

  return (
    <div className="p-6 rounded-2xl border border-[#E5E9F2] shadow-card h-full flex flex-col gap-3">
      <div className="flex justify-between gap-4 items-center mb-3">
        <Typography variant="H3" weight="medium">
          Tasks board
        </Typography>
      </div>
      <TaskCard
        loading={loading}
        icon={
          <IconButton
            wrapperClassName="bg-red-50 text-red-100"
            Icon={AlertIcon}
            status={AutomationStatus.FAILED}
            size={32}
          />
        }
        title="Inventory feeds"
        description={`You have ${feedsCount} unused inventory feeds`}
        onClick={() => handleSearch(true)}
      />
      <TaskCard
        loading={lockedLocationLoading}
        icon={
          <IconButton
            wrapperClassName="bg-red-50 text-red-100"
            Icon={AlertIcon}
            status={AutomationStatus.FAILED}
            size={32}
          />
        }
        title="Locked locations"
        description={`You have ${locationCount} locked locations`}
        onClick={() => navigate(LOCKED_LOCATIONS)}
      />
      <UnusedFeedModal
        loading={loading}
        open={!!searchParams.get('unused')}
        onClose={() => handleSearch(false)}
        unusedFeeds={unusedFeeds as IUnusedFeedResponseType}
      />
    </div>
  )
}

export default TaskList
