import React, { ReactNode, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import {
  checkInventoryAccess,
  fetchSuperhumanJob,
  fetchSuperhumanJobCount,
  requestJob,
} from '@/store/slices/superhuman.slice'

const SuperhumanPageWrapper = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch()
  const { available, totalJobCount, jobId, job } = useAppSelector(
    state => state.superhuman,
  )
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(fetchSuperhumanJobCount())
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [available, dispatch])

  useEffect(() => {
    if (available && totalJobCount > 0 && !job) {
      dispatch(requestJob())
    }
  }, [available, dispatch, totalJobCount])

  useEffect(() => {
    if (jobId) {
      dispatch(fetchSuperhumanJob({ id: jobId }))
    }
  }, [dispatch, jobId])

  useEffect(() => {
    if (job && job.organization._id) {
      dispatch(checkInventoryAccess({ organization: job.organization._id }))
    }
  }, [dispatch, job])
  return <div className="w-full flex flex-col gap-8">{children}</div>
}

export default SuperhumanPageWrapper
