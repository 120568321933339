import React, { ReactNode } from 'react'
import SubmenuSection from '@/sections/SubmenuSection'
import { Outlet } from 'react-router-dom'

const AutomationsPageWrapper = () => {
  return (
    <div className="flex flex-col gap-6 grow">
      <SubmenuSection />
      <Outlet />
    </div>
  )
}

export default AutomationsPageWrapper
