import Cookies from 'js-cookie'

export const getStorageValue = <T = string>(key: string, defaultValue?: T) => {
  const value = Cookies.get(key)
  try {
    return value ?? defaultValue
  } catch (error) {
    console.log('storage error: ', error)
  }
  return defaultValue
}
export const setStorageValue = <T = string>(
  key: string,
  value: T,
  options: Cookies.CookieAttributes = {},
): void => {
  const valueToStore = value instanceof Function ? value(value) : value
  try {
    Cookies.set(key, valueToStore, options)
  } catch {
    console.warn(`Could not save ${key} to cookies`)
  }
}
export const removeStorageValue = (key: string) => {
  Cookies.remove(key)
}
