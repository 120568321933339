import { createApi } from '@reduxjs/toolkit/query/react'

import { endpoints } from '@/api/constants'
import {
  ICredentialMutationPayload,
  IResponse,
  IRevealPasswordResponse,
  ISettings,
} from '@/types'
import { createBaseQuery, generatePath } from '@/utils'

export const settingsApi = createApi({
  reducerPath: 'settingsApi',
  tagTypes: ['Settings'],
  baseQuery: createBaseQuery(),
  endpoints: builder => ({
    revealPassword: builder.mutation<IRevealPasswordResponse, { id: string }>({
      query: query => {
        return {
          url: generatePath(endpoints.revealPassword, query),
          method: 'POST',
        }
      },
    }),
    getCrmSettings: builder.query<IResponse<ISettings[]>, undefined>({
      query: () => {
        return {
          url: endpoints.crmSettings,
        }
      },
    }),
    updateCredential: builder.mutation<
      IRevealPasswordResponse,
      ICredentialMutationPayload
    >({
      query: query => {
        return {
          url: endpoints.credentialsMutation,
          body: query,
          method: 'POST',
        }
      },
    }),
  }),
})

export const {
  useRevealPasswordMutation,
  useGetCrmSettingsQuery,
  useUpdateCredentialMutation,
} = settingsApi
