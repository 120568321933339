import React from 'react'
import {
  useReactTable,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
} from '@tanstack/react-table'
import clsx from 'clsx'
import { Typography } from '@/components'
import { TableLoadingSkeleton } from '@/sections/TableLoadingSkeleton'
import { TAppTheme } from '@/types'

interface TableProps<T> {
  data: T[]
  columns: ColumnDef<T, any>[]
  className?: string
  loading?: boolean
  fakeDataCount?: number
  renderSubComponent?: (row: T) => React.ReactNode
  theme?: TAppTheme
}

export const DataTable = <T extends object>({
  data,
  columns,
  className,
  loading,
  fakeDataCount = 10,
  renderSubComponent,
  theme,
}: TableProps<T>) => {
  const isDark = theme === 'dark'
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  })

  return (
    <div
      className={clsx('overflow-x-auto w-full rounded-xl border ', className, {
        'border-dark': isDark,
        'border-bg-stroke shadow-card': !isDark,
      })}
    >
      <table className="w-full rounded-xl">
        <thead
          className={clsx(
            'rounded-t-xl overflow-hidden',
            isDark ? 'bg-[#302E47]' : 'bg-bg-gray',
          )}
        >
          {table.getHeaderGroups().map(headerGroup => (
            <tr
              key={headerGroup.id}
              className={clsx(
                'border ',
                isDark ? 'border-dark' : 'border-gray-100',
              )}
            >
              {headerGroup.headers.map(header => (
                <th
                  key={header.id}
                  className="px-6 py-3 text-left"
                  style={{ width: header.getSize() }}
                >
                  <Typography
                    variant="small"
                    weight="medium"
                    className={clsx(
                      isDark ? 'text-[#A3A6BE]' : 'text-black-100',
                    )}
                    as="div"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </Typography>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          className={clsx(
            'divide-y rounded-b-xl',
            isDark ? 'divide-dark' : 'bg-white-100 divide-bg-stroke',
          )}
        >
          {loading && (
            <TableLoadingSkeleton
              className="py-4"
              rows={fakeDataCount}
              cols={columns.length}
              theme={theme}
            />
          )}
          {!loading && data.length === 0 && (
            <tr>
              <td colSpan={columns.length || 1} className="py-8">
                <div
                  className={clsx('flex justify-center items-center gap-3', {
                    'text-white-100': isDark,
                    'text-black-50': !isDark,
                  })}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="64"
                    height="64"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-7 h-7"
                  >
                    <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4a2 2 0 0 0 1-1.73z"></path>
                    <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                    <line x1="12" y1="22.08" x2="12" y2="12"></line>
                  </svg>
                  <Typography variant="medium">No data</Typography>
                </div>
              </td>
            </tr>
          )}
          {!loading &&
            table.getRowModel().rows.map(row => (
              <React.Fragment key={row.id}>
                <tr
                  className={clsx({
                    'bg-[#302E47]': isDark && row.depth > 0,
                    'bg-white': !isDark && row.depth <= 0,
                    'bg-bg-gray': !isDark && row.depth > 0,
                    'bg-[#322D4F]': isDark && row.depth <= 0,
                  })}
                >
                  {row.getVisibleCells().map(cell => (
                    <td
                      key={cell.id}
                      className="px-6 py-6"
                      style={{
                        width: cell.column.getSize(),
                      }}
                    >
                      <Typography
                        variant="small"
                        weight="normal"
                        className={clsx(
                          'flex w-full h-full',
                          isDark ? 'text-white-100' : 'text-black-80',
                        )}
                        as="div"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </Typography>
                    </td>
                  ))}
                </tr>
                {row.getIsExpanded() &&
                  renderSubComponent &&
                  renderSubComponent(row.original)}
              </React.Fragment>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default DataTable
