import { endpoints } from '@/api/constants'
import { ICrmResponse } from '@/types/crm'
import { createApi } from '@reduxjs/toolkit/query/react'
import { createBaseQuery } from '@/utils'

export const crmApi = createApi({
  reducerPath: 'crmApi',
  tagTypes: ['Automation'],
  baseQuery: createBaseQuery(),
  endpoints: builder => ({
    getCrms: builder.query<ICrmResponse, undefined>({
      query: () => {
        return {
          url: endpoints.crms,
        }
      },
    }),
  }),
})

export const { useGetCrmsQuery } = crmApi
