import React from 'react'
import { Option, Select } from '@/components'
import { capitalize } from '@/utils'
import { ParsedPermissionWidthActions } from '@/types'

const ResourceSection = ({
  permission,
  resources,
  onChange,
  error,
}: {
  permission: ParsedPermissionWidthActions
  resources: Option[]
  onChange: (val: string) => void
  error: string
}) => {
  const value = permission.resource
  const isEdit = permission.edit
  const isAdd = permission.add
  const isDelete = permission.delete
  return (
    <div className="w-full">
      <div className="w-full">
        {isEdit || isAdd ? (
          <Select
            error={error}
            className="w-full"
            options={resources}
            value={resources.find(res => res._id === value)}
            onChange={val => onChange(val?._id as string)}
          />
        ) : (
          <span className={isDelete ? 'line-through opacity-70' : ''}>
            {capitalize(value)}
          </span>
        )}
      </div>
    </div>
  )
}

export default ResourceSection
