import { createApi } from '@reduxjs/toolkit/query/react'
import { endpoints } from '@/api/constants'
import { IAppointment, IAppointmentFilter, IPaginatedRes } from '@/types'
import { createBaseQuery, generateQueryParams } from '@/utils'

export const appointmentApi = createApi({
  reducerPath: 'appointmentApi',
  tagTypes: ['Appointments'],
  baseQuery: createBaseQuery(),
  endpoints: builder => ({
    getAppointments: builder.query<
      IPaginatedRes<IAppointment>,
      Partial<IAppointmentFilter>
    >({
      query: query => {
        const params = generateQueryParams(query)
        return {
          url: endpoints.allAppointments + params,
        }
      },
    }),
  }),
})

export const { useGetAppointmentsQuery } = appointmentApi
