import React, { useState } from 'react'
import Tab from '@/components/Tab'
import SalesData from '@/pages/SuccessPortal/SalesDashboard/SalesData'
import SalesBuckets from '@/pages/SuccessPortal/SalesDashboard/SalesBuckets'

const SalesDashboard = () => {
  const [currentView, setCurrentView] = useState('data')
  const Component = currentView === 'data' ? SalesData : SalesBuckets
  return (
    <div className="flex flex-col gap-6 w-full">
      <Tab
        items={[
          { _id: 'data', name: 'Sales Data' },
          // { _id: 'buckets', name: 'Sales Buckets' },
        ]}
        active={currentView}
        onChange={setCurrentView}
      />
      <Component />
    </div>
  )
}

export default SalesDashboard
