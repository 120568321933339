import React, { useCallback } from 'react'
import { Select, Option } from '@/components'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import {
  clearFilter,
  onFilterChange,
} from '@/store/slices/pages/error-tracker.slice'
import { Button } from '@/components'
import DatePicker from '@/components/DatePicker'
import { useParams } from 'react-router-dom'
import { useGetCrmsQuery } from '@/api/services/crm'
import { useGetOrganizationsQuery } from '@/api/services/organization-list'
import clsx from 'clsx'
import { SingleValue } from 'react-select'

const ErrorTrackerFilter = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const { data: organizations = [] } = useGetOrganizationsQuery(undefined)
  const { data: { data: crms = [] } = {} } = useGetCrmsQuery(undefined)

  const {
    filter: { organization, crm, startDate, endDate },
  } = useAppSelector(state => state.errorTracker)
  const onOrganizationChange = useCallback(
    (value: SingleValue<Option>) => {
      dispatch(
        onFilterChange({
          organization: (value && value._id) || '',
          page: 1,
        }),
      )
    },
    [dispatch],
  )

  const onSelectChange = useCallback(
    (field: string, value: Option | null) => {
      dispatch(
        onFilterChange({
          [field]: (!Array.isArray(value) && value?._id) || '',
          page: 1,
        }),
      )
    },
    [dispatch],
  )

  const reset = useCallback(() => {
    dispatch(clearFilter())
  }, [dispatch])

  const onDateChange = useCallback(
    (type: 'startDate' | 'endDate') => (date: Date | undefined) => {
      dispatch(
        onFilterChange({
          [type]: date,
          page: 1,
        }),
      )
    },
    [dispatch],
  )

  return (
    <div className="flex items-end">
      <div
        className={clsx(
          'grid gap-3 w-11/12',
          !id ? 'grid-cols-7' : 'grid-cols-6',
        )}
      >
        {!id && (
          <>
            <Select
              isSearchable
              label="Organization"
              options={organizations}
              value={
                organizations.find(org => org._id === organization) || null
              }
              onChange={onOrganizationChange}
            />
            <Select
              label="CRM"
              options={crms}
              value={crms.find(_crm => _crm._id === crm) || null}
              onChange={val => onSelectChange('crm', val)}
            />
          </>
        )}

        <DatePicker
          label="Start Date"
          value={new Date(startDate)}
          onChange={onDateChange('startDate')}
          placeholder="Select a date range"
        />
        <DatePicker
          label="End Date"
          value={new Date(endDate)}
          onChange={onDateChange('endDate')}
          placeholder="Select a date range"
        />
      </div>
      <Button size="small" className="ml-auto" status="text" onClick={reset}>
        Clear All
      </Button>
    </div>
  )
}

export default ErrorTrackerFilter
