export const PickerClasses = {
  months: 'months-container',
  month: 'month-container',
  caption: 'caption-container',
  caption_label: 'caption-label',
  nav: 'nav',
  nav_button: 'nav-button',
  table: 'calendar-table',
  head_row: 'calendar-header',
  head_cell: 'week-day',
  row: 'calendar-row',
  cell: 'calendar-day-container',
  day: 'calendar-day',
  day_selected: 'selected-date',
  day_today: '',
  day_outside: 'outside-date',
  disabled: 'disabled-date',
  day_range_middle: 'day-in-range',
  day_hidden: 'day-hidden',
}
