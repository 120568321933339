import { IBaseListItem } from '@/types'
import { IUnionInventory, VendorInventory } from '@/types/inventory'

export const arrayToOptions = (array: IBaseListItem[]) => {
  return array.map(item => ({ value: item._id, label: item.name }))
}

const stripUnit = (value: number): number => {
  if (!isNaN(value) && Number.isNaN(value)) {
    return value / (value * 0 + 1)
  }
  return value
}

export const rem = (value: number | string, fontSize = 16): string => {
  if (isNaN(+value)) return value.toString()
  return `${stripUnit(+value) / stripUnit(fontSize)}rem`
}

export const generateInventoryItem = ({
  vendor,
  item,
}: VendorInventory): IUnionInventory => {
  let data = {}
  if (vendor === 'homenet') {
    const {
      'dealer name': dealername,
      'dealer address': dealeraddress,
      'dealer city': dealercity,
      'dealer state': dealerstate,
      'dealer zip': dealerzip,
      'dealer email': dealeremail,
      'dealer phone': dealerphone,
      wheelbase_code: wheelbasecode,
      engine_description: enginedescription,
      engine_aspiration_type: engineaspirationtype,
      fuel_type: fueltype,
      transmission_speed: transmissionspeed,
      transmission_description: transmissiondescription,
      ext_color_generic: extcolorgeneric,
      ...rest
    } = item
    data = {
      ...rest,
      dealername,
      dealeraddress,
      dealercity,
      dealerstate,
      dealerzip,
      dealeremail,
      dealerphone,
      wheelbasecode,
      enginedescription,
      engineaspirationtype,
      fueltype,
      transmissionspeed,
      transmissiondescription,
      extcolorgeneric,
    }
    return <IUnionInventory>data
  }
  if (vendor === 'sm360') {
    const { passengercount: passengercapacity, ...rest } = item
    data = {
      ...rest,
      passengercapacity,
    }
    return <IUnionInventory>data
  } else {
    data = item
  }

  return <IUnionInventory>data
}
