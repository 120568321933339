import React from 'react'
import { Button, Input } from '@/components'
import { useHookForm } from '@/hooks'
import { grantAccessSchema } from '@/utils'
import Form from '@/components/Form'
import { useAppDispatch } from '@/store/hooks'
import { editPermission, setGrantAccess } from '@/store/slices/access.slice'

const GrantAccess = () => {
  const dispatch = useAppDispatch()
  const {
    handler: {
      reset,
      register,
      handleSubmit,
      formState: { errors },
      watch,
    },
  } = useHookForm(grantAccessSchema)
  const onSubmit = (data: any) => {
    dispatch(setGrantAccess(true))
    dispatch(
      editPermission({
        email: data.email,
        permissions: ['automations:read/[*]'],
        name: '',
        hasAccess: true,
      }),
    )
    reset()
  }
  const email = watch('email')
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex items-end gap-2">
        <Input
          {...register('email')}
          error={errors.email?.message}
          label="Enter Email"
        />
        <Button
          status="primary"
          size="small"
          className="flex-shrink-0"
          type="submit"
          disabled={!email}
        >
          Grant access
        </Button>
      </div>
    </Form>
  )
}

export default GrantAccess
