import React, { useState } from 'react'
import Tab from '@/components/Tab'
import { Button } from '@/components'
import { internalApiRequest } from '@/api'
import Registration from './Registration'
import Pending from './Pending'

const A2p = () => {
  const [currentView, setCurrentView] = useState('registration')
  const [syncing, setSyncing] = React.useState(false)
  const syncProfiles = () => {
    setSyncing(true)
    internalApiRequest
      .post('/profiles/sync')
      .then(response => {
        if (response.data) {
          setSyncing(false)
        }
      })
      .catch(() => {
        setSyncing(false)
      })
  }
  return (
    <div className="w-full flex flex-col gap-6">
      <div className="flex justify-between items-center gap-4">
        <Tab
          items={[
            { _id: 'registration', name: 'Registration' },
            { _id: 'pending', name: 'Pending' },
          ]}
          active={currentView}
          onChange={setCurrentView}
        />
        <Button
          status="primary"
          size="small"
          onClick={syncProfiles}
          loading={syncing}
        >
          Sync Profiles
        </Button>
      </div>
      <div className="w-full">
        {currentView === 'registration' ? <Registration /> : <Pending />}
      </div>
    </div>
  )
}

export default A2p
