import { ColumnDef } from '@tanstack/react-table'
import { capitalize } from '@/utils'
import moment from 'moment-timezone'
import { DATE_FORMAT } from '@/constants'
import React from 'react'
import { Tag } from '@/components'
import { IA2pWorkflow, TA2pStatus, TagStatus } from '@/types'
import PendingFormEdit from '@/pages/SuccessPortal/A2P/Edit'

const statuses = {
  [TA2pStatus.FAILED]: 'error',
  [TA2pStatus.PAUSED]: 'warning',
  [TA2pStatus.COMPLETED]: 'success',
}

export const pendingFormColumns: ColumnDef<IA2pWorkflow, any>[] = [
  {
    accessorKey: 'name',
    header: () => <span>ID</span>,
    cell: info => info.row.original._id,
  },
  {
    accessorKey: 'status',
    header: () => <span>Status</span>,
    cell: info => (
      <Tag
        variant="light"
        status={statuses[info.row.original.status] as TagStatus}
      >
        {capitalize(info.getValue())}
      </Tag>
    ),
  },
  {
    accessorKey: 'message',
    header: () => <span>Message</span>,
    cell: info => <span className="truncate max-w-2xl">{info.getValue()}</span>,
  },
  {
    accessorKey: 'createdAt',
    header: () => <span>Created At</span>,
    cell: info => moment(info.getValue()).format(DATE_FORMAT),
  },

  {
    accessorKey: '_id',
    header: () => <span></span>,
    cell: info => <PendingFormEdit id={info.row.original._id} />,
  },
]
