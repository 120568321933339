import React, { useCallback, useMemo } from 'react'
import DatePicker from '../../../../components/DatePicker'
import { Button, Select, Input, Option } from '@/components'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import {
  clearFilter,
  onFilterChange,
} from '@/store/slices/service-appointment.slice'
import { useParams } from 'react-router-dom'
import { useGetOrganizationWorkflowsQuery } from '@/api/services/workflow'
import { useGetLocationsQuery } from '@/api/services/location'
import { MultiValue } from 'react-select'

const ServiceAppointmentsFilter = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const { data: { workflows = [] } = {} } = useGetOrganizationWorkflowsQuery({
    id,
    active: true,
  })
  const {
    filter: { externalId, location, startDate, endDate, workflowIds },
  } = useAppSelector(state => state.serviceAppointments)
  const { data: { data: locations = [] } = {} } =
    useGetLocationsQuery(undefined)

  const onChange = (field: string, value?: string | number) => {
    dispatch(onFilterChange({ [field]: value }))
  }

  const locationList = useMemo(() => {
    return locations.filter(loc => loc._organization_id === id)
  }, [id, locations])

  const onDateChange = useCallback(
    (type: 'startDate' | 'endDate') => (date: Date | undefined) => {
      dispatch(
        onFilterChange({
          [type]: date,
          page: 1,
        }),
      )
    },
    [dispatch],
  )

  const onWorkflowChange = useCallback(
    (value: MultiValue<Option>) => {
      dispatch(
        onFilterChange({
          workflowIds:
            (Array.isArray(value) && value?.map(org => org._id)) || [],
          page: 1,
        }),
      )
    },
    [dispatch],
  )

  const workflowList = workflows.filter(
    workflow => workflow.type === 'appointmentConfirmation',
  )

  const reset = useCallback(() => {
    dispatch(clearFilter())
  }, [dispatch])
  return (
    <div className="flex items-end">
      <div className="grid grid-cols-6 gap-3 w-full">
        <Input
          label="RO Number"
          value={externalId}
          onChange={e => onChange('externalId', e.target.value)}
          placeholder="External ID"
        />
        <DatePicker
          label="Start Date"
          value={new Date(startDate)}
          onChange={onDateChange('startDate')}
          placeholder="Select a date"
        />
        <DatePicker
          label="End Date"
          value={new Date(endDate)}
          onChange={onDateChange('endDate')}
          placeholder="Select a date"
        />
        <Select
          label="Location"
          placeholder="Location"
          options={locationList}
          value={locationList.find(loc => loc._id === location) || null}
          onChange={val => onChange('location', val?._id)}
        />
        <Select
          label="Workflows"
          isMulti
          isSearchable
          placeholder="Workflows"
          options={workflowList}
          value={workflows.filter(workflow =>
            workflowIds.includes(workflow._id),
          )}
          onChange={onWorkflowChange}
        />
      </div>
      <Button size="small" className="ml-auto" status="text" onClick={reset}>
        Clear All
      </Button>
    </div>
  )
}

export default ServiceAppointmentsFilter
