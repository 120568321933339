import { createSlice } from '@reduxjs/toolkit'

import { ILockedLocationState } from '@/types'

const initialState: ILockedLocationState = {
  loading: false,
  error: undefined,
  filter: {
    locationId: '',
    search: '',
  },
}

const lockedLocationSlice = createSlice({
  name: 'lockedLocations',
  initialState,
  reducers: {
    clearFilter: state => {
      state.filter = initialState.filter
    },
    onFilterChange: (state, action) => {
      state.filter = { ...state.filter, ...action.payload }
    },
  },
})

export const { clearFilter, onFilterChange } = lockedLocationSlice.actions
export default lockedLocationSlice.reducer
