import React from 'react'
import styles from './styles.module.scss'
import { DOTS, usePagination } from '@/hooks'
import clsx from 'clsx'

import { ReactComponent as ArrowIcon } from '@/assets/icons/Arrow.svg'
import { Typography } from '@/components'

export interface PaginationProps {
  currentPage: number
  totalCount: number
  onPageChange: (page: number) => void
  maxVisiblePages?: number
  siblingCount?: number
  pageSize?: number
  className?: string
}

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize = 10,
  className,
}: PaginationProps) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const onNext = () => {
    onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1)
  }

  const lastPage = paginationRange[paginationRange.length - 1]
  return (
    <div className={clsx(styles.paginationContainer, className, 'justify-end')}>
      <button
        className={styles.arrow}
        disabled={currentPage === 1}
        onClick={e => {
          e.stopPropagation()
          onPrevious()
        }}
      >
        <ArrowIcon />
      </button>
      <div className={styles.numbers}>
        {paginationRange.map(pageNumber => {
          if (pageNumber === DOTS) {
            return (
              <Typography
                as="span"
                variant="regular"
                className={styles.dots}
                key={pageNumber}
              >
                &#8230;
              </Typography>
            )
          }

          return (
            <button
              className={clsx(styles.paginationItem, {
                [styles.selected]: pageNumber === currentPage,
              })}
              onClick={e => {
                e.stopPropagation()
                onPageChange(Number(pageNumber))
              }}
              key={pageNumber}
            >
              <Typography variant="regular">{pageNumber}</Typography>
            </button>
          )
        })}
      </div>
      <button
        className={clsx(styles.arrow, styles.next)}
        onClick={e => {
          e.stopPropagation()
          onNext()
        }}
        disabled={currentPage === lastPage}
      >
        <ArrowIcon />
      </button>
    </div>
  )
}

export default Pagination
