import { ColumnDef } from '@tanstack/react-table'
import { IAutomationData, IFrequency } from '@/types'
import React from 'react'
import { Tag, Typography } from '@/components'
import Toggle from '../../../components/Toggle'
import AutomationType from '@/sections/AutomationType'
import {
  useDeleteAutomationMutation,
  useToggleAutomationMutation,
} from '@/api/services/organization/organization-automation'
import toast from 'react-hot-toast'

import { ReactComponent as PencilIcon } from '@/assets/icons/Pencil.svg'
import { ReactComponent as DeleteIcon } from '@/assets/icons/Delete.svg'
import { useAppDispatch } from '@/store/hooks'
import { onFormTypeChange } from '@/store/slices/pages/organization/organization-automations.slice'

const getMessage = (type: string, frequency?: IFrequency) => {
  if (type === 'automation') {
    return `${frequency?.readableText} at ${frequency?.time?.hour}:${frequency?.time?.minute}`
  } else if (type === 'aibot') {
    return 'Every 30 minutes'
  } else if (type === 'workflow') {
    return 'At every new one'
  }
  return `At every new ${type}`
}

const AutomationToggle = ({
  active,
  id,
  organization,
}: {
  active: boolean
  id: string
  organization: string
}) => {
  const [checked, setChecked] = React.useState(active)
  const [toggleAutomation] = useToggleAutomationMutation()
  return (
    <Toggle
      checked={checked}
      onChange={() => {
        toggleAutomation({ id, organization }).then(() => {
          toast.success('Automation updated successfully')
        })
        setChecked(prev => !prev)
      }}
    />
  )
}

export const automationTableColumns = (id?: string) => {
  const data: ColumnDef<IAutomationData, any>[] = [
    {
      id: 'location',
      accessorKey: 'location',
      header: () => <span>Location</span>,
      cell: info => {
        const data = info.row.original.location
        return (
          <Typography
            variant="regular"
            weight="medium"
            className="text-black-100"
          >
            {data.name}
          </Typography>
        )
      },
    },
    {
      id: 'name',
      accessorKey: 'name',
      header: () => <span>Name</span>,
      cell: info => info.row.original.name,
    },
    {
      id: 'frequencies',
      accessorKey: 'frequencies',
      header: () => <span>Launch cycle</span>,
      cell: info => {
        const message =
          info.row.original.type === 'automation'
            ? info.row.original.frequencies
                .map(freq => {
                  return getMessage(info.row.original.type, freq)
                })
                .join(', ')
            : getMessage(info.row.original.type)
        return <div>{message}</div>
      },
    },
    {
      id: 'type',
      accessorKey: 'type',
      header: () => <span>Type</span>,
      cell: info => <AutomationType type={info.row.original.type} />,
    },
    {
      id: 'active',
      accessorKey: 'active',
      header: () => <span>Status</span>,
      cell: info => {
        const { active, _id, organization } = info.row.original
        return (
          <AutomationToggle
            active={active}
            id={_id}
            organization={organization}
          />
        )
      },
    },
    {
      id: '_id',
      accessorKey: '_id',
      header: () => <span></span>,
      cell: info => {
        const _id = info.getValue()
        const dispatch = useAppDispatch()
        const [deleteAutomation] = useDeleteAutomationMutation()
        const { active, organization } = info.row.original

        return (
          <div className="flex gap-2 items-center justify-end w-full">
            {!active && (
              <Tag
                variant="light"
                IconLeft={DeleteIcon}
                status="error"
                onClick={() => {
                  deleteAutomation({ id: _id, organization }).then(() => {
                    toast.success('Automation deleted successfully')
                  })
                }}
              >
                Delete
              </Tag>
            )}
            <Tag
              IconLeft={PencilIcon}
              status="info"
              onClick={() => {
                dispatch(onFormTypeChange(_id))
              }}
            >
              Edit
            </Tag>
          </div>
        )
      },
    },
  ]
  return data
}
