import React from 'react'
import { Button, Option, Select } from '@/components'
import { useGetOrganizationAutomationsQuery } from '@/api/services/organization/organization-automation'
import { capitalize } from '@/utils'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import {
  clearFilter,
  onFilterChange,
} from '@/store/slices/pages/organization/organization-automations.slice'

const OrganizationAutomationFilter = () => {
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const { filter } = useAppSelector(state => state.organizationAutomations)
  const { data = [] } = useGetOrganizationAutomationsQuery({ id })

  const reset = () => {
    dispatch(clearFilter())
  }

  const onSelectChange = (field: string, value: any) => {
    dispatch(onFilterChange({ [field]: value._id }))
  }

  const getLocations = () => {
    const items: Record<string, Option> = {}

    data.forEach(item => {
      items[item.location._id] = item.location
    })
    return [
      { _id: '', name: 'All' },
      ...Object.values(items).map(loc => ({
        _id: loc._id,
        name: capitalize(loc.name),
      })),
    ]
  }

  const locations = getLocations()

  const types = [
    { _id: '', name: 'All' },
    ...Array.from(new Set(data.map(item => item.type))).map(type => ({
      _id: type,
      name: capitalize(type),
    })),
  ]

  return (
    <div className="flex items-end">
      <div className="grid grid-cols-4 gap-3 w-full">
        <Select
          label="Location"
          options={locations}
          value={locations.find(loc => loc._id === filter.location) || null}
          onChange={val => onSelectChange('location', val)}
        />
        <Select
          label="Type"
          options={types}
          value={types.find(_crm => _crm._id === filter.type) || null}
          onChange={val => onSelectChange('type', val)}
        />
      </div>
      <Button className="ml-auto" status="text" size="small" onClick={reset}>
        Clear All
      </Button>
    </div>
  )
}

export default OrganizationAutomationFilter
