import React, { useCallback, useEffect, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'

import { Button, LoadingSkeleton, Typography } from '@/components'

import { ReactComponent as CloseIcon } from '@/assets/icons/Close.svg'
import { ReactComponent as PictureIcon } from '@/assets/icons/Image Placeholder.svg'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { onFormChange } from '@/store/slices/pages/organization/organization-automations.slice'
import { useAutomationFileUploadMutation } from '@/api/services/organization/organization-automation'

interface FilePreviewProps {
  fileType: string
  previewUrl: string
  onRemove: () => void
}

const FilePreview = ({ fileType, previewUrl, onRemove }: FilePreviewProps) => {
  return (
    <div className="mt-10 w-full sm:w-1/2 relative">
      {fileType === 'image' ? (
        <img className="w-full h-auto" src={previewUrl} alt="Uploaded file" />
      ) : (
        <video className="w-full h-auto" src={previewUrl} controls />
      )}
      <CloseIcon
        className="absolute top-0 -right-8 h-6 w-6 cursor-pointer text-red-500"
        onClick={onRemove}
      />
    </div>
  )
}

const Uploader = () => {
  const dispatch = useAppDispatch()
  const {
    form: { media, image: file, imageError: error },
  } = useAppSelector(state => state.organizationAutomations)
  const url = media?.url
  const [uploadImage, { isLoading, error: imageError }] =
    useAutomationFileUploadMutation()
  const [mediaLoading, setMediaLoading] = React.useState(!!url)
  const [urlFileType, setUrlFileType] = React.useState<string | null>(null)
  const fileType = useMemo(() => {
    return file?.type.split('/')[0] || ''
  }, [file])

  useEffect(() => {
    if (imageError) {
      dispatch(
        onFormChange({
          imageError,
        }),
      )
    }
  }, [])

  const previewUrl = useMemo(() => {
    return file ? URL.createObjectURL(file) : ''
  }, [file])

  const getTypeFromImageURL = useCallback((url: string) => {
    const img = new Image()
    img.src = url
    return new Promise<string>(resolve => {
      img.onerror = () => resolve('video')
      img.onload = () => resolve('image')
    }).then(result => {
      setUrlFileType(result)
      setMediaLoading(false)
    })
  }, [])

  useEffect(() => {
    if (url) getTypeFromImageURL(url)
  }, [getTypeFromImageURL, url])

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const uploadedFile: File = acceptedFiles[0]
      dispatch(
        onFormChange({
          image: uploadedFile,
        }),
      )
    },
    [dispatch],
  )

  const { getRootProps, fileRejections } = useDropzone({
    onDrop,
    accept: { 'image/*': [], 'video/*': [] },
  })

  const onFileChange = (file: File | null) => {
    dispatch(onFormChange({ image: file }))
  }

  return (
    <div className="mt-6">
      <label
        {...getRootProps({ className: 'dropzone' })}
        className="w-full py-11 border border-bg-stroke bg-bg-gray cursor-pointer mt-2 flex justify-center rounded-lg border-dashed px-6"
      >
        <div className="flex flex-col gap-4 justify-center">
          <PictureIcon className="mx-auto " />
          <div className="flex flex-col gap-1">
            <Typography variant="regular" weight="medium">
              Choose a file or drag & drop it here
            </Typography>
            <Typography variant="small" className="text-black-50 text-center">
              PNG, JPG, GIF up to 10MB
            </Typography>
          </div>
          <div className="flex justify-center">
            <Button status="secondary" size="small">
              Browse file
            </Button>
          </div>
        </div>
      </label>
      {(fileRejections.length > 0 || error) && (
        <div className="py-2">
          <p className="text-red-500 text-xs">
            {error ? error : 'Please select a valid file, then try again.'}
          </p>
        </div>
      )}

      {file && previewUrl ? (
        <div>
          <FilePreview
            previewUrl={previewUrl}
            fileType={fileType}
            onRemove={() => onFileChange(null)}
          />
          <Button
            disabled={isLoading}
            loading={isLoading}
            onClick={() => {
              uploadImage({ image: file })
                .unwrap()
                .then(res => {
                  dispatch(
                    onFormChange({
                      image: null,
                      imageError: null,
                      media: res.data,
                    }),
                  )
                })
            }}
            className="mt-2"
            size="small"
            status="secondary"
          >
            Save file
          </Button>
        </div>
      ) : url && urlFileType ? (
        <FilePreview
          previewUrl={url}
          fileType={urlFileType}
          onRemove={() => {
            dispatch(
              onFormChange({
                image: null,
                media: null,
                imageError: null,
              }),
            )
          }}
        />
      ) : null}
      {mediaLoading && (
        <LoadingSkeleton className="w-full sm:w-1/2 h-56 mt-10" />
      )}
    </div>
  )
}

export default Uploader
