import React, { ReactNode } from 'react'
import clsx from 'clsx'
import { Typography } from '@/components'

interface PageHeadingProps {
  title: string | ReactNode
  extra?: React.ReactNode
  className?: string
}
const PageHeading = ({ title, extra, className }: PageHeadingProps) => {
  return (
    <div className={clsx('flex justify-between items-center', className)}>
      <div>
        <Typography variant="H3" weight="semibold">
          {title}
        </Typography>
      </div>
      {extra && extra}
    </div>
  )
}

export default PageHeading
