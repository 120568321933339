import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import {
  clearFilter,
  onFilterChange,
} from '@/store/slices/pages/appointment.slice'
import { Button, Input, Option, Select } from '@/components'
import DatePicker from '@/components/DatePicker'

import { ReactComponent as SearchIcon } from '@/assets/icons/Search.svg'
import { useGetLocationsQuery } from '@/api/services/location'
import { useGetCrmsQuery } from '@/api/services/crm'
import {
  appointmentSortByFilter,
  appointmentStatusFilter,
} from '@/constants/appointments'

const AppointmentFilter = () => {
  const dispatch = useAppDispatch()
  const { data: { data: locations = [] } = {} } =
    useGetLocationsQuery(undefined)
  const { data: { data: crms = [] } = {} } = useGetCrmsQuery(undefined)
  const {
    filter: { startDate, endDate, status, name, phoneNumber, sortBy },
  } = useAppSelector(state => state.appointments)

  const onSelectChange = useCallback(
    (field: string, value: Option | null) => {
      dispatch(
        onFilterChange({
          [field]: (!Array.isArray(value) && value?._id) || '',
          page: 1,
        }),
      )
    },
    [dispatch],
  )

  const reset = useCallback(() => {
    dispatch(clearFilter())
  }, [dispatch])

  const onDateChange = useCallback(
    (type: 'startDate' | 'endDate') => (date: Date | undefined) => {
      dispatch(
        onFilterChange({
          [type]: date,
          page: 1,
        }),
      )
    },
    [dispatch],
  )

  const handleInputChange = useCallback(
    (field: 'name' | 'phoneNumber') =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(onFilterChange({ [field]: e.target.value, page: 1 }))
      },
    [dispatch],
  )

  return (
    <div className="flex items-end">
      <div className="grid grid-cols-6 gap-3 w-full">
        <DatePicker
          label="Start Date"
          value={new Date(startDate)}
          onChange={onDateChange('startDate')}
          placeholder="Select a date"
        />
        <DatePicker
          label="End Date"
          value={new Date(endDate)}
          onChange={onDateChange('endDate')}
          placeholder="Select a date"
        />
        <Select
          label="Sort By"
          options={appointmentSortByFilter}
          value={crms.find(_crm => _crm._id === sortBy) || null}
          onChange={val => onSelectChange('sortBy', val)}
        />
        <Input
          label="Phone"
          IconLeft={SearchIcon}
          placeholder="Search by phone number"
          value={phoneNumber}
          onChange={handleInputChange('phoneNumber')}
        />
        <Input
          label="Name"
          IconLeft={SearchIcon}
          placeholder="Search by name"
          value={name}
          onChange={handleInputChange('name')}
        />
        <Select
          label="Status"
          options={appointmentStatusFilter}
          value={
            appointmentStatusFilter.find(_status => _status._id === status) ||
            null
          }
          onChange={val => onSelectChange('status', val)}
        />
      </div>
      <Button className="ml-auto" status="text" size="small" onClick={reset}>
        Clear All
      </Button>
    </div>
  )
}

export default AppointmentFilter
