import React from 'react'
import { IBaseListItem } from '@/types'
import Modal from '@/components/Modal'
import { Tag } from '@/components'

const WorkflowsModal = ({ workflows }: { workflows: IBaseListItem[] }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <div>
      <span
        className="text-blue-80 cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        {workflows.length}
      </span>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title="Workflow list"
      >
        <div className="flex flex-wrap gap-2">
          {workflows.map(workflow => (
            <Tag key={workflow._id} status="success" variant="light">
              {workflow.name}
            </Tag>
          ))}
        </div>
      </Modal>
    </div>
  )
}

export default WorkflowsModal
