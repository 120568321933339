import React from 'react'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import moment from 'moment-timezone'
import { Button, Typography } from '@/components'
import ConfirmModal from '@/sections/ConfirmModal'
import {
  cancelAppointmentRequest,
  editAppointmentRequest,
} from '@/store/slices/superhuman.slice'
import { DateTimePicker } from '@mui/x-date-pickers'
import { ISuperhumanJobAppointment } from '@/types'

const AppointmentList = () => {
  const dispatch = useAppDispatch()
  const [cancelAppointment, setCancelAppointment] = React.useState<string>('')
  const [updateAppointment, setUpdateAppointment] = React.useState<
    ISuperhumanJobAppointment | undefined
  >()
  const [isCanceling, setIsCanceling] = React.useState(false)
  const [isUpdating, setIsUpdating] = React.useState(false)

  const { job } = useAppSelector(state => state.superhuman)

  const handleCancelAppointment = (appointment: string) => {
    if (job) {
      setIsCanceling(true)
      dispatch(
        cancelAppointmentRequest({
          appointment,
          id: job.jobId,
        }),
      )
        .unwrap()
        .finally(() => {
          setIsCanceling(false)
          setCancelAppointment('')
        })
    }
  }

  const handleUpdateAppointment = () => {
    if (job && updateAppointment) {
      setIsUpdating(true)
      // @ts-ignore
      dispatch(
        editAppointmentRequest({
          id: job.jobId,
          appointment: updateAppointment._id,
          startDate: updateAppointment.eventStart,
        }),
      )
        .unwrap()
        .finally(() => {
          setIsUpdating(false)
          setUpdateAppointment(undefined)
        })
    }
  }

  return (
    <div>
      <Typography variant="regular" weight="semibold" className="my-3">
        Appointments
      </Typography>
      {job?.appointments?.length ? (
        <div>
          <ul className="divide-y divide-bg-stroke">
            {[...job.appointments]
              .sort((left, right) => {
                return moment
                  .utc(left.eventStart)
                  .diff(moment.utc(right.eventStart))
              })
              .map(appointment => {
                return (
                  <li
                    className="px-4 py-2 flex flex-wrap justify-between items-center gap-2"
                    key={appointment._id}
                  >
                    <Typography variant="small">
                      {moment(appointment?.eventStart)
                        .tz('UTC', true)
                        .format('DD-MM-YYYY HH:mm')}
                    </Typography>
                    <div className="flex items-center gap-2">
                      <Button
                        size="small"
                        status="primary"
                        onClick={() => {
                          setUpdateAppointment({
                            _id: appointment._id,
                            eventStart: moment(appointment?.eventStart)
                              .tz('UTC', true)
                              .toDate(),
                          })
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        size="small"
                        status="secondary"
                        onClick={() => {
                          setCancelAppointment(appointment._id)
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </li>
                )
              })}
          </ul>
        </div>
      ) : (
        <Typography variant="small" className="text-black-50 mt-3">
          No appointments
        </Typography>
      )}
      <ConfirmModal
        title="Confirm your action"
        onClose={() => setCancelAppointment('')}
        open={!!cancelAppointment}
        content="Are you sure you want to cancel this appointment?"
        onConfirm={() => {
          handleCancelAppointment(cancelAppointment)
        }}
        isLoading={isCanceling}
      />
      <ConfirmModal
        title="Update appointment"
        onClose={() => setUpdateAppointment(undefined)}
        open={!!updateAppointment}
        isLoading={isUpdating}
        content={
          <DateTimePicker
            label="Update appointment"
            defaultValue={moment.utc(updateAppointment?.eventStart)}
            timezone={'UTC'}
            // @ts-ignore
            value={moment.utc(updateAppointment?.eventStart)}
            onChange={newValue => {
              if (newValue) {
                setUpdateAppointment(prevState =>
                  prevState
                    ? {
                        ...prevState,
                        eventStart: moment.utc(newValue).toDate(),
                      }
                    : undefined,
                )
              }
            }}
            className="w-full"
          />
        }
        onConfirm={handleUpdateAppointment}
      ></ConfirmModal>
    </div>
  )
}

export default AppointmentList
