import React, { useCallback, useEffect } from 'react'
import { Button, Drawer, DrawerSize, Select, Tag } from '@/components'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import {
  useAssignUsersMutation,
  useGetOrganizationSettingsQuery,
  useGetUsersByPermissionQuery,
} from '@/api/services/organization'
import { permissionType } from '@/types'
import { useAppSelector } from '@/store/hooks'
import { accessList } from '@/store/slices/user.slice'

import { ReactComponent as CloseIcon } from '@/assets/icons/Close.svg'
import { ReactComponent as UserIcon } from '@/assets/icons/User.svg'

const AssignUsers = () => {
  const { id = '' } = useParams()
  const [modalOpen, setModalOpen] = React.useState(false)
  const [assignedUsers, setAssignedUsers] = React.useState<string[]>([])
  const stateRef = React.useRef<string[]>([])
  const accesses = useAppSelector(accessList)
  const isAdmin = accesses.ADMIN_WRITE
  const { data: { users = [] } = {} } = useGetUsersByPermissionQuery({
    permissions: [permissionType.AUTOMATIONS_READ],
    organization: id,
  })
  const { data: { data: settings } = {} } = useGetOrganizationSettingsQuery({
    organization: id,
  })
  const [assignUsers, { isLoading, data }] = useAssignUsersMutation()

  useEffect(() => {
    if (settings?.assignedUsers) {
      setAssignedUsers(settings.assignedUsers)
      stateRef.current = settings.assignedUsers
    }
  }, [settings])

  const userList = users
    .map(user => {
      return {
        _id: user._id,
        name: user.firstName + ' ' + user.lastName,
      }
    })
    .sort((a, b) => {
      return a.name.localeCompare(b.name)
    })

  const assignedUserList = userList.filter(user =>
    assignedUsers.includes(user._id),
  )

  const triggerClick = useCallback((id: string, assigned: boolean) => {
    setAssignedUsers(prev =>
      assigned ? prev.filter(_id => _id !== id) : [...prev, id],
    )
  }, [])

  const onSelectChange = useCallback((users: string[]) => {
    setAssignedUsers(users)
  }, [])

  const availableUserList = userList.filter(
    user => !assignedUsers.includes(user._id),
  )

  const hasChanges = !_.isEqual(assignedUsers, stateRef.current)

  const submit = () => {
    assignUsers({
      assignedUsers,
      organization: id,
    })
  }

  return (
    <div>
      <Button
        status="primary"
        size="small"
        onClick={() => setModalOpen(true)}
        IconLeft={UserIcon}
      >
        Assign users
      </Button>
      <Drawer
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Assigned users"
        size={DrawerSize.MD}
      >
        {isAdmin && (
          <>
            <div className="w-full">
              <Select
                isMulti
                value={availableUserList.filter(user =>
                  assignedUsers.includes(user._id),
                )}
                label="Assign user"
                placeholder="Assign user"
                options={availableUserList}
                onChange={option =>
                  onSelectChange(option?.map(({ _id }) => _id as string))
                }
              />
            </div>
            <div className="flex flex-wrap items-center gap-4 w-full mt-3">
              {assignedUserList.length
                ? assignedUserList.map(({ _id, name }) => (
                    <Tag
                      status="default"
                      variant="light"
                      IconRight={isAdmin ? CloseIcon : undefined}
                      onIconClick={() => isAdmin && triggerClick(_id, true)}
                      key={_id}
                      className="px-3 !py-2"
                    >
                      {name}
                    </Tag>
                  ))
                : null}
            </div>
            {hasChanges && (
              <div className="mt-6">
                <Button
                  size="large"
                  status="primary"
                  loading={isLoading}
                  onClick={submit}
                >
                  Save
                </Button>
              </div>
            )}
          </>
        )}
      </Drawer>
    </div>
  )
}

export default AssignUsers
