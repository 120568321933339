import React, { useEffect } from 'react'

import { IPageLoadingWrapperProps } from './types'
import './styles.scss'

export const PageLoadingWrapper: React.FC<IPageLoadingWrapperProps> = ({
  isLoading,
  disableScroll = true,
  children,
}) => {
  useEffect(() => {
    if (!disableScroll) return
    if (isLoading) {
      // lock document scroll
      document.body.classList.add('scroll-lock')
    } else {
      document.body.classList.remove('scroll-lock')
    }
    return () => {
      document.body.classList.remove('scroll-lock')
    }
  }, [disableScroll, isLoading])

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loader"></div>
        </div>
      )}
      {children}
    </>
  )
}
