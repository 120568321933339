import React from 'react'
import { Typography, TypographyVariant } from '@/components'
import clsx from 'clsx'

import styles from './styles.module.scss'
import { Link, NavLink } from 'react-router-dom'

type ButtonStatus = 'primary' | 'secondary' | 'text' | 'dark' | 'error'
type ButtonSize = 'small' | 'large'

export type ButtonProps = React.HTMLAttributes<HTMLButtonElement> & {
  status: ButtonStatus
  as?: React.ElementType | 'NavLink' | 'Link'
  to?: string
  disabled?: boolean
  loading?: boolean
  type?: 'button' | 'submit' | 'reset'
  size: ButtonSize
  IconLeft?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  IconRight?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  active?: boolean
}

export const buttonStyles = {
  base: 'rounded-lg px-4 flex justify-center items-center transition-all duration-300 shrink-0 relative',
  primary:
    'bg-blue-100 text-white-100 hover:bg-[#3440CC] active:bg-[#2E38B2] disabled:bg-blue-40',
  error: 'bg-red-100 text-white-100',
  secondary:
    'border border-blue-20 text-blue-100 hover:bg-blue-10 active:bg-blue-20 disabled:text-blue-40',
  text: 'text-blue-100 hover:text-[#3440CC] active:text-[#2E38B2] disabled:text-black-50',
  dark: 'text-white-100 bg-black-80 hover:bg-black-100 active:bg-black-100',
  small: 'h-10',
  large: 'h-11',
}

const activeClasses = {
  primary: 'bg-[#2E38B2]',
  secondary: 'bg-blue-20',
  text: 'text-[#2E38B2]',
  dark: 'bg-black-100',
  error: 'bg-black-100',
}

const buttonTextStyles: Record<ButtonSize, TypographyVariant> = {
  small: 'small',
  large: 'regular',
}

const iconStyles = {
  small: 'w-6 h-6',
  large: 'w-6 h-6',
}

export const Button = ({
  className,
  children,
  as = 'button',
  to,
  loading,
  disabled,
  type = 'button',
  status = 'primary',
  size = 'small',
  IconLeft,
  IconRight,
  active,
  ...rest
}: ButtonProps) => {
  const Component = as === 'NavLink' ? NavLink : as === 'Link' ? Link : as
  if (Component === Link || Component === NavLink) {
    return (
      <Component
        to={to || '/'}
        className={clsx(
          className,
          buttonStyles.base,
          buttonStyles[status],
          buttonStyles[size],
          'flex justify-center items-center gap-2 disabled:cursor-not-allowed',
          { [activeClasses[status]]: active },
        )}
        disabled={disabled || loading}
        end="true"
        {...(rest as any)}
      >
        {loading && (
          <span
            className={clsx(styles.loaderWrapper, { [styles.show]: loading })}
          >
            <span className={styles.loader}></span>
          </span>
        )}
        {IconLeft && (
          <span className={styles.icon}>
            <IconLeft className={iconStyles[size]} />
          </span>
        )}
        <Typography
          variant={buttonTextStyles[size]}
          weight="medium"
          className={clsx('text-center  flex justify-center items-center')}
        >
          {children}
        </Typography>
        {IconRight && (
          <span className={styles.icon}>
            <IconRight className={iconStyles[size]} />
          </span>
        )}
      </Component>
    )
  }

  return (
    <Component
      className={clsx(
        className,
        buttonStyles[status],
        buttonStyles.base,
        buttonStyles[size],
        { [activeClasses[status]]: active },
        'flex justify-center items-center gap-2 disabled:cursor-not-allowed',
      )}
      disabled={disabled || loading}
      type={type}
      {...rest}
    >
      {loading && (
        <span
          className={clsx(styles.loaderWrapper, { [styles.show]: loading })}
        >
          <span className={styles.loader}></span>
        </span>
      )}
      {IconLeft && (
        <span className={styles.icon}>
          <IconLeft className={iconStyles[size]} />
        </span>
      )}
      <Typography
        variant={buttonTextStyles[size]}
        weight="medium"
        className={clsx('text-center flex justify-center items-center')}
      >
        {children}
      </Typography>
      {IconRight && (
        <span className={styles.icon}>
          <IconRight className={iconStyles[size]} />
        </span>
      )}
    </Component>
  )
}
