import React, { ComponentPropsWithRef } from 'react'
import clsx from 'clsx'

interface CardProps extends ComponentPropsWithRef<'div'> {}

const Card = ({ children, className }: CardProps) => {
  return (
    <div
      className={clsx(
        'p-6 rounded-2xl border border-bg-stroke shadow-card',
        className,
      )}
    >
      {children}
    </div>
  )
}

export default Card
