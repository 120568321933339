import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Input, Select } from '@/components'
import { registerFormFields } from '../constants'
import { internalApiRequest } from '@/api'

export default function A2PForm() {
  const [messagingServices, setMessagingServices] = useState([])
  const [organizationProfiles, setOrganizationProfiles] = useState<any[]>([])
  const [twilioProfiles, setTwilioProfiles] = useState([])
  const [brandRegistrations, setBrandRegistrations] = useState([])

  const [useSameProfile, setUseSameProfile] = useState(false)

  useEffect(() => {
    internalApiRequest.get('/profiles').then(response => {
      setOrganizationProfiles(
        response.data.sort(
          (
            a: { organizationName: string },
            b: { organizationName: string },
          ) => {
            if (a.organizationName < b.organizationName) {
              return -1
            }
            if (a.organizationName > b.organizationName) {
              return 1
            }
            return 0
          },
        ),
      )
    })
    internalApiRequest.get('/twilio/services').then(response => {
      setMessagingServices(
        response.data.sort(
          (a: { friendlyName: string }, b: { friendlyName: string }) => {
            if (a.friendlyName < b.friendlyName) {
              return -1
            }
            if (a.friendlyName > b.friendlyName) {
              return 1
            }
            return 0
          },
        ),
      )
    })
    internalApiRequest.get('/twilio/profiles').then(response => {
      setTwilioProfiles(
        response.data.sort(
          (a: { friendlyName: string }, b: { friendlyName: string }) => {
            if (a.friendlyName < b.friendlyName) {
              return -1
            }
            if (a.friendlyName > b.friendlyName) {
              return 1
            }
            return 0
          },
        ),
      )
    })
    internalApiRequest.get('/twilio/brand-registrations').then(response => {
      setBrandRegistrations(
        response.data.sort(
          (a: { friendlyName: string }, b: { friendlyName: string }) => {
            if (a.friendlyName < b.friendlyName) {
              return -1
            }
            if (a.friendlyName > b.friendlyName) {
              return 1
            }
            return 0
          },
        ),
      )
    })
  }, [])

  const submitForm = () => {
    internalApiRequest
      .post('/workflows', {
        name: 'a2p_registration',
        form: {
          ...formState,
          profile_id: undefined,
          skip_automatic_set_vet: true,
          website_opt_in_link: '[for next step]',
          screenshot_url: '[for next step]',
          '': undefined,
        },
        profile: formState.profile_id.split(' - ')[0].trim(),
      })
      .then(res => {
        setFormState({})
        return res.data
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (useSameProfile) {
      setFormState(prev => ({
        ...prev,
        ...registerFormFields.reduce((acc, field) => {
          // @ts-ignore
          acc[field.slug] = field.isSelectedValue
          return acc
        }, {}),
      }))
    } else {
      setFormState(prev => ({
        ...prev,
        ...registerFormFields.reduce((acc, field) => {
          // @ts-ignore
          acc[field.slug] = ''
          return acc
        }, {}),
      }))
    }
  }, [useSameProfile])

  const commonFields = (
    params: { profile_id: any },
    messagingServices: {
      sid: any
      friendlyName: any
    }[],
    twilioProfiles: { sid: any; friendlyName: any }[],
    brandRegistrations: any[],
  ) => [
    {
      slug: 'profile_id',
      allowedValues: organizationProfiles
        ? organizationProfiles.map(
            (organizationProfile: {
              profileId: any
              organizationName: any
            }) => {
              return `${organizationProfile.profileId} - ${organizationProfile.organizationName}`
            },
          )
        : [],
    },
    {
      slug: 'messaging_service_sid',
      allowedValues: messagingServices
        ? messagingServices.map(
            (messagingService: { sid: any; friendlyName: any }) => {
              return `${messagingService.sid} - ${messagingService.friendlyName}`
            },
          )
        : [],
    },
    {
      slug: 'profile_bundle_sid',
      allowedValues: twilioProfiles
        ? twilioProfiles.map(
            (twilioProfile: { sid: any; friendlyName: any }) => {
              return `${twilioProfile.sid} - ${twilioProfile.friendlyName}`
            },
          )
        : [],
    },
    {
      slug: 'brand_registration_sid',
      allowedValues: brandRegistrations
        ? brandRegistrations.map(brandRegistration => {
            return `${brandRegistration.sid} - ${brandRegistration.friendlyName}`
          })
        : [],
    },
    {
      slug: 'skip_automatic_set_vet',
      defaultValue: true,
    },
  ]

  const [formState, setFormState] = useState<Record<string, any>>({})
  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        submitForm()
      }}
      className="flex flex-col gap-4"
    >
      <Checkbox
        label="Use Same Profile"
        checked={useSameProfile}
        onChange={val => {
          setUseSameProfile(val)
        }}
        className="mb-2"
      />
      {commonFields(
        { profile_id: 'profile_id' },
        messagingServices,
        twilioProfiles,
        brandRegistrations,
      ).map(field => {
        return field.slug === 'skip_automatic_set_vet' ? (
          <div key={field.slug}>
            <Checkbox
              label="Skip Automatic Set Vet"
              name={field.slug}
              defaultChecked={true}
              disabled={true}
              checked={formState[field.slug]}
              onChange={val => {
                setFormState({
                  ...formState,
                  [field.slug]: val,
                })
              }}
            />
          </div>
        ) : (
          <div className="form-group" key={field.slug}>
            <Select
              placeholder="Select"
              className="w-full block"
              label={field.slug}
              value={
                field.allowedValues
                  ?.filter(el => el === formState[field.slug])
                  .map(_v => ({
                    _id: _v,
                    name: _v,
                  }))?.[0]
              }
              onChange={option =>
                setFormState({
                  ...formState,
                  [field.slug]: option?._id,
                })
              }
              options={
                field.allowedValues?.map(_v => ({
                  name: _v,
                  _id: _v,
                })) || []
              }
            />
          </div>
        )
      })}
      <hr />
      {registerFormFields
        .filter(field => {
          if (useSameProfile) {
            return true
          } else {
            return (
              field.slug !== 'profile_bundle_sid' &&
              field.slug !== 'brand_registration_sid'
            )
          }
        })
        .map(field => {
          return field.allowedValues ? (
            <div className={'form-group'} key={field.slug}>
              <Select
                placeholder="Select"
                className="w-full block"
                disabled={useSameProfile}
                label={field.slug}
                value={
                  field.allowedValues
                    ?.filter(el => el === formState[field.slug])
                    .map(_v => ({
                      _id: _v,
                      name: _v,
                    }))?.[0]
                }
                onChange={option =>
                  setFormState({
                    ...formState,
                    [field.slug]: option?._id,
                  })
                }
                options={
                  field.allowedValues?.map(_v => ({
                    _id: _v,
                    name: _v,
                  })) || []
                }
              />
            </div>
          ) : (
            <div className="form-group" key={field.slug}>
              <Input
                type="text"
                label={field.slug}
                className="block w-full mt-2"
                disabled={
                  (field.disabled || useSameProfile) &&
                  field.slug !== 'customer_profile_address_customer_name' &&
                  field.slug !== 'business_info_website_url'
                }
                onChange={e =>
                  setFormState({
                    ...formState,
                    [field.slug]: e.target.value,
                  })
                }
                value={formState[field.slug] || ''}
              />
            </div>
          )
        })}
      <Button status="primary" size="small" className="mt-8">
        Submit
      </Button>
    </form>
  )
}
