import React from 'react'
import { useAppSelector } from '@/store/hooks'
import { userPermissions } from '@/store/slices/user.slice'
import { groupPermissions } from '@/utils'
import { permissionType, permissionValueType } from '@/types'
import { Option, Tag, Typography } from '@/components'
import PageHeading from '@/sections/PageHeading'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'

const PermissionCard = ({
  title,
  options,
  permissions,
  ...rest
}: {
  title: string
  options: Option<permissionValueType>[]
  permissions: permissionValueType[]
}) => {
  return (
    <div
      className="overflow-hidden rounded-2xl border border-bg-stroke bg-white-100"
      {...rest}
    >
      <div className="flex items-center  bg-blue-10 p-5">
        <Typography variant="regular" weight="medium">
          {title}
        </Typography>
      </div>
      <div className="text-sm px-4 pt-3 pb-4 flex flex-col gap-2">
        {options.map((option, idx) => {
          const isChecked = permissions.includes(option._id)
          return (
            <div
              key={idx}
              className="flex gap-4 justify-between items-center w-full"
            >
              <Typography variant="regular" className="text-black-80">
                {option.name}
              </Typography>
              {isChecked ? (
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              ) : (
                <XCircleIcon className="h-5 w-5 text-red-500" />
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const Settings = () => {
  const { organizations } = useAppSelector(state => state.user)
  const permissions = useAppSelector(userPermissions)
  const groupedPermissions = groupPermissions(Object.values(permissionType))
  return (
    <div className="w-full flex flex-col gap-6">
      <PageHeading title="Settings" className="mb-2" />
      <div className="flex flex-col gap-6">
        <Typography variant="H3" weight="semibold">
          Permissions
        </Typography>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-x-6 gap-y-8 lg:grid-cols-6 xl:gap-x-8 capitalize">
          {Object.keys(groupedPermissions).map(scope => (
            <PermissionCard
              key={scope}
              title={scope}
              options={groupedPermissions[scope]}
              permissions={permissions}
            />
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <Typography variant="H3" weight="semibold">
          Assigned Organizations
        </Typography>
        <div className="flex gap-4 items-center flex-wrap">
          {organizations.map((organization, idx) => (
            <Tag
              variant="light"
              status="default"
              key={idx}
              className="trasition-all duration-300 rounded-full bg-white-100 px-4 py-2.5 text-sm font-semibold border border-gray-100 hover:bg-blue-100 hover:text-white-100"
            >
              {organization.name}
            </Tag>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Settings
