import React from 'react'
import { DataTable, Drawer, DrawerSize, Typography } from '@/components'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { onImportJobsChange } from '@/store/slices/pages/inventory.slice'
import { useGetImportJobsQuery } from '@/api/services/inventory'
import { importJobsTableColumns } from '@/pages/Inventory/config'
import { IInventory } from '@/types/inventory'

const ImportJobsModal = ({ inventories }: { inventories: IInventory[] }) => {
  const dispatch = useAppDispatch()
  const { importJobs } = useAppSelector(state => state.inventory)
  const { data: { data = [] } = {}, isFetching } = useGetImportJobsQuery(
    { id: importJobs },
    {
      skip: !importJobs,
    },
  )

  const dealerId = inventories.find(
    inventory => inventory.id === importJobs,
  )?.dealer_id

  return (
    <Drawer
      open={!!importJobs}
      onClose={() => {
        dispatch(onImportJobsChange(undefined))
      }}
      title="Imports"
      size={DrawerSize.XL}
    >
      <div className="flex flex-col gap-6">
        {dealerId && (
          <div className="flex flex-col gap-1">
            <Typography
              variant="small"
              weight="medium"
              className="text-black-50"
            >
              Dealer ID:
            </Typography>
            <Typography variant="medium" weight="medium">
              {dealerId}
            </Typography>
          </div>
        )}
        <DataTable
          data={data}
          columns={importJobsTableColumns}
          loading={isFetching}
        />
      </div>
    </Drawer>
  )
}

export default ImportJobsModal
