import { endpoints } from '@/api/constants'
import apiService from '@/api'
import {
  IGoogleLoginCredentials,
  ILoginCredentials,
  TUserToken,
} from '@/types/auth'

export const login = async (data: ILoginCredentials) => {
  return await apiService.post<TUserToken>(endpoints.auth, data)
}

export const googleLogin = async (data: IGoogleLoginCredentials) => {
  return await apiService.post<TUserToken>(endpoints.googleAuth, data)
}
