import React from 'react'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { onFormChange } from '@/store/slices/pages/organization/organization-automations.slice'
import {
  frequencyDefaultState,
  options,
  soldOptions,
} from '@/constants/automations'
import { useGetSingleOrganizationsQuery } from '@/api/services/organization'
import { useParams } from 'react-router-dom'
import FrequencyItem from '@/pages/Organization/AutomationForm/Frequency/FrequencyItem'
import { Button, Select, Typography } from '@/components'
import { IRule } from '@/types'
import { ReactComponent as PlusIcon } from '@/assets/icons/Plus.svg'
import Card from '@/components/Card'

const Frequency = ({ rule }: { rule?: IRule }) => {
  const { id } = useParams()

  const { data: organization } = useGetSingleOrganizationsQuery({ id })

  const {
    form: { frequencies, start, startUnit, end, endUnit, ruleId },
  } = useAppSelector(state => state.organizationAutomations)
  const dispatch = useAppDispatch()

  const onFreqChange = (index: number) => (field: string, value: any) => {
    const frequencyList = [...frequencies]
    if (field === 'hour' || field === 'minute') {
      frequencyList[index] = {
        ...frequencyList[index],
        time: {
          ...frequencyList[index].time,
          [field]: value,
        },
      }
    } else {
      frequencyList[index] = {
        ...frequencyList[index],
        [field]: value,
      }
    }
    dispatch(
      onFormChange({
        frequencies: frequencyList,
      }),
    )
  }

  const addFrequency = () => {
    dispatch(
      onFormChange({
        frequencies: [
          ...frequencies,
          {
            ...frequencyDefaultState,
            time: {
              ...frequencyDefaultState.time,
              isoTimezone: organization?.timezone,
            },
          },
        ],
      }),
    )
  }
  const onDelete = (index: number) => () => {
    dispatch(
      onFormChange({
        frequencies: frequencies.filter((_, i) => i !== index),
      }),
    )
  }

  const onChange = (key: string) => (value: any) => {
    dispatch(onFormChange({ [key]: value }))
  }

  return (
    <div>
      {!!rule &&
        rule.type !== 'lead' &&
        rule.type !== 'appointment' &&
        rule.type !== 'workflow' &&
        rule.type !== 'aibot' && (
          <Card className="flex flex-col gap-3 !shadow-none">
            <Typography variant="regular" weight="medium">
              Frequencies
            </Typography>
            <div className="flex justify-center">
              <Button
                className="w-full"
                size="large"
                status="secondary"
                IconLeft={PlusIcon}
                onClick={addFrequency}
              >
                Add Frequency
              </Button>
            </div>
            {frequencies.map((frequency, index) => (
              <div key={index}>
                <FrequencyItem
                  frequency={frequency}
                  onChange={onFreqChange}
                  organization={organization}
                  index={index}
                  onDelete={onDelete(index)}
                  rule={rule}
                />
              </div>
            ))}
          </Card>
        )}

      {rule?.type === 'automation' && (
        <React.Fragment>
          {rule?.flags?.useFutureOffset && (
            <span className="bg-red-400 text-white p-2 mt-4 block w-full text-center">
              The offset is set for future dates
            </span>
          )}
          {rule &&
            rule?.crm !== 'vinsolutions' &&
            rule?.crm !== 'subarucareconnect' &&
            rule?.crm !== 'dealercenter' && (
              <React.Fragment>
                <div className="flex gap-5 w-full mt-4">
                  <div className="flex items-end gap-2 w-full">
                    <Select
                      placeholder="Select Start offset"
                      label="Start offset"
                      options={soldOptions}
                      className="w-1/2 block"
                      value={soldOptions.find(el => el._id === start)}
                      onChange={_v => onChange('start')(_v?._id)}
                    />
                    <Select
                      options={options}
                      className="w-1/2 block"
                      value={options.find(el => el._id === startUnit)}
                      onChange={_v => onChange('startUnit')(_v?._id)}
                    />
                  </div>
                  <div className="flex items-end gap-2 w-full">
                    <Select
                      placeholder="Select End offset"
                      label="End offset"
                      options={soldOptions}
                      className="w-1/2 block"
                      value={soldOptions.find(el => el._id === end)}
                      onChange={_v => onChange('end')(_v?._id)}
                    />
                    <Select
                      options={options}
                      className="w-1/2 block"
                      value={options.find(el => el._id === endUnit)}
                      onChange={_v => onChange('endUnit')(_v?._id)}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
        </React.Fragment>
      )}
    </div>
  )
}

export default Frequency
