import React from 'react'
import { IBaseListItem, TAppTheme } from '@/types'
import clsx from 'clsx'
import { Typography } from '@/components'

interface TabProps<K extends string = string> {
  items: IBaseListItem<K>[]
  active: K
  onChange: (id: K) => void
  theme?: TAppTheme
}
const Tab = <K extends string>({
  items,
  active,
  onChange,
  theme,
}: TabProps<K>) => {
  const isDark = theme === 'dark'
  return (
    <div className="flex gap-1">
      {items.map(item => (
        <button
          key={item._id}
          className={clsx(
            'rounded-lg px-4 py-3 flex justify-center items-center transition-all duration-300 shrink-0 disabled:text-blue-40',
            {
              'text-blue-100 bg-blue-10': active === item._id && !isDark,
              'text-white-100 bg-blue-80': active === item._id && isDark,
              'text-black-50 hover:text-black-100':
                active !== item._id && !isDark,
              'bg-[#242142] text-white-100 text-opacity-50 hover:text-opacity-80':
                active !== item._id && isDark,
            },
          )}
          onClick={() => onChange(item._id)}
        >
          <Typography variant="small" weight="semibold">
            {item.name}
          </Typography>
        </button>
      ))}
    </div>
  )
}

export default Tab
