import React from 'react'
import { Button, Typography } from '@/components'
import { generatePath } from '@/utils'
import { WORKFLOW } from '@/constants'
import { ReactComponent as LocationIcon } from '@/assets/icons/Location.svg'
import { ReactComponent as DeleteIcon } from '@/assets/icons/Delete.svg'

import { IBaseListItem, ISingOrganizationType, IWorkflow } from '@/types'
import {
  useDeleteWorkflowMutation,
  useToggleWorkflowMutation,
} from '@/api/services/workflow'
import Card from '@/components/Card'

const WorkFLowItem = ({
  workflow,
  locations,
  hasWritePermissions,
  organization,
  afterAction,
}: {
  workflow: IWorkflow
  locations: IBaseListItem[]
  hasWritePermissions: boolean
  organization?: ISingOrganizationType
  afterAction: () => void
}) => {
  const [deleteWorkflow, { isLoading }] = useDeleteWorkflowMutation()
  const [toggleWorkflow, { isLoading: isToggling }] =
    useToggleWorkflowMutation()

  const handleToggle = () => {
    toggleWorkflow({
      locationId: workflow.location,
      workflowId: workflow._id,
    })
      .unwrap()
      .then(res => {
        if (res) afterAction()
      })
  }
  const handleDelete = async () => {
    try {
      await deleteWorkflow({
        locationId: workflow.location,
        workflowId: workflow._id,
      }).unwrap()
      afterAction()
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <Card className="!p-5" key={workflow._id}>
      <div className="flex flex-col gap-3">
        <Typography variant="regular" weight="medium">
          {workflow.name}
        </Typography>
        <div className="flex items-center gap-1">
          <LocationIcon className="text-black-80" />
          <Typography variant="small">
            {locations.find(_v => _v._id === workflow?.location)?.name ??
              'Not found'}
          </Typography>
        </div>
      </div>
      {hasWritePermissions && (
        <div className="flex gap-2 w-full mt-5 items-center">
          <Button
            className="block"
            size="small"
            status="primary"
            as="Link"
            to={generatePath(WORKFLOW, {
              id: organization?._id,
              workFlowId: workflow._id,
            })}
          >
            Edit
          </Button>
          <Button
            loading={isToggling}
            onClick={handleToggle}
            size="small"
            status={workflow?.active ? 'secondary' : 'primary'}
          >
            {workflow?.active ? 'Disable' : 'Enable'}
          </Button>
          {!workflow?.active && hasWritePermissions && (
            <DeleteIcon
              className="w-6 h-6 text-red-100 ml-auto cursor-pointer"
              onClick={handleDelete}
            />
          )}
        </div>
      )}
    </Card>
  )
}

export default WorkFLowItem
