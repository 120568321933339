import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Button, Input, Tag, Typography } from '@/components'
import {
  ICredential,
  ICredentialMutationPayload,
  ICredentialMeta,
  ISettings,
  IBaseListItem,
} from '@/types'

import { capitalize } from '@/utils'
import {
  useGetCrmSettingsQuery,
  useRevealPasswordMutation,
  useUpdateCredentialMutation,
} from '@/api/services/settings'
import { useGetSingleOrganizationsQuery } from '@/api/services/organization'
import { useParams } from 'react-router-dom'
import { OTP } from '@/pages/Organization/Settings/Otp'
import { PasswordReveal } from '@/pages/Organization/Settings/PasswordReveal'
import LocationLockModal from '@/pages/Organization/Settings/LockModal'
import LocationUnlockModal from '@/pages/Organization/Settings/UnlockModal'
import Select from '@/components/Select'

interface CredentialsProps {
  location: IBaseListItem
  credentials: ICredential[]
  isLocked: boolean
  onUpdate: () => void
}
interface CredentialErrorProps {
  username: string
  password: string
  meta: {
    [key: string]: string
  }
  crm: string
}

export const Credentials: React.FC<CredentialsProps> = ({
  location,
  credentials,
  isLocked,
  onUpdate,
}) => {
  const { id } = useParams()
  const [revealPassword] = useRevealPasswordMutation()

  const { data: { data: crmSettings = [] } = {} } =
    useGetCrmSettingsQuery(undefined)
  const { data: organization } = useGetSingleOrganizationsQuery({ id })

  const [errors, setErrors] = useState<Partial<CredentialErrorProps>>({})
  const [state, setState] = useState<
    Partial<ICredentialMutationPayload> & {
      error: boolean
      meta: Partial<ICredentialMeta>
    }
  >({
    error: false,
    meta: {},
  })
  const hasCredential = useMemo(
    () => credentials?.find(_v => _v.location === location._id),
    [credentials, location._id],
  )

  const credential = useMemo(() => {
    if (hasCredential && !state.crm) {
      return hasCredential
    } else {
      return credentials.find(
        _v => _v.location === location._id && _v.crm === state.crm,
      )
    }
  }, [credentials, hasCredential, location._id, state.crm])

  const selectMapper = useCallback((data: ISettings) => {
    return { _id: data.name, name: capitalize(data.name) }
  }, [])

  const crmSettingsList = useMemo(() => {
    return crmSettings.map(selectMapper)
  }, [crmSettings, selectMapper])
  const [updateCredentials, { isLoading: isUpdating }] =
    useUpdateCredentialMutation()

  useEffect(() => {
    setState(
      credential
        ? {
            error: false,
            crm: credential?.crm,
            meta: credential?.meta || {},
            username: credential?.username,
          }
        : {
            error: false,
            crm: state.crm,
            meta: {},
            username: '',
          },
    )
  }, [credential, state.crm])

  const onChange = useCallback(
    (field: string) => (value?: string | number) => {
      const isReset = field === 'crm'
      setState(prev => ({ ...prev, [field]: value }))
      setErrors({})
      if (isReset) {
        setState(prev => ({ ...prev, meta: {} }))
      }
    },
    [],
  )

  const onMetaChange = useCallback(
    (field: string) => (value: string) => {
      setState(prev => ({ ...prev, meta: { ...prev.meta, [field]: value } }))
    },
    [],
  )

  const fieldSettings: ISettings | undefined = useMemo(
    () => crmSettings.find(_v => _v.name === state.crm),
    [state.crm, crmSettings],
  )

  const onSubmit = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()
      const { crm, password, username, meta } = state
      const keys = fieldSettings?.parameters?.map(param => param.name)
      const mustKeys = ['username', 'crm']

      if (!credential) {
        mustKeys.push('password')
      }
      mustKeys.forEach(key => {
        if (!state[key as keyof ICredentialMutationPayload]) {
          setErrors(prev => ({ ...prev, [key]: 'This field is required' }))
        }
      })
      keys?.forEach(key => {
        if (!meta[key as keyof ICredentialMeta]) {
          setErrors(prev => ({
            ...prev,
            meta: { ...prev.meta, [key]: 'This field is required' },
          }))
        }
      })
      if (!organization || !crm || !username || (!credential && !password)) {
        return
      }

      if (keys?.length) {
        if (!keys.every(key => meta[key as keyof ICredentialMeta])) {
          return
        }
      }

      try {
        const data = {
          crm,
          meta,
          username,
          password,
          location: location._id,
          organization: organization?._id,
        }
        if (credential && !password) {
          const res = await revealPassword({ id: location._id }).unwrap()
          data.password = res.password
          // @ts-ignore
          await updateCredentials(data)
          onUpdate()
        } else {
          // @ts-ignore
          await updateCredentials(data)
          onUpdate()
        }
      } catch (err) {
        // @ts-ignore
        setState(prev => ({ ...prev, error: true }))
      }
    },
    [
      state,
      fieldSettings?.parameters,
      credential,
      organization,
      location._id,
      revealPassword,
      updateCredentials,
      onUpdate,
    ],
  )

  return (
    <div className="flex flex-col gap-4 text-black-100 mt-1">
      <div className="flex justify-between gap-2">
        <Typography variant="regular" className="flex items-center gap-2">
          Integration credentials for <b>{location.name}</b>
          <Tag status={hasCredential ? 'success' : 'error'} variant="light">
            {hasCredential ? 'Active' : 'No credentials found'}
          </Tag>
        </Typography>
        {hasCredential ? (
          !isLocked ? (
            <LocationLockModal locationId={location._id} />
          ) : (
            <LocationUnlockModal locationId={location._id} />
          )
        ) : null}
      </div>

      <Select
        error={errors?.crm}
        label="CRM"
        placeholder="Select CRM..."
        value={crmSettingsList.find(el => el._id === state.crm)}
        onChange={_v => onChange('crm')(_v?._id)}
        options={crmSettingsList}
      />
      <Input
        error={errors?.username}
        className="mt-2 block"
        value={state.username ?? ''}
        label={fieldSettings?.usernameFieldName ?? 'Username'}
        onChange={e => onChange('username')(e.target.value)}
      />
      <div>
        <Input
          error={errors?.password}
          type="password"
          className="mt-2 block"
          value={state.password ?? ''}
          label={fieldSettings?.passwordFieldName ?? 'Password'}
          onChange={e => onChange('password')(e.target.value)}
        />
        <PasswordReveal locationId={location._id} />
      </div>
      {fieldSettings?.parameters?.map((param, idx) => (
        <React.Fragment key={idx}>
          <Input
            error={errors?.meta?.[param.name]}
            type={param.type}
            label={param.label}
            className="mt-2 block"
            // @ts-ignore
            value={state?.meta?.[param.name] ?? ''}
            onChange={e => onMetaChange(param.name)(e.target.value)}
          />
          {!!state?.meta?.otp_code &&
            param.name === 'otp_code' &&
            state?.meta?.otp_code && <OTP otp_code={state.meta.otp_code} />}
        </React.Fragment>
      ))}
      <div className="flex justify-end">
        <Button
          size="large"
          status="primary"
          disabled={isUpdating}
          onClick={onSubmit}
        >
          {credential ? 'Update' : 'Save'}
        </Button>
      </div>
    </div>
  )
}
