import React, { ReactNode } from 'react'
import clsx from 'clsx'
import { Tag } from '@/components'

import styles from './styles.module.scss'
import { TagStatus } from '@/types'

interface WithBadgeProps {
  className?: string
  badge?: TagStatus
  children: ReactNode
  onClick?: () => void
}

const WithBadge = ({ children, badge, onClick, className }: WithBadgeProps) => {
  return (
    <div className={clsx(className, styles.wrapper)} onClick={onClick}>
      {children}
      {badge && <Tag className={styles.badge} status={badge} />}
    </div>
  )
}

export default WithBadge
