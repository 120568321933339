import React from 'react'
import PageHeading from '@/sections/PageHeading'
import AppointmentFilter from '@/pages/Appointments/Filter'
import { DataTable } from '@/components'
import { appointmentTableColumns } from '@/pages/Appointments/config'
import Pagination from '@/components/Pagination'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { onFilterChange } from '@/store/slices/pages/appointment.slice'
import { useGetAppointmentsQuery } from '@/api/services/organization/appointment'
import { useParams } from 'react-router-dom'

const OrganizationAppointments = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const { filter } = useAppSelector(state => state.appointments)
  const {
    data: { data = [], pageMeta: { totalItems = 0 } = {} } = {},
    isFetching,
  } = useGetAppointmentsQuery({ ...filter, organization: id })
  const onPageChange = (page: number) => {
    dispatch(
      onFilterChange({
        page,
      }),
    )
  }
  return (
    <div className="w-full flex flex-col gap-6">
      <AppointmentFilter />
      <DataTable
        loading={isFetching}
        columns={appointmentTableColumns}
        data={data}
      />
      <Pagination
        currentPage={filter.page}
        totalCount={totalItems}
        onPageChange={onPageChange}
      />
    </div>
  )
}

export default OrganizationAppointments
