import {
  permissionValueType,
  permissionType,
  ParsedPermission,
  PermissionValueTypeWithOrg,
  Resource,
  Level,
} from '@/types'

export const groupPermissions = (permissions: permissionValueType[]) => {
  const groupedPermissions: Record<
    string,
    { name: string; _id: permissionValueType }[]
  > = {}
  permissions.forEach(permission => {
    const [scope, accessLevel] = permission.split(':')
    if (!groupedPermissions[scope]) {
      groupedPermissions[scope] = []
    }
    groupedPermissions[scope].push({ name: accessLevel, _id: permission })
  })
  return groupedPermissions
}

export const checkPermissions = (
  permissions: permissionValueType[],
  access: permissionType[],
) => {
  return access.some(access => permissions.includes(access))
}

export const checkSuperhuman = (permissions: permissionValueType[]) => {
  const hasSuperhumanAcces = [
    permissionType.SUPERHUMAN_READ,
    permissionType.SUPERHUMAN_WRITE,
  ].some(el => permissions.includes(el))

  const noAutomationAccess = [
    permissionType.AUTOMATIONS_WRITE,
    permissionType.AUTOMATIONS_READ,
  ].every(el => !permissions.includes(el))
  return hasSuperhumanAcces && noAutomationAccess
}

export const getOrganizationsFromString = (organizations: string) => {
  return (
    organizations
      .replace(/[\[\]']+/g, '')
      .split(',')
      .map(org => org.trim()) || []
  )
}

export const checkHasOrganizationPermission = (
  organizations: string,
  org: string,
) => {
  const orgList = getOrganizationsFromString(organizations)
  if (orgList.includes('*')) return true
  return organizations.includes(org)
}

export const parsePermission = (
  permission: PermissionValueTypeWithOrg,
): ParsedPermission => {
  const [resource, levelAndOrg] = permission.split(':')
  const [level, orgs] = levelAndOrg.split('/')
  const organizations = getOrganizationsFromString(orgs)

  return {
    resource: resource as Resource,
    level: level as Level,
    organizations,
  }
}

export const combineParsedPermission = (list: ParsedPermission[]) => {
  return list.map(permission => {
    const organizations = permission.organizations.includes('*')
      ? '*'
      : permission.organizations.join(',')
    return `${permission.resource}:${permission.level}/[${organizations}]`
  })
}

export const getResourceAndLevel = (list: permissionValueType[]) => {
  return list.map(permission => {
    return permission.split('/')[0]
  })
}

export const resourceList = Object.keys(Resource)
export const levelList = Object.keys(Level)
