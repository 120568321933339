import { endpoints } from '@/api/constants'
import { ICrmResponse } from '@/types/crm'
import { createApi } from '@reduxjs/toolkit/query/react'
import { createBaseQuery } from '@/utils'
import {
  IListUser,
  ISetAccessControlPayload,
  PermissionValueTypeWithOrg,
} from '@/types'

export const accessApi = createApi({
  reducerPath: 'accessApi',
  tagTypes: ['AccessControl'],
  baseQuery: createBaseQuery(),
  endpoints: builder => ({
    getUsersList: builder.query<IListUser[], undefined>({
      query: () => {
        return {
          url: endpoints.accessControlList,
          method: 'POST',
        }
      },
    }),
    setAccessControl: builder.mutation<IListUser, ISetAccessControlPayload>({
      query: params => {
        return {
          url: endpoints.accessControl,
          method: 'POST',
          body: params,
        }
      },
    }),
    setPermissions: builder.mutation<
      IListUser,
      { permissions: PermissionValueTypeWithOrg[]; email: string }
    >({
      query: params => {
        return {
          url: endpoints.permissions,
          method: 'POST',
          body: params,
        }
      },
    }),
  }),
})

export const {
  useGetUsersListQuery,
  useSetAccessControlMutation,
  useSetPermissionsMutation,
} = accessApi
