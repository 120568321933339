import { createSlice } from '@reduxjs/toolkit'

import { IAccessControlState } from '@/types/user'

const initialState: IAccessControlState = {
  users: [],
  editUser: null,
  isGrantAccess: false,
}

const accessSlice = createSlice({
  name: 'accessControl',
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload
    },
    updateUser: (state, action) => {
      const index = state.users.findIndex(
        user => user.email === action.payload.email,
      )
      if (index !== -1) {
        state.users[index] = action.payload
      }
    },
    editPermission: (state, action) => {
      const user = state.users.find(
        user => user.email === action.payload?.email,
      )
      state.editUser = user ?? action.payload
    },
    setGrantAccess: (state, action) => {
      state.isGrantAccess = action.payload
    },
  },
})

export const { setUsers, editPermission, setGrantAccess } = accessSlice.actions
export default accessSlice.reducer
