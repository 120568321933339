import React from 'react'
import { Outlet } from 'react-router-dom'

import { WrapperAside } from '@/pages/Organization/Wrapper'

const Organization = () => {
  return (
    <WrapperAside>
      <Outlet />
    </WrapperAside>
  )
}

export default Organization
