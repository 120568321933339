import React, { useState } from 'react'
import { Button, DataTable, Drawer, DrawerSize } from '@/components'
import { syncFailureColumns } from '@/pages/Inventory/config'

import DatePicker from '../../../../components/DatePicker'

const SyncFailureModal = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => {
  const [startDate, setStartDate] = useState<Date>(new Date())

  const [endDate, setEndDate] = useState<Date>(new Date())
  return (
    <Drawer
      title="Inventoy Sync Failures"
      open={open}
      onClose={() => {
        onClose()
      }}
      size={DrawerSize.XL}
    >
      <div className="flex flex-col gap-6">
        <div className="flex gap-3 items-center w-full">
          <div className="grid grid-cols-3 gap-3 w-full">
            <DatePicker
              label="Start Date"
              value={new Date(startDate)}
              onChange={val => val && setStartDate(val)}
              placeholder="Select a date range"
            />
            <DatePicker
              label="End Date"
              value={new Date(endDate)}
              onChange={val => val && setEndDate(val)}
              placeholder="Select a date range"
            />
          </div>
          <Button size="small" className="ml-auto" status="text">
            Clear All
          </Button>
        </div>
        <DataTable columns={syncFailureColumns} data={[]} />
      </div>
    </Drawer>
  )
}

export default SyncFailureModal
