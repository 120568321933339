import { createApi } from '@reduxjs/toolkit/query/react'
import { endpoints } from '@/api/constants'
import { IAIBotCustomer, IAIBotFilter, IPaginatedRes } from '@/types'
import { createBaseQuery, generateQueryParams } from '@/utils'

export const aiBotApi = createApi({
  reducerPath: 'aiBotApi',
  tagTypes: ['AiBot'],
  baseQuery: createBaseQuery(),
  endpoints: builder => ({
    getAiBotCustomers: builder.query<
      IPaginatedRes<IAIBotCustomer>,
      Partial<IAIBotFilter>
    >({
      query: query => {
        const params = generateQueryParams(query)
        return {
          url: endpoints.allAIBotCustomers + params,
        }
      },
    }),
    getStats: builder.query<number, Partial<IAIBotFilter>>({
      query: query => {
        const params = generateQueryParams(query)
        return {
          url: endpoints.aiBotStats + params,
        }
      },
    }),
  }),
})

export const { useGetAiBotCustomersQuery, useGetStatsQuery } = aiBotApi
