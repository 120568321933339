import React from 'react'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { IconButton, Typography } from '@/components'
import styles from '@/pages/Superhuman/styles.module.scss'
import {
  tagUser,
  untagUser,
  jobAssignedUsers,
  jobPeopleToTag,
  addUserToList,
} from '@/store/slices/superhuman.slice'

import { ReactComponent as PlusIcon } from '@/assets/icons/Plus.svg'
import { ReactComponent as MinusIcon } from '@/assets/icons/Minus.svg'
import TagActionModal from '@/pages/Superhuman/Actions/TagActionModal'
import AppointmentList from '@/pages/Superhuman/Actions/AppointmentList'
import { LocalizationProvider } from '@mui/x-date-pickers'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import AppointmentForm from '@/pages/Superhuman/Actions/AppointmentForm'

const SuperhumanJobActions = () => {
  const dispatch = useAppDispatch()
  const { usersToTag } = useAppSelector(state => state.superhuman)
  const assignedUsers = useAppSelector(jobAssignedUsers)
  const peopleToTag = useAppSelector(jobPeopleToTag)
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className={styles.sectionHeading}>
        <Typography variant="H3">Job Actions</Typography>
      </div>
      <div className={styles.sectionContent}>
        <div>
          <Typography variant="regular" weight="semibold" className="mb-3">
            Assigned users
          </Typography>
          <div className="flex flex-col gap-1">
            {assignedUsers?.map(({ fullName, _id }) => {
              return (
                <div
                  className="flex justify-between gap-2 items-center"
                  key={_id}
                >
                  <Typography
                    variant="small"
                    weight="medium"
                    className="truncate"
                  >
                    {fullName}
                  </Typography>
                  {usersToTag.includes(_id) ? (
                    <IconButton
                      onClick={() => dispatch(untagUser(_id))}
                      Icon={MinusIcon}
                      size={24}
                      wrapperClassName="bg-red-50 text-red-100"
                    />
                  ) : (
                    <IconButton
                      Icon={PlusIcon}
                      size={24}
                      wrapperClassName="bg-green-100 text-white-100"
                      onClick={() => {
                        dispatch(addUserToList(_id))
                        dispatch(tagUser(_id))
                      }}
                    />
                  )}
                </div>
              )
            })}
          </div>
        </div>
        {peopleToTag.length > 0 && (
          <div>
            <Typography
              variant="regular"
              weight="semibold"
              className="my-3 text-green-100"
            >
              Tag users that will be tagged after your submit.
            </Typography>
            <div className="flex flex-col gap-1">
              {peopleToTag.map(({ fullName, _id }) => {
                return (
                  <div
                    className="flex justify-between gap-2 items-center"
                    key={_id}
                  >
                    <Typography
                      variant="small"
                      weight="medium"
                      className="truncate"
                    >
                      {fullName}
                    </Typography>
                    {usersToTag.includes(_id) ? (
                      <IconButton
                        onClick={() => dispatch(untagUser(_id))}
                        Icon={MinusIcon}
                        size={24}
                        wrapperClassName="bg-red-50 text-red-100"
                      />
                    ) : (
                      <IconButton
                        Icon={PlusIcon}
                        size={24}
                        wrapperClassName="bg-green-100 text-white-100"
                        onClick={() => dispatch(tagUser(_id))}
                      />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        )}
        <TagActionModal />
        <AppointmentForm />
        <AppointmentList />
      </div>
    </LocalizationProvider>
  )
}

export default SuperhumanJobActions
