export const uuid = function b(a?: string): string {
  return a
    ? (parseInt(a) ^ ((Math.random() * 16) >> (parseInt(a) / 4))).toString(16)
    : (
        (1e7).toString() +
        '-' +
        (1e3).toString() +
        '-' +
        (4e3).toString() +
        '-' +
        (8e3).toString() +
        '-' +
        (1e11).toString()
      ).replace(/[018]/g, b)
}
