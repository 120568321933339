import { createApi } from '@reduxjs/toolkit/query/react'

import { endpoints } from '@/api/constants'
import { ISales, ISalesFilter } from '@/types'
import { createBaseQuery, generateQueryParams } from '@/utils'

export const salesApi = createApi({
  reducerPath: 'salesApi',
  tagTypes: ['Sales'],
  baseQuery: createBaseQuery(),
  endpoints: builder => ({
    getSales: builder.query<ISales[], ISalesFilter>({
      query: data => {
        const query = generateQueryParams(data)
        return {
          url: endpoints.salesMetrics + query,
        }
      },
    }),
  }),
})

export const { useGetSalesQuery } = salesApi
