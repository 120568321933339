import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import {
  API_ENDPOINT,
  AUTH_TOKEN_KEY,
  INTERNAL_API_ENDPOINT,
} from '@/constants'
import { generatePath, getStorageValue } from '@/utils'
import { IParams } from '@/types'

type RequestMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

interface RequestConfig extends AxiosRequestConfig {
  method?: RequestMethod
  url: string
  params?: object
  data?: any
  headers?: object
}

export const internalApiRequest = axios.create({
  baseURL: `${INTERNAL_API_ENDPOINT}`,
})

internalApiRequest.interceptors.request.use(config => {
  const authToken = getStorageValue(AUTH_TOKEN_KEY)
  if (authToken) {
    // TODO: Add the token to the headers
    config.headers.Authorization = `Bearer mMpkzW3wE8cQh34RLUcjUlmcsB1LFZHop5IroO-5AVWhiPSsyxOtUk1Ixt_FDp07ppwDCjq1uOhF1OUBACBsbb>`
  }
  return config
})

const apiRequest = axios.create({
  baseURL: `${API_ENDPOINT}`,
})

apiRequest.interceptors.request.use(config => {
  const authToken = getStorageValue(AUTH_TOKEN_KEY)
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`
  }
  return config
})

const apiService = {
  async request<T>(config: RequestConfig): Promise<AxiosResponse<T>> {
    const response: AxiosResponse<T> = await apiRequest(config)
    return response
  },

  async get<T>(url: string, params?: IParams): Promise<AxiosResponse<T>> {
    const path = generatePath(url, params)
    return this.request<T>({ method: 'GET', url: path, params })
  },

  async getPaginated<T, P extends Record<string, any>>(
    url: string,
    params?: P,
  ): Promise<AxiosResponse<T>> {
    const queryParams: Record<string, any> = {}
    if (params) {
      Object.keys(params).forEach(param => {
        if (params[param]) {
          if (Array.isArray(params[param]) && params[param].length) {
            queryParams[param] = params[param]
          } else {
            queryParams[param] = params[param]
          }
        }
      })
    }
    const path = generatePath(url, params)
    return this.request<T>({ method: 'GET', url: path, params: queryParams })
  },

  async post<T, D = any>(
    url: string,
    data?: D,
    params?: IParams,
  ): Promise<AxiosResponse<T>> {
    const path = generatePath(url, params)
    return this.request<T>({ method: 'POST', url: path, data })
  },

  async put<T, D>(url: string, data?: D): Promise<AxiosResponse<T>> {
    return this.request<T>({ method: 'PUT', url, data })
  },

  async patch<T, D>(url: string, data?: D): Promise<AxiosResponse<T>> {
    return this.request<T>({ method: 'PATCH', url, data })
  },

  async delete<T>(url: string): Promise<AxiosResponse<T>> {
    return this.request<T>({ method: 'DELETE', url })
  },
}

export default apiService
