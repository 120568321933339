import React from 'react'
import { useGetSingleOrganizationsQuery } from '@/api/services/organization'
import { useGetSamplesQuery } from '@/api/services/workflow'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { onFormChange, onStepChange } from '@/store/slices/workflow.slice'
import { Button, Input, Tag, Typography } from '@/components'
import { RadioGroup, Radio } from '@headlessui/react'

import { ITemplate, IWorkflowLocation } from '@/types'

const SelectLocation = ({
  templates,
  locations,
}: {
  templates: ITemplate[]
  locations: IWorkflowLocation[]
}) => {
  const dispatch = useAppDispatch()
  const {
    form: { location, name, template, combinator },
  } = useAppSelector(state => state.workflow)
  const { id } = useParams()
  const { data: organization } = useGetSingleOrganizationsQuery({ id })
  const { data: samples } = useGetSamplesQuery(
    {
      organization: organization?._id || '',
      combinator,
      crm: template?.crm || '',
    },
    { skip: !template?.crm && !combinator && !organization?._id },
  )

  const onFieldChange = (field: string, value: any) => {
    dispatch(onFormChange({ [field]: value }))
  }

  return (
    <div className="flex flex-col gap-6 max-w-xl mx-auto mt-4 w-full">
      <Input
        placeholder="Enter Workflow Name"
        label="Name your workflow"
        value={name}
        onChange={e => onFieldChange('name', e.target.value)}
      />
      <div>
        <Typography
          variant="small"
          weight="medium"
          className="mb-3 text-black-80"
        >
          Select Location
        </Typography>
        <RadioGroup
          className="flex flex-col gap-2"
          value={location}
          onChange={val => onFieldChange('location', val)}
        >
          {locations.map(loc => (
            <Radio
              value={loc._id}
              className={
                'p-4 group flex items-center justify-center rounded-2xl border border-bg-stroke bg-white-100 data-[checked]:border-blue-100 transition-all duration-300'
              }
              key={loc._id}
            >
              <div className="flex w-full items-center justify-between">
                <div className="flex flex-col gap-1">
                  <Typography variant="regular" weight="medium">
                    {loc.name}
                  </Typography>
                  <Typography
                    variant="small"
                    className="text-black-50 flex gap-1 items-center"
                  >
                    Workflows Available:{' '}
                    <Tag status="warning" variant="light" className="uppercase">
                      {loc.crm}
                    </Tag>
                  </Typography>
                </div>
              </div>
              <div className="group flex size-4 items-center justify-center rounded-full border border-[#D0D5DD] data-[checked]:border-blue-100 bg-white transition-all disabled:bg-[#F9FAFB] outline-none duration-300">
                <span className="invisible size-2 rounded-full bg-white-100 group-data-[checked]:visible group-data-[checked]:bg-blue-100 group-data-[disabled]:bg-[#D0D5DD]" />
              </div>
            </Radio>
          ))}
        </RadioGroup>
      </div>
      {location && (
        <div>
          <Typography
            variant="small"
            weight="medium"
            className="mb-3 text-black-80"
          >
            Select Template
          </Typography>
          <RadioGroup
            className="flex flex-col gap-2"
            value={template?.id}
            onChange={val =>
              onFieldChange(
                'template',
                templates.find(temp => temp.id === val),
              )
            }
          >
            {templates.map(template => (
              <Radio
                value={template.id}
                className={
                  'p-4 group flex items-center justify-center rounded-2xl border border-bg-stroke bg-white-100 data-[checked]:border-blue-100 transition-all duration-300'
                }
                key={template.id}
              >
                <div className="flex w-full items-center justify-between">
                  <div className="flex flex-col gap-1">
                    <Typography variant="regular" weight="medium">
                      {template.name}
                    </Typography>
                    <Typography variant="small" className="text-black-50">
                      {template.description}
                    </Typography>
                  </div>
                </div>
                <div className="group flex size-4 items-center justify-center rounded-full border border-[#D0D5DD] data-[checked]:border-blue-100 bg-white transition-all disabled:bg-[#F9FAFB] outline-none duration-300">
                  <span className="invisible size-2 rounded-full bg-white-100 group-data-[checked]:visible group-data-[checked]:bg-blue-100 group-data-[disabled]:bg-[#D0D5DD]" />
                </div>
              </Radio>
            ))}
          </RadioGroup>
        </div>
      )}
      <Button
        onClick={() => {
          dispatch(onStepChange(2))
        }}
        status="primary"
        size="large"
        disabled={!name || !location || !template}
      >
        Continue
      </Button>
    </div>
  )
}

export default SelectLocation
