import React, { useMemo, useState } from 'react'
import Tab from '@/components/Tab'
import SalesData from '@/pages/SuccessPortal/SalesDashboard/SalesData'
import SalesBuckets from '@/pages/SuccessPortal/SalesDashboard/SalesBuckets'

const ExternalSalesDashboard = () => {
  const [currentView, setCurrentView] = useState('data')
  const Component = currentView === 'data' ? SalesData : SalesBuckets

  const { id, name } = useMemo(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const id = queryParams.get('organizationId')
    const name = queryParams.get('organizationName')
    return {
      id,
      name,
    }
  }, [])

  return (
    <div className="flex flex-col gap-6 w-full">
      <Tab
        theme="dark"
        items={[
          { _id: 'data', name: 'Sales Data' },
          // { _id: 'buckets', name: 'Sales Buckets' },
        ]}
        active={currentView}
        onChange={setCurrentView}
      />
      <Component isDark={true} id={id} name={name} />
    </div>
  )
}

export default ExternalSalesDashboard
