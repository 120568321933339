import React from 'react'

import { Button, Typography } from '@/components'

const Confirmation = ({ onSave }: { onSave: () => void }) => {
  return (
    <div className="grow flex flex-col">
      <Typography variant="H3" weight="semibold">
        Appointment confirmations
      </Typography>
      <div className="flex flex-col lg:flex-row justify-between">
        <Typography
          variant="regular"
          weight="medium"
          className="mt-2 text-black-80"
        >
          You can set up the appointment messages in the location's settings on
          Matador.
        </Typography>
      </div>

      <div className="mt-auto">
        <Button size="large" status="primary" onClick={onSave}>
          Confirm and save workflow
        </Button>
      </div>
    </div>
  )
}

export default Confirmation
