import React from 'react'
import { Tag } from '@/components'
import { AutomationStatus } from '@/types'
import { statuses } from '@/constants/automations'

const AutomationTaskStatus = ({
  status,
  onClick,
}: {
  status: AutomationStatus
  onClick?: () => void
}) => {
  return (
    <Tag variant="light" status={statuses[status].color} onClick={onClick}>
      {statuses[status].text}
    </Tag>
  )
}

export default AutomationTaskStatus
