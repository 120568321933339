import React from 'react'
import { useGetOrganizationAutomationsQuery } from '@/api/services/organization/organization-automation'
import { useParams } from 'react-router-dom'

import { DataTable } from '@/components'
import { automationTableColumns } from './config'
import OrganizationAutomationFilter from '@/pages/Organization/Automations/Filter'
import { useAppSelector } from '@/store/hooks'

const OrganizationAutomations = () => {
  const { id } = useParams()
  const { filter } = useAppSelector(state => state.organizationAutomations)
  const { isLoading, data = [] } = useGetOrganizationAutomationsQuery({ id })
  const filteredData = data.filter(item => {
    return (
      (!filter.location || item.location._id === filter.location) &&
      (!filter.type || item.type === filter.type)
    )
  })

  return (
    <div className="w-full flex flex-col gap-6">
      <OrganizationAutomationFilter />
      <DataTable
        loading={isLoading}
        columns={automationTableColumns(id)}
        data={filteredData}
      />
    </div>
  )
}

export default OrganizationAutomations
