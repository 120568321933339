import React from 'react'
import ComingSoon from '@/sections/ComingSoon'

import { ReactComponent as OrganizationSummaryIcon } from '@/assets/icons/Statistics Bar.svg'
import { DataTable } from '@/components'
import { summaryTableColumns } from '@/pages/SuccessPortal/OrganizationSummary/config'
import { useAppSelector } from '@/store/hooks'
import OrganizationSummaryFilter from '@/pages/SuccessPortal/OrganizationSummary/Filter'

const SuccessPortalOrganizationSummary = () => {
  const { filter } = useAppSelector(state => state.organizationSummary)
  return (
    <div className="flex flex-col gap-6">
      <OrganizationSummaryFilter />
      {/*<ComingSoon*/}
      {/*  Icon={OrganizationSummaryIcon}*/}
      {/*  description="Get a clear overview of key metrics, including broadcasts, automations, and messaging activity for each organization."*/}
      {/*/>*/}
      <DataTable columns={summaryTableColumns} data={[]} />
      {/*<Pagination*/}
      {/*  currentPage={filter.page}*/}
      {/*  totalCount={totalItems}*/}
      {/*  onPageChange={onPageChange}*/}
      {/*/>*/}
    </div>
  )
}

export default SuccessPortalOrganizationSummary
