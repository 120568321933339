import { endpoints } from '@/api/constants'
import {
  ILocationResponse,
  ILockedLocation,
  ILockedLocationResponse,
} from '@/types/location'
import { createApi } from '@reduxjs/toolkit/query/react'
import { createBaseQuery, generatePath } from '@/utils'
import { IPBSDealsType } from '@/types'

export const locationApi = createApi({
  reducerPath: 'locationApi',
  tagTypes: ['Locations'],
  baseQuery: createBaseQuery(),
  endpoints: builder => ({
    getLocations: builder.query<ILocationResponse, undefined>({
      query: () => {
        return {
          url: endpoints.locations,
        }
      },
    }),
    getLocationCredentials: builder.query<IPBSDealsType, { id?: string }>({
      query: ({ id }) => {
        return {
          url: generatePath(endpoints.getLocationCredentials, { id }),
        }
      },
    }),
    getLockedLocations: builder.query<ILockedLocationResponse, undefined>({
      query: () => {
        return {
          url: endpoints.lockedLocations,
        }
      },
    }),
    lockLocation: builder.mutation<
      ILockedLocation,
      { locationId: string; reason: string }
    >({
      query: ({ reason, locationId }) => {
        return {
          url: generatePath(endpoints.lockLocation, { locationId }),
          method: 'POST',
          body: {
            reason,
          },
        }
      },
    }),
    unlockLocation: builder.mutation<
      { locationId: string },
      { locationId: string }
    >({
      query: ({ locationId }) => {
        return {
          url: generatePath(endpoints.unlockLocation, { locationId }),
          method: 'POST',
        }
      },
    }),
  }),
})

export const {
  useGetLocationsQuery,
  useGetLockedLocationsQuery,
  useLockLocationMutation,
  useUnlockLocationMutation,
  useGetLocationCredentialsQuery,
} = locationApi
